import { FC } from "react";

import { useAuth } from "@contexts/auth";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";
import { getCoachBaseUrl, getCurrentURIFromServer } from "@lib/utils";

import InviteClientModal from "@components/Modals/InviteClientModal";
import SharingModal from "@components/Modals/SharingModal";

interface InviteAndShareModal {
  setShowInviteModal: (value: boolean) => void;
  showInviteModal: boolean;
  setShowShareModal: (value: boolean) => void;
  showShareModal: boolean;
  client: ClientType;
  showViewChat?: boolean;
}

const InviteAndShareModal: FC<InviteAndShareModal> = ({
  showInviteModal,
  setShowInviteModal,
  setShowShareModal,
  showShareModal,
  client,
  showViewChat = true,
}) => {
  const { firestoreUser: coach } = useAuth();
  const clientMessagingLink = `clients/${client.id}/messaging`;
  const messagingLink =
    client && `${getCoachBaseUrl(coach)}/${clientMessagingLink}`;

  // this is necessary for when the organization as domain set, and the
  // coach clicks to view the client portal. It will ensure that the coach
  // doesn't need to sign in again.
  const practiceMessageLink = `${getCurrentURIFromServer()}/me/${
    coach.slug
  }/${clientMessagingLink}`;

  return (
    <>
      {showInviteModal && (
        <InviteClientModal
          show
          toggleShow={setShowInviteModal}
          clientName={displayNameFromContact(client, true)}
          setShowShareModal={setShowShareModal}
          showViewChat={showViewChat}
          messagingLink={practiceMessageLink}
        />
      )}
      {showShareModal && (
        <SharingModal
          show
          toggleShow={setShowShareModal}
          artefactType="chat invite"
          artefactLink={messagingLink}
          client={client}
        />
      )}
    </>
  );
};

export default InviteAndShareModal;
