import { useState } from "react";
import { PackageInstanceApi } from "@practice/sdk";
import moment, { Moment } from "moment";

import { catchErrorSDK } from "@lib/utils/catch-error-client";

import { useMutatePackages } from "./use-client-organization-data";
import useLogger from "./use-logger";
import { useRequestIdGenerator } from "./use-request-id-generator";
import { useSDKApi } from "./use-sdk-api";

const useResumePackage = (
  organizationId: string,
  clientId: string,
  clientParentId?: string,
  options?: { failMode?: "throw"; logName?: string }
) => {
  const [loading, isLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { logger } = useLogger("usePausePackage");

  const packageInstanceAPI = useSDKApi(PackageInstanceApi);
  const mutatePackages = useMutatePackages({
    clientId,
    parentId: clientParentId,
  });

  const generateRequestId = useRequestIdGenerator(
    options?.logName ?? "useResumePackage"
  );

  const resumePackageInstance = async (
    packageInstanceId: string,
    cycleEndDate: Moment
  ) => {
    const request = {
      organizationId,
      packageInstanceId,
      clientId,
    };

    const formattedCycleEndDate = cycleEndDate.isSame(moment(), "day")
      ? moment().add(5, "minutes").toISOString()
      : cycleEndDate.toISOString();

    logger.info(request, "Resuming package instance");

    isLoading(true);
    try {
      await packageInstanceAPI.resumePackageInstance({
        ...request,
        resumePackageInstanceRequest: {
          cycleEndDate: formattedCycleEndDate,
        },
        xRequestId: generateRequestId(),
      });
      await mutatePackages();
    } catch (e: any) {
      const errorMessage = await catchErrorSDK(
        e,
        "Failed to resume package instance"
      );
      logger.info({ error: e, request, errorMessage }, errorMessage);
      setError(errorMessage);
      if (options?.failMode === "throw") {
        throw e;
      }
    } finally {
      isLoading(false);
      logger.info(request, "Package instance resumed");
    }
  };

  return { resumePackageInstance, loading, error };
};

export default useResumePackage;
