import { FC, useMemo } from "react";
import classNames from "classnames";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";
import { getLibrayItemStatus } from "@components/FilesAndLinks/FilesAndLinksListItem";

import CommonMoreMenu from "./CommonMoreMenu";
import RenderWithLink from "./RenderWithLink";
import { classes } from "./shared";
import { TimelineArtifactItemProps } from "./types";

export const Folder: FC<TimelineArtifactItemProps> = ({
  item,
  contactId,
  isCoach,
  artifactSettings,
  onChangeHidden,
}) => {
  let linkUrl = item.sharedWithData
    ? `/library/folders/${item.id}`
    : `/contacts/${contactId}/folders/${item.id}`;

  const hidden = useMemo(() => {
    const { folders } = artifactSettings || {};
    return folders?.hidden?.includes(item.id);
  }, [artifactSettings]);

  const renderMoreMenu = isCoach ? (
    <CommonMoreMenu
      artifact="folders"
      item={item}
      artifactSettings={artifactSettings}
      onChangeHidden={onChangeHidden}
    />
  ) : undefined;

  if (!isCoach) {
    linkUrl = [
      `/me/${item?.coachUserId}`,
      !item.sharedWithData && !item.broadcasted && `clients/${contactId}`,
      `folders/${item.id}`,
    ]
      .filter(Boolean)
      .join("/");
  }

  return (
    <RenderWithLink
      isCoach={isCoach}
      item={item}
      linkUrl={linkUrl}
      isHidden={hidden}
      moreMenu={renderMoreMenu}
    >
      <ArtifactStatusIcon
        url={item.url}
        status={getLibrayItemStatus(item)}
        type="folders"
        className="mr-4"
      />
      <div className={classNames(classes.content, "truncate")}>
        <p className="truncate">{item.title}</p>
        <ArtifactStatusText
          status={getLibrayItemStatus(item)}
          type="folders"
          size="xs"
        />
      </div>
    </RenderWithLink>
  );
};
