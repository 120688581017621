import { ReactNode } from "react";
import classNames from "classnames";

import { ToggleFormComponent } from "@components/Form/Toggle";
import InfoFilledIcon from "@components/Icons/InfoFilledIcon";

interface ToggleWithInfoProps {
  label: string;
  name: string;
  control: any;
  description?: ReactNode;
  infoText?: string;
  className?: string;
}

export const ToggleWithInfo: React.FC<ToggleWithInfoProps> = ({
  label,
  name,
  control,
  description,
  infoText,
  className,
}) => {
  return (
    <div
      className={classNames(
        className,
        "flex flex-col text-sm mt-4 bg-grey-950 rounded-md p-4"
      )}
    >
      <div className="flex items-center font-medium justify-between">
        <span>{label}</span>
        <ToggleFormComponent name={name} control={control} size="small" />
      </div>
      {description && (
        <div className="flex items-center mt-2 text-gray-500 leading-4">
          {description}
        </div>
      )}
      {infoText && (
        <div className="flex items-center mt-2 text-black-ink text-xs leading-4 bg-grey-900 rounded-full py-1 px-3 self-start">
          <InfoFilledIcon className="h-4 w-4 mr-2" />
          <span>{infoText}</span>
        </div>
      )}
    </div>
  );
};
