import classNames from "classnames";

import { useMatchMedia } from "@hooks/use-match-media";

export const ZeroState = () => {
  const matches = useMatchMedia("xl");

  return (
    <div
      className={classNames(
        "px-6 py-4 relative w-full overflow-hidden bg-no-repeat",
        matches ? "bg-right-top h-64" : "bg-bottom h-80"
      )}
      style={{
        backgroundImage: matches
          ? `url("/images/img_timeline-earlystate@3x.png")`
          : `url("/images/img_timeline-earlystate_mobile@3x.png")`,
        backgroundSize: matches ? "auto 100%" : "auto 70%",
      }}
    >
      <div>
        <h2 className="text-2xl font-medium inline-block">
          Plan your next step!
        </h2>
      </div>
      <div>
        <p className="text-action-600 mt-1 inline-block">
          Tap + to make sure {matches && <br />} you&apos;re up to date{" "}
        </p>
      </div>
    </div>
  );
};
