import React from "react";
import classNames from "classnames";
import Link from "next/link";

import { classes } from "./shared";

export interface RenderWithLinkProps {
  item: any;
  children: React.ReactNode;
  linkUrl: string;
  moreMenu?: React.ReactNode;
  isCoach?: boolean;
  isHidden?: boolean;
}

const RenderWithLink: React.FC<RenderWithLinkProps> = ({
  children,
  linkUrl,
  item,
  moreMenu,
  isCoach = true,
  isHidden = true,
}) => {
  const isDraft = ["draft", "scheduled"].includes(item?.status);
  const isUnread = item?.status === "unread";
  const linkStyle = classNames(
    classes.link,
    (isDraft || isHidden) && "hover:bg-transparent focus:bg-transparent",
    isUnread && !isHidden && "bg-action-900"
  );
  const linkContentStyle = "w-full flex overflow-visible";

  const renderWithClick = (
    <div className={linkContentStyle} onClick={() => item.onClick(item.id)}>
      {children}
    </div>
  );

  const renderWithLink =
    linkUrl === "cancelled" ? (
      <a className={linkContentStyle}>{children}</a>
    ) : (
      <Link href={linkUrl ? linkUrl : ""} className={linkContentStyle}>
        {children}
      </Link>
    );

  const renderWithNewTabLink = (
    <a
      className={linkContentStyle}
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );

  return (
    <div className={linkStyle}>
      {!isCoach
        ? renderWithNewTabLink
        : item?.onClick
        ? renderWithClick
        : renderWithLink}
      {moreMenu && (
        <div className="absolute right-4 hidden group-hover:block group-hover:visible">
          {moreMenu}
        </div>
      )}
    </div>
  );
};

export default RenderWithLink;
