import { useCallback } from "react";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import {
  PackageInstanceApi,
  PackageInstanceCard,
  UpdatePackageInstanceDurationRequest,
} from "@practice/sdk";

import { useAuth } from "@contexts/auth";
import { useSchedulers } from "@hooks/data/schedulers";
import { useMutatePackages } from "@hooks/use-client-organization-data";
import { usePackageSchedulers } from "@hooks/use-package-schedulers";
import { useSDKApiExecute } from "@hooks/use-sdk-api";
import { getCurrentCycle } from "@lib/models/package-instances/utils";

import { ToggleWithInfo } from "@components/AppointmentTypeForm/RoundRobinSettings/ToggleWithInfo";
import { ErrorMessage } from "@components/ErrorMessage";
import TextFieldForm from "@components/Form/TextFieldForm";
import MathIcon from "@components/Icons/MathIcon";
import SmallModal from "@components/Modals/SmallModal";
import { SchedulerWithItemType } from "@components/Package/PackageForm";
import PackageSchedulerItem from "@components/Package/PackageSchedulerFormItem";
import { getPackageInstanceUnit } from "@components/Package/utils";
import {
  getPackageInstanceInfo,
  getRecurringCycleTotal,
} from "@components/PackageInstance/utils";

const PackageInstanceModifyContent: React.FC<{
  packageInstance: PackageInstanceCard;
  toggle: () => void;
  clientId: string;
  clientParentId?: string;
}> = ({ packageInstance, toggle, clientId, clientParentId }) => {
  const { oid } = useAuth();
  const methods = useForm<any>({
    defaultValues: packageInstance.distributeSessions
      ? {
          total: 0,
        }
      : {
          items: packageInstance.items.map((item) => ({
            ...item,
            quantity: 0,
          })),
        },
  });
  const { execute, loading } = useSDKApiExecute(PackageInstanceApi, {
    method: "updatePackageInstanceDuration",
    errorLogMessage: "Failed to update the package instance duration",
    keyOrigin: "use-update-package-instance-duration",
    showErrorSnackbar: true,
  });
  const mutatePackages = useMutatePackages({
    clientId,
    parentId: clientParentId,
  });

  const onSubmit = async (data: any) => {
    if (!oid) return;
    const formattedData: UpdatePackageInstanceDurationRequest =
      "total" in data
        ? {
            total: Number(data.total),
            applyRecurring: !!data.applyRecurring,
          }
        : {
            items: data.items.map((item: any) => ({
              ...item,
              quantity: Number(item.quantity),
            })),
          };
    await execute({
      organizationId: oid,
      packageInstanceId: packageInstance.id,
      updatePackageInstanceDurationRequest: formattedData,
    });
    await mutatePackages();
    toggle();
  };

  return (
    <SmallModal
      show
      toggleShow={toggle}
      icon={MathIcon}
      iconColor="green"
      title="Add/Remove sessions"
      description="Client won't be charged for additional sessions or hours."
      onAction={methods.handleSubmit(onSubmit)}
      onActionLoading={loading}
      isCloseAfterPrimaryActionDisabled
      onActionText="Confirm changes"
    >
      <FormProvider {...methods}>
        <Content packageInstance={packageInstance} clientId={clientId} />
      </FormProvider>
    </SmallModal>
  );
};

const Content: React.FC<{
  packageInstance: PackageInstanceCard;
  clientId: string;
}> = ({ packageInstance, clientId }) => {
  const { register, formState, watch, control } = useFormContext();
  const operationCount = Number(watch("total") ?? 0);
  const applyRecurring = watch("applyRecurring");

  const { data: schedulers } = useSchedulers();
  const { packageSchedulers } = usePackageSchedulers(
    clientId,
    packageInstance.id
  );

  const items = watch().items;

  const countItems = (items: any[]): number =>
    items.reduce((acc, item) => acc + Number(item.quantity), 0);

  const validateSessionCount = (val: any): string | undefined => {
    if (
      val.length &&
      getNewTotalSessionCount() < getBookedSessionCount().value
    ) {
      return `Cannot decrease the number of sessions below the currently booked amount: ${
        getBookedSessionCount().description
      }`;
    }

    if (val && getNewTotalSessionCount() < 1) {
      return "At least one session is required";
    }

    return undefined;
  };

  useFieldArray({
    control,
    name: "items",
    rules: {
      validate: (val) => {
        const sum = countItems(val);
        return validateSessionCount(sum);
      },
    },
  });

  const getBookedSessionCount = useCallback(() => {
    const currentCycle = getCurrentCycle(packageInstance);
    const {
      isContentTypeTime,
      isRollOver,
      totalAppointmentsConsumedInMinutes,
      totalSessionsConsumedInTotalAppointments,
    } = getPackageInstanceInfo(packageInstance, true, currentCycle);

    const consumedFromInfo = isContentTypeTime
      ? totalAppointmentsConsumedInMinutes
      : totalSessionsConsumedInTotalAppointments;
    const apptsConsumed = isRollOver
      ? packageInstance?.totalBooked ?? 0
      : consumedFromInfo;

    if (isContentTypeTime) {
      return {
        value: apptsConsumed / 60,
        unit: "hours",
        description: `${apptsConsumed} minutes`,
      };
    }

    return {
      value: apptsConsumed,
      unit: "sessions",
      description: `${apptsConsumed} sessions`,
    };
  }, [packageSchedulers, packageInstance, clientId]);

  const getNewTotalSessionCount = useCallback(() => {
    const existingTotal = getRecurringCycleTotal(packageInstance) ?? 0;
    if (packageInstance.distributeSessions) {
      if (packageInstance.frequency) {
        return (getRecurringCycleTotal(packageInstance) ?? 0) + operationCount;
      }
      return operationCount + existingTotal;
    }

    return countItems(packageInstance.items) + countItems(items ?? []);
  }, [operationCount, applyRecurring, packageInstance, items]);

  const packageInstanceUnit = getPackageInstanceUnit(
    packageInstance,
    applyRecurring
  );
  const currentTotal = (
    <>
      Current total: {getNewTotalSessionCount() - operationCount}{" "}
      {packageInstanceUnit}
      <br />
      New total: {getNewTotalSessionCount()} {packageInstanceUnit}
    </>
  );

  if (!packageInstance.distributeSessions) {
    return (
      <div>
        {packageInstance.items.map((item, index) => {
          return (
            <PackageSchedulerItem
              key={item.schedulerId}
              scheduler={
                schedulers?.find(
                  (scheduler) => item.schedulerId === scheduler.id
                ) as SchedulerWithItemType
              }
              index={index}
              withQuantityField
              showIcon
              modify
              customNumberValidation={(value) => {
                return validateSessionCount(value);
              }}
              positiveNumberOnly={false}
            />
          );
        })}
        {formState.errors.items && (
          <ErrorMessage className="mb-2">
            {formState.errors.items.root?.message?.toString()}
          </ErrorMessage>
        )}
        <div className="p-4 rounded-xl bg-blue-950 text-blue-250">
          {currentTotal}
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <TextFieldForm
        register={register}
        type="number"
        containerClassName="relative"
        name="total"
        customValidation={(val: any) => {
          return validateSessionCount(val);
        }}
        errors={formState.errors}
        helper={getPackageInstanceUnit(packageInstance, applyRecurring)}
        helperClassName="bg-grey-950 text-grey-500"
      />
      {packageInstance.packageType === "recurring" && operationCount >= 0 && (
        <ToggleWithInfo
          label="Change for all cycles"
          name="applyRecurring"
          control={control}
          description=""
          className="mb-4"
        />
      )}
      <div className="p-4 rounded-xl bg-blue-950 text-blue-250">
        {currentTotal}
      </div>
    </div>
  );
};

export default PackageInstanceModifyContent;
