import { FC } from "react";

import { useContact } from "@hooks/data/clients";
import { useCallbackRef } from "@hooks/use-ref-callback";
import { OrganizationType } from "@lib/data/schemas/organization";

import About from "@components/About/About";
import OrganizationMemberSidebarInformation from "@components/OrganizationMemberSidebarInformation";
import PackageInstances, {
  PackageInstancesProps,
} from "@components/PackageInstances/PackageInstances";
import SharedTodos from "@components/Todos/SharedTodos";

interface ClientRecordSidebarProps {
  contactId: string;
  organization: OrganizationType;
  isMd: boolean;
  onClientAssigneeClick?: () => void;
  packageInstances: PackageInstancesProps["packageInstances"];
  selectedPackageInstanceIndex: PackageInstancesProps["selectedPackageInstanceIndex"];
  setSelectedPackageInstanceIndex: PackageInstancesProps["setSelectedPackageInstanceIndex"];
  setShowPackageFilter?: (show: boolean) => void;
  showPackageFilter?: boolean;
}

const ClientRecordSidebar: FC<ClientRecordSidebarProps> = ({
  contactId,
  organization,
  isMd,
  onClientAssigneeClick,
  packageInstances,
  selectedPackageInstanceIndex,
  setSelectedPackageInstanceIndex,
  setShowPackageFilter,
  showPackageFilter,
}) => {
  const { contact, parentContact } = useContact(contactId);
  const [ref] = useCallbackRef();

  return contact ? (
    <div ref={ref} className="pb-4 mt-0 md:overflow-show">
      <About
        className="mt-2"
        client={contact}
        coachId={organization?.id}
        onClientAssigneeClick={onClientAssigneeClick}
      />
      {parentContact && (
        <OrganizationMemberSidebarInformation
          client={contact}
          parent={parentContact}
        />
      )}
      <PackageInstances
        packageInstances={packageInstances}
        selectedPackageInstanceIndex={selectedPackageInstanceIndex}
        setSelectedPackageInstanceIndex={setSelectedPackageInstanceIndex}
        org={organization}
        clientId={contactId}
        isCoach
        variant="internal"
        setShowPackageFilter={setShowPackageFilter}
        showPackageFilter={showPackageFilter}
        parentContact={parentContact}
      />
      <SharedTodos
        className="mt-4"
        coachId={organization?.id}
        client={contact}
        isCollapsible={!isMd}
        isDefaultCollapsed={!isMd}
        title={contact?.tasks?.title}
        messaging={false}
      />
    </div>
  ) : null;
};

export default ClientRecordSidebar;
