import { PackageInstanceCard } from "@practice/sdk";

import usePausePackage from "@hooks/use-pause-package";
import useSnackbar from "@hooks/use-snackbar";

import PauseIcon from "@components/Icons/PauseIcon";
import SmallModal from "@components/Modals/SmallModal";

const PackageInstancePauseContent: React.FC<{
  packageInstance: PackageInstanceCard;
  toggle: () => void;
  clientId: string;
  clientParentId?: string;
  orgId: string;
}> = ({ toggle, clientId, clientParentId, orgId, packageInstance }) => {
  const snackbar = useSnackbar();

  const { loading, pausePackageInstance } = usePausePackage(
    orgId,
    clientId,
    clientParentId,
    {
      failMode: "throw",
      logName: "PackageInstancePauseContent",
    }
  );

  const onSubmit = async () => {
    try {
      await pausePackageInstance(packageInstance.id);
      toggle();
    } catch (e) {
      snackbar.showWarning("Failed to update package instance");
    }
  };

  return (
    <SmallModal
      show
      toggleShow={toggle}
      icon={PauseIcon}
      iconColor="blue"
      title="Pause package"
      description="We'll pause all reminders and session renewal for this package. Clients can still book any outstanding appointments but new sessions will not accrue."
      onAction={onSubmit}
      onActionLoading={loading}
      isCloseAfterPrimaryActionDisabled
      onActionText="Pause package"
    />
  );
};

export default PackageInstancePauseContent;
