import { FC, useEffect, useState } from "react";
import { PackageInstanceCard } from "@practice/sdk";
import { ChosenAppointmentType } from "api-services/definitions/package-instances";
import classNames from "classnames";
import moment from "moment";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { useSchedulers } from "@hooks/data/schedulers";
import useAccessType from "@hooks/use-access-type";
import useAppointments from "@hooks/use-appointments";
import { useMutatePackages } from "@hooks/use-client-organization-data";
import { usePackages } from "@hooks/use-packages";
import { useCheckScreenSize } from "@hooks/use-window-size";
import { getNormalizedDate } from "@lib/appointments";
import { AccountType } from "@lib/data/schemas/account";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageType } from "@lib/data/schemas/packages";
import { checkIfApptAdheresToPackageRules } from "@lib/packages/package-rules";
import { validateApptsAdded } from "@lib/packages/validate-appts-added";
import { momentDate } from "@lib/utils/todos";

import AppHeader from "@components/App/AppHeader";
import EmptyListLarge from "@components/EmptyListLarge";
import SearchableMultiSelectForm from "@components/Form/SelectForm/SearchableMultiSelectForm";
import { CustomOptionType } from "@components/Form/SelectForm/types";
import DoubleChevronOpenIcon from "@components/Icons/DoubleChevronOpenIcon";
import InfoFilledIcon from "@components/Icons/InfoFilledIcon";
import ContentListLayout from "@components/Layout/ContentListLayout";
import LoadingSpinner from "@components/LoadingSpinner";
import Modal from "@components/Modals/Modal";
import PackageCyclePreview from "@components/Package/PackageCyclePreview";
import { SchedulerItem } from "@components/SchedulerPicker";
import { useSelectableListContext } from "@components/SelectableList";
import { SelectableList } from "@components/SelectableList";
import Tabs from "@components/Tabs/Tabs";
import { Tooltip } from "@components/Tooltips/Tooltip";

interface AddApptsToPackageInstanceModalProps {
  show: boolean;
  setShow: (val: boolean) => void;
  handleSubmit: (data: ChosenAppointmentType[]) => Promise<void>;
  packageData?: PackageInstanceCard | PackageType;
  startDate?: string | Date;
  existingPackageAppts?: AppointmentType[];
  clientId: string;
  showCyclePreview?: boolean;
  clientParentId?: string;
}

const AddApptsToPackageInstanceModal: FC<
  AddApptsToPackageInstanceModalProps
> = ({
  show,
  setShow,
  handleSubmit,
  packageData,
  startDate,
  existingPackageAppts,
  clientId,
  showCyclePreview = false,
  clientParentId,
}) => {
  const { aid, oid } = useAuth();
  const { active: schedulers } = useSchedulers();
  const { accessType } = useAccessType();
  const router = useRouter();
  const { selected } = useSelectableListContext();
  const { isMD, isLG } = useCheckScreenSize();
  const mutatePackages = useMutatePackages({
    clientId,
    parentId: clientParentId,
  });

  const packageSchedulerIds = packageData?.items?.map(
    (item: any) => item.schedulerId
  );
  const packageSchedulers = schedulers?.filter(
    (s) => packageSchedulerIds?.includes(s.id)
  );

  const packageId = (packageData as PackageInstanceCard)?.packageId;
  const { getPackage } = usePackages();
  const packageType = getPackage(packageId) as PackageType;
  const [chosenAppointments, setChosenAppointments] = useState<{
    [key: string]: { schedulerId: string };
  }>({});
  const [error, setError] = useState<string | null>(null);
  const { view } = router.query;
  const [loading, setLoading] = useState<boolean>(false);

  const momentStartDate = momentDate(startDate).startOf("day");

  const {
    appointments: unfilteredAppts,
    loadMore,
    hasMore,
  } = useAppointments({
    aid,
    oid,
    accessType,
    startDate: momentStartDate.toDate(),
    isPast: view === "past",
    limitOverride: 10,
    clientId,
  });

  const appointments = unfilteredAppts?.filter((appt) => {
    const apptFollowsRules = checkIfApptAdheresToPackageRules(
      appt,
      packageType
    );
    return !appt.packageInstanceId && apptFollowsRules;
  });
  const hasAppointments = appointments && appointments?.length > 0;

  const options = packageSchedulers?.map((scheduler) => ({
    value: scheduler.id,
    label: (
      <div className="flex items-center overflow-hidden truncate text-sm">
        <SchedulerItem
          title={scheduler.title}
          duration={scheduler.duration}
          icon={scheduler.icon}
          account={scheduler.account as AccountType}
        />
      </div>
    ),
    extra: {
      search: scheduler.title,
    },
  }));

  const onSubmit = async () => {
    setLoading(true);
    const selectedAppointments = selected.map((s) => {
      const sel = chosenAppointments[s.id];
      const schedulerId = sel?.schedulerId ?? options?.[0].value;
      return {
        appointmentId: s.id,
        schedulerId,
      };
    });
    await handleSubmit(selectedAppointments);
    await mutatePackages();
    setShow(false);
  };

  const isRecurring = packageData?.packageType === "recurring";

  useEffect(() => {
    const res = validateApptsAdded({
      selected: selected as AppointmentType[],
      packageType: packageType,
      packageInstance:
        packageData && "packageId" in packageData ? packageData : undefined,
      existingPackageAppts: existingPackageAppts as AppointmentType[],
    });
    if (res.errors) {
      setError(res.errors[0]?.message);
    } else setError(null);
  }, [
    selected,
    packageSchedulers,
    options,
    existingPackageAppts,
    packageData,
    packageType,
  ]);

  const apptCountValidation = () => {
    if (!error) return null;
    return (
      <Tooltip
        className="self-center"
        contentClassName="!max-w-fit"
        trigger={
          <InfoFilledIcon className="text-action-500 ring-inset ring-4 rounded-full ring-action-900" />
        }
      >
        <p>{error}</p>
      </Tooltip>
    );
  };

  const gridClasses = classNames(
    "items-center grid pl-0 sm:pl-4 pr-4 gap-x-4 flex-1",
    isRecurring && showCyclePreview
      ? "lg:grid-cols-[140px_2fr_3fr_2fr]"
      : "lg:grid-cols-[140px_2fr_3fr]"
  );

  const updateChosenAppointments = (
    apptId: string,
    payload: { schedulerId?: string }
  ) => {
    setChosenAppointments({
      ...chosenAppointments,
      [apptId]: {
        ...chosenAppointments[apptId],
        ...payload,
      },
    });
  };

  const renderTitle = (title: string, className: string) => (
    <h1 className={classNames("truncate", className)}>{title}</h1>
  );

  const renderAppts = (
    <div className="mt-4">
      {hasAppointments && (
        <div className="border-b hidden lg:flex flex-1">
          <div className="w-10" />
          <div
            className={classNames(
              "pb-4 text-grey-500 text-sm w-full",
              gridClasses
            )}
          >
            <div>Date</div>
            <div>Title</div>
            <div>Scheduler</div>
            {isRecurring && showCyclePreview && <div>Cycle</div>}
          </div>
        </div>
      )}
      <SelectableList
        showAll
        alwaysShowCheckbox
        rowRenderer={(item) => {
          const date = moment(item.start).format("ddd, MMM D, YYYY");
          const start = moment(item.start).format("h:mm A");
          const end = moment(item.end).format("h:mm A");
          return (
            <div className={classNames("lg:h-20 py-4 lg:py-0", gridClasses)}>
              {renderTitle(item.title ?? "", "lg:hidden")}
              <div>
                <h3 className="truncate text-grey-500 text-xs lg:text-base lg:text-black">
                  {date}
                </h3>
                <h5 className="text-sm text-grey-500 truncate hidden lg:block">
                  {start} - {end}
                </h5>
              </div>
              {renderTitle(item.title ?? "", "hidden lg:block")}
              <div className="my-2 lg:my-0">
                <SearchableMultiSelectForm
                  defaultValue={options?.[0]}
                  isDisabled={false}
                  name={`schedulerId-${item.id}`}
                  options={options as CustomOptionType[]}
                  parents={[]}
                  isSearchable={true}
                  emptyMessage="No schedulers match your search"
                  type="schedulers"
                  valueClassNames="!bg-white"
                  controlClassNames="!bg-white"
                  label={isLG ? undefined : "Scheduler"}
                  onInputChange={(s) =>
                    updateChosenAppointments(item.id, { schedulerId: s.value })
                  }
                />
              </div>
              {isRecurring &&
                showCyclePreview &&
                "totalAvailable" in packageData && (
                  <PackageCyclePreview
                    packageInstance={packageData}
                    targetDate={getNormalizedDate(item.start)}
                    showIfIsFull
                  />
                )}
            </div>
          );
        }}
        loadingPlaceholder={<LoadingSpinner />}
        items={appointments}
        selectable
        emptyList={
          <EmptyListLarge
            title="No appointments found"
            description="Looks like you don't have any appointments, make sure that the package start date is before any appointments you'd like to add."
          />
        }
      />
    </div>
  );

  const headerButtons = [
    {
      text: `Add to package (${selected?.length})`,
      onClick: onSubmit,
      primary: true,
      disabled: !!error || !selected?.length,
      isLoading: loading,
    },
  ];

  const customBackButton = () => {
    setShow(false);
  };

  const renderLoadMore = hasAppointments && hasMore && (
    <div className="w-full flex justify-center my-6">
      <div
        onClick={() => loadMore()}
        className="flex gap-2 text-grey-500 hover:cursor-pointer"
      >
        <span>Load more</span>
        <DoubleChevronOpenIcon />
      </div>
    </div>
  );

  return (
    <Modal size="full" show={show} toggleShow={setShow}>
      <AppHeader
        variant="form"
        title={!isMD ? "" : "Assign appointments to package"}
        extraCustomButtons={apptCountValidation()}
        headerButtons={headerButtons}
        customBackButtonClick={customBackButton}
      />
      <ContentListLayout>
        <Tabs>
          <Tabs.Item name="Upcoming">{renderAppts}</Tabs.Item>
          <Tabs.Item name="Past">{renderAppts}</Tabs.Item>
        </Tabs>
      </ContentListLayout>
      {renderLoadMore}
    </Modal>
  );
};

export default AddApptsToPackageInstanceModal;
