import { FC } from "react";

import ChatIcon from "@components/Icons/ChatIcon";
import HomeIcon from "@components/Icons/HouseIcon";
import PingPong from "@components/Icons/PingPong";

import SmallModal from "./SmallModal";

interface InviteClientModalProps {
  clientName: string;
  show: boolean;
  toggleShow: (value: boolean) => void;
  setShowShareModal: (value: boolean) => void;
  showViewChat?: boolean;
  messagingLink: string;
}

const InviteClientModal: FC<InviteClientModalProps> = ({
  clientName,
  show,
  toggleShow,
  setShowShareModal,
  showViewChat,
  messagingLink,
}) => {
  const description = (
    <div className="text-black text-left mt-4 tracking-tight">
      <div className="flex gap-5 items-center">
        <div>
          <ChatIcon />
        </div>
        One shared place for your communication, accessible on Mobile Web and
        Desktop.
      </div>
      <div className="flex gap-5 items-center mt-4">
        <div>
          <HomeIcon />
        </div>
        A home for everything from notes to files and everything in between.
      </div>
    </div>
  );

  const actionText = (
    <>
      <span>Send</span>
      <div className="hidden sm:block">invitation</div>
      <div className="block sm:hidden">invite</div>
    </>
  );
  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      icon={PingPong}
      bigIcon
      title={`Invite ${clientName} to their client portal`}
      description={description}
      onAction={() => setShowShareModal(true)}
      onActionText={actionText}
      heapModalName="coach_shared_chat_invite"
      onSecondaryActionText={showViewChat ? "View client portal" : undefined}
      onSecondaryAction={
        showViewChat ? () => window.open(messagingLink) : undefined
      }
      showCancel={!showViewChat}
    />
  );
};

export default InviteClientModal;
