import { FC } from "react";
import classNames from "classnames";

import {
  ArtifactSettingsType,
  ArtifactTypes,
} from "@lib/data/schemas/artifact-settings";
import { TimelineItemType } from "@lib/shared-types";

interface TimelineItemArticleProps {
  item: TimelineItemType;
  coachSlug: string;
  contactId: string;
  clientParentId?: string;
  isCoach: boolean;
  sameDateAndTypeAsPrev: boolean;
  sameDateAndTypeAsNext: boolean;
  Component: JSX.Element;
  variant: string;
  isPast: boolean;
  isSideModal: boolean;
  artifactSettings?: ArtifactSettingsType;
  onChangeHidden?: (id: string, artifact: ArtifactTypes) => void;
}

const TimelineItemArticle: FC<TimelineItemArticleProps> = ({
  item,
  coachSlug,
  contactId,
  isCoach,
  clientParentId,
  sameDateAndTypeAsPrev,
  sameDateAndTypeAsNext,
  Component,
  artifactSettings,
  onChangeHidden,
  variant = "default",
  isPast = true,
  isSideModal = false,
}) => {
  const { __type: type } = item;
  const isZeroType = type === "zero";
  const isVariantDefault = !isZeroType && variant === "default";
  const isVariantDashed = !isZeroType && variant === "dashed";

  return (
    <article
      className={classNames(
        !sameDateAndTypeAsPrev && "pt-2",
        !sameDateAndTypeAsNext && "pb-2",
        sameDateAndTypeAsNext && "pb-0.5"
      )}
    >
      <div
        className={classNames(
          "rounded-2xl",
          "text-black-ink flex items-center",
          isZeroType && "-mt-13 border-2 border-dashed border-grey-900",
          isVariantDefault && "bg-grey-950",
          isVariantDashed &&
            "border border-dashed border-grey-900 hover:border-action-800",
          !!item?.hidden && "hover:border-grey-900"
        )}
      >
        <div className="flex w-full">
          <Component
            item={item}
            coachSlug={coachSlug}
            contactId={contactId}
            clientParentId={clientParentId}
            isCoach={isCoach}
            isPast={isPast}
            isSideModal={isSideModal}
            artifactSettings={artifactSettings}
            onChangeHidden={onChangeHidden}
          />
        </div>
      </div>
    </article>
  );
};

export default TimelineItemArticle;
