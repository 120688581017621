import { FC } from "react";

import { FormTemplateType } from "@lib/data/schemas/form-template";
import pluralHelper from "@lib/utils/pluralHelper";

import IconWithDetails from "@components/DisplayDetails/IconWithDetails";
import SearchableMultiSelectForm from "@components/Form/SelectForm/SearchableMultiSelectForm";
import { CustomOptionType } from "@components/Form/SelectForm/types";
import FormIcon from "@components/Icons/FormIcon";
import TypeIllustration from "@components/TypeIllustration";

import FormWrappedSmallModal from "../FormWrappedSmallModal";

export type InternalFormPickerType = { internalFormId: string };

interface InternalFormTemplatePickerModalProps {
  show: boolean;
  toggleShow: (show: boolean) => void;
  internalForms: FormTemplateType[];
  onSubmit: (data: InternalFormPickerType) => void;
}

const InternalFormTemplatePickerModal: FC<
  InternalFormTemplatePickerModalProps
> = ({ show, toggleShow, internalForms, onSubmit }) => {
  const getIcon = (form: FormTemplateType) => (
    <TypeIllustration height={24} width={24} slug={form.icon} fileType="svg" />
  );

  const options = internalForms?.map((form) => ({
    label: (
      <IconWithDetails
        icon={getIcon(form)}
        iconClassNames="flex-none bg-transparent"
        title={form.title}
        wrapperClassNames="!pr-0"
        subtitle={pluralHelper(form.items.length, "question")}
      />
    ),
    value: form.id,
    extra: {
      search: form.title,
    },
  }));

  const placeholder = (
    <div className="flex gap-4 py-4 text-black">
      <FormIcon />
      <span>Choose a form template</span>
    </div>
  );

  return (
    <FormWrappedSmallModal
      show={show}
      toggleShow={toggleShow}
      title="Internal form"
      description="Choose an existing form template if you'd like to continue."
      icon={FormIcon}
      onActionText="Continue with template"
      onAction={onSubmit}
      isCloseAfterPrimaryActionDisabled
      defaultValues={{
        internalFormId: "",
      }}
    >
      <SearchableMultiSelectForm
        name="internalFormId"
        options={options as CustomOptionType[]}
        parents={[]}
        isSearchable={true}
        emptyMessage="No forms match your search"
        type="forms"
        placeholder={placeholder}
        required
      />
    </FormWrappedSmallModal>
  );
};

export default InternalFormTemplatePickerModal;
