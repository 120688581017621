import { FC, useMemo } from "react";
import { postArtifactSettings } from "api-services/definitions/artifact-settings";
import { useApi } from "api-services/endpoints";

import { useAuth } from "@contexts/auth";
import {
  ArtifactSettingsType,
  ArtifactTypes,
} from "@lib/data/schemas/artifact-settings";

import ViewOffIcon from "@components/Icons/ViewOffIcon";
import ViewOnIcon from "@components/Icons/ViewOnIcon";
import MoreMenu from "@components/Menu/MoreMenu";

type SingularArtifactType = Record<ArtifactTypes, string>;

const singularArtifact: SingularArtifactType = {
  emailThreads: "email",
  links: "link",
  notes: "note",
  subscriptions: "subscription",
  payments: "invoice",
  files: "file",
  folders: "folder",
  forms: "form",
};

interface CommonMoreMenuProps {
  artifact: ArtifactTypes;
  artifactSettings?: ArtifactSettingsType;
  item: any;
  onChangeHidden?: (id: string, artifact: ArtifactTypes) => void;
}

const CommonMoreMenu: FC<CommonMoreMenuProps> = ({
  artifact,
  item,
  artifactSettings,
  onChangeHidden,
}) => {
  const { oid, aid } = useAuth();
  const { apiCall } = useApi(postArtifactSettings);
  const hidden = useMemo(() => {
    const currentItem = artifactSettings?.[artifact] || {};
    return currentItem?.hidden?.includes(item.id);
  }, [artifactSettings]);

  const changeVisibility = async (value: boolean) => {
    if (!oid || !aid) return;

    await apiCall(
      {
        userId: oid,
        accountId: aid,
      },
      {
        artifact,
        field: "hidden",
        value: item.id,
        action: value ? "remove" : "add",
      },
      {}
    );
    onChangeHidden?.(item.id, artifact);
  };

  return (
    <MoreMenu
      name={artifact}
      buttonClassName="bg-white rounded-md !w-12 !h-12"
      menuClassName="!mt-14"
      options={[
        {
          icon: hidden ? ViewOnIcon : ViewOffIcon,
          children: `${hidden ? "Unhide" : "Hide"} ${
            singularArtifact[artifact]
          }`,
          onClick: () => {
            changeVisibility(!!hidden);
          },
        },
      ]}
    />
  );
};

export default CommonMoreMenu;
