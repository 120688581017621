import { compact } from "lodash";
import { CollectionQueryType, useCollection } from "swr-firebase";

import {
  SmartActionDataStatusTypes,
  SmartActionDataType,
} from "@lib/shared-types";

type UseSmartActionDataType = ({
  coachId,
  clientId,
  status,
}: {
  coachId: string;
  clientId: string;
  status?: SmartActionDataStatusTypes;
}) => {
  smartActions: SmartActionDataType[];
  loading: boolean;
};
type WhereType = CollectionQueryType<SmartActionDataType>["where"];

const useContactSmartActions: UseSmartActionDataType = ({
  coachId,
  clientId,
  status,
}) => {
  const where = compact([
    ["coachId", "==", coachId],
    ["clientId", "==", clientId],
    status && ["status", "==", status],
  ]) as WhereType;

  const { data: smartActions, loading } = useCollection<SmartActionDataType>(
    "smartActions",
    {
      listen: true,
      where,
    }
  );

  return { smartActions: smartActions ?? [], loading };
};

export default useContactSmartActions;
