import React, { FC } from "react";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#00338C"
        fillRule="evenodd"
        d="M5.507 30.362a1.5 1.5 0 01-1.922-.897c-1.181-3.247-1.329-6.029-.647-8.29.688-2.282 2.182-3.895 3.998-4.821 3.517-1.794 8.338-1.024 10.845 2.319 1.317 1.756 3.072 2.23 4.518 1.874 1.45-.356 2.823-1.603 3.225-3.815a1.5 1.5 0 012.952.536c-.598 3.288-2.779 5.534-5.461 6.193-2.686.66-5.641-.331-7.634-2.988-1.534-2.045-4.713-2.655-7.082-1.447-1.128.575-2.05 1.558-2.489 3.015-.445 1.477-.432 3.576.594 6.398a1.5 1.5 0 01-.897 1.923z"
        clipRule="evenodd"
      ></path>

      <path
        fill="#00338C"
        fillRule="evenodd"
        d="M5.507 30.362a1.5 1.5 0 01-1.922-.897c-1.181-3.247-1.329-6.029-.647-8.29.688-2.282 2.182-3.895 3.998-4.821 3.517-1.794 8.338-1.024 10.845 2.319 1.317 1.756 3.072 2.23 4.518 1.874 1.45-.356 2.823-1.603 3.225-3.815a1.5 1.5 0 012.952.536c-.598 3.288-2.779 5.534-5.461 6.193-2.686.66-5.641-.331-7.634-2.988-1.534-2.045-4.713-2.655-7.082-1.447-1.128.575-2.05 1.558-2.489 3.015-.445 1.477-.432 3.576.594 6.398a1.5 1.5 0 01-.897 1.923z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F1C27D"
        d="M14.414 5.53l3.121-3.121 10.486 10.485v3.1l-3.122.021L14.414 5.53z"
      ></path>
      <path
        fill="#E46767"
        d="M13.354 4.47a.5.5 0 010-.708l2.408-2.408a.5.5 0 01.707 0l1.06 1.06-3.115 3.116-1.06-1.06z"
      ></path>
    </svg>
  );
};

export default Icon;
