import { FC, useCallback, useState } from "react";
import { AppointmentApi } from "@practice/sdk";

import { useSDKApiExecute } from "@hooks/use-sdk-api";
import { AppointmentType } from "@lib/data/schemas/appointment";

import { Checkbox } from "@components/Form/Checkbox";
import TrashIcon from "@components/Icons/TrashIcon";
import SmallModal from "@components/Modals/SmallModal";

interface DeleteAppointmentModalProps {
  item: AppointmentType;
  visible: boolean;
  toogle: () => void;
  organizationId: string;
  onSuccess?: (series: boolean) => void;
}

export const DeleteAppointmentModal: FC<DeleteAppointmentModalProps> = ({
  item,
  visible,
  toogle,
  organizationId,
  onSuccess,
}) => {
  const { execute, loading } = useSDKApiExecute(AppointmentApi, {
    method: "deleteAppointment",
    errorLogMessage: "Error deleting appointment",
    keyOrigin: "DeleteAppointmentModal",
    showErrorSnackbar: true,
  });

  const [hardDelete, setHardDelete] = useState(false);

  const onDelete = useCallback(
    async (series: boolean) => {
      await execute({
        appointmentId: item.id,
        deleteAppointmentRequest: {
          series,
          soft: !hardDelete,
        },
        organizationId,
      });
      toogle();
      onSuccess?.(series);
    },
    [item.id, organizationId, hardDelete]
  );

  return (
    <SmallModal
      title="Are you sure?"
      description={`Deleting this appointment cannot be undone`}
      show={visible}
      icon={TrashIcon}
      iconColor="peach"
      showCancel
      onAction={() => onDelete(false)}
      isCloseAfterPrimaryActionDisabled={true}
      disableClose={loading}
      isActionDisabled={loading}
      isSecondaryActionDisabled={loading}
      onActionText="Delete"
      onSecondaryActionText={
        item.recurring || item.gcal?.event?.recurringEventId
          ? "Delete entire series"
          : undefined
      }
      isCloseAfterSecondaryActionDisabled={true}
      onSecondaryAction={() => onDelete(true)}
      toggleShow={toogle}
    >
      {!item.customEventInformation && (
        <label className="flex items-center justify-center my-12 text-grey-500">
          <Checkbox
            checked={hardDelete}
            value={hardDelete}
            defaultChecked={hardDelete}
            onChange={() => setHardDelete(!hardDelete)}
          />
          <div className="ml-3">Delete this event off your calendar</div>
        </label>
      )}
    </SmallModal>
  );
};
