import { FC, useMemo } from "react";
import classNames from "classnames";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";
import { getLibrayItemStatus } from "@components/FilesAndLinks/FilesAndLinksListItem";

import CommonMoreMenu from "./CommonMoreMenu";
import RenderWithLink from "./RenderWithLink";
import { classes } from "./shared";
import { TimelineArtifactItemProps } from "./types";

export const File: FC<TimelineArtifactItemProps> = ({
  item,
  contactId,
  isCoach,
  artifactSettings,
  onChangeHidden,
}) => {
  let linkUrl = item.sharedWithData
    ? `/library/files/${item.id}`
    : `/contacts/${contactId}/files/${item.id}`;

  if (!isCoach) {
    linkUrl = [
      `/me/${item?.coachUserId}`,
      !item.sharedWithData && `clients/${contactId}`,
      `files/${item.id}`,
    ]
      .filter(Boolean)
      .join("/");
  }

  const hidden = useMemo(() => {
    const { files } = artifactSettings || {};
    return files?.hidden?.includes(item.id);
  }, [artifactSettings]);

  const renderMoreMenu = isCoach ? (
    <CommonMoreMenu
      artifact="files"
      item={item}
      artifactSettings={artifactSettings}
      onChangeHidden={onChangeHidden}
    />
  ) : undefined;

  return (
    <RenderWithLink
      isCoach={isCoach}
      item={item}
      linkUrl={linkUrl}
      moreMenu={renderMoreMenu}
      isHidden={hidden}
    >
      <ArtifactStatusIcon
        url={item.url}
        status={getLibrayItemStatus(item)}
        type="files"
        className="mr-4"
      />
      <div className={classNames(classes.content, "truncate")}>
        <p className="truncate">{item.fileName}</p>
        <ArtifactStatusText
          status={getLibrayItemStatus(item)}
          type="files"
          size="xs"
        />
      </div>
    </RenderWithLink>
  );
};
