import { isEmpty } from "lodash";

import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { PackageType } from "@lib/data/schemas/packages";

type PackageRulesType = PackageType | PackageInstanceType;

export const checkIfApptAdheresToOutcomeRules = (
  appt: AppointmentType | { outcome: { outcomeId: string } },
  packageInstance: PackageRulesType
) => {
  const acceptedOutcomes = packageInstance?.acceptedOutcomes;
  const apptOutcomeId = appt?.outcome?.outcomeId;

  if (!acceptedOutcomes || isEmpty(acceptedOutcomes) || !apptOutcomeId)
    return true;
  return acceptedOutcomes.includes(apptOutcomeId);
};

export const checkIfApptAdheresToStatusRules = (
  appt: AppointmentType,
  packageInstance: PackageRulesType
) => {
  const autoCancel = packageInstance?.autoCancelOutcome;
  if (!autoCancel) return true;
  return appt.status !== "cancelled";
};

export const checkIfApptAdheresToPackageRules = (
  appt: AppointmentType,
  packageInstance: PackageRulesType
) => {
  const adheresToOutcomeRules = checkIfApptAdheresToOutcomeRules(
    appt,
    packageInstance
  );
  const adheresToStatusRules = checkIfApptAdheresToStatusRules(
    appt,
    packageInstance
  );

  return adheresToOutcomeRules && adheresToStatusRules;
};
