import { FC } from "react";
import { PackageInstanceCard } from "@practice/sdk";
import { PackageSchedulerType } from "api-services/definitions/package-instances";
import { compact } from "lodash";

import { useUpdatePackageInstanceSchedulerRates } from "@hooks/use-package-instance";

import Label from "@components/Form/Label";
import PencilIcon from "@components/Icons/PencilIcon";
import FormWrappedSmallModal from "@components/Modals/FormWrappedSmallModal";
import { SchedulerWithItemType } from "@components/Package/PackageForm";
import PackageSchedulerItem from "@components/Package/PackageSchedulerFormItem";

type FormDataType = {
  currency: string;
  items: {
    usagePricing: {
      amount: number;
      schedulerId: string;
      currency: string;
      taxTypeId: string;
    };
  }[];
};

interface EditSchedulerRatesFormProps {
  schedulers: PackageSchedulerType[];
}

const EditSchedulerRatesForm: FC<EditSchedulerRatesFormProps> = ({
  schedulers,
}) => {
  return (
    <>
      <Label className="mb-1">Rate per session</Label>
      <div className="space-y-2">
        {schedulers.map((scheduler, index) => (
          <PackageSchedulerItem
            wrapperClassNames="!mb-0"
            index={index}
            key={scheduler.id}
            scheduler={scheduler as SchedulerWithItemType}
            showIcon
            withPriceField
            withQuantityField={false}
          />
        ))}
      </div>
    </>
  );
};

interface EditSchedulerRatesModalProps {
  toggle: (val: boolean) => void;
  clientId: string;
  packageInstance: PackageInstanceCard;
  schedulers: PackageSchedulerType[];
}

const EditSchedulerRatesModal: FC<EditSchedulerRatesModalProps> = ({
  toggle,
  clientId,
  packageInstance,
  schedulers,
}) => {
  const { isUpdating, update } = useUpdatePackageInstanceSchedulerRates(
    clientId,
    packageInstance.id
  );

  const currency = packageInstance.items[0]?.usagePricing?.currency || "";

  const defaultUsagePricing = {
    amount: 0,
    currency,
    taxTypeId: "no_tax",
  };

  const usagePricing = packageInstance.items.map((item) => ({
    ...(item.usagePricing ?? defaultUsagePricing),
    schedulerId: item.schedulerId,
  }));
  const defaultItems = usagePricing.map((item) => ({
    usagePricing: item,
  }));

  const hasPriceChanged = (item: (typeof defaultItems)[0]) => {
    const { schedulerId, amount } = item.usagePricing;
    const previousPrice = usagePricing.find(
      (prevItem) => prevItem.schedulerId === schedulerId
    )?.amount;
    return previousPrice !== amount;
  };

  const onSubmit = async (data: FormDataType) => {
    const usagePricingList = compact(
      data.items.map((item) =>
        hasPriceChanged(item)
          ? {
              ...item.usagePricing,
              amount: Number(item.usagePricing.amount),
            }
          : null
      )
    );
    const totalChanged = usagePricingList.length;

    if (!totalChanged) {
      return;
    }

    await update(usagePricingList);
  };

  return (
    <FormWrappedSmallModal
      show
      toggleShow={toggle}
      icon={PencilIcon}
      iconColor="green"
      title="Edit pricing rates"
      description="Customize the rate billed to client per session."
      onActionText="Save"
      onAction={onSubmit}
      defaultValues={{ currency, items: defaultItems }}
      onActionLoading={isUpdating}
      modalClassNames="overflow-y-auto"
    >
      <EditSchedulerRatesForm schedulers={schedulers} />
    </FormWrappedSmallModal>
  );
};

export default EditSchedulerRatesModal;
