import { useMemo } from "react";
import { compact, orderBy } from "lodash";
import { useCollection } from "swr-firebase";

import { ClientType } from "@lib/data/schemas/client";
import { ExtendedInvoiceType, InvoiceType } from "@lib/data/schemas/invoice";
import { mapOldInvoiceToNew } from "@lib/invoices";

type FilterPaymentsByClientIdType = (props: {
  payments: InvoiceType[];
  onlyPublic?: boolean;
  includeDrafts?: boolean;
}) => InvoiceType[];

type UseContactPaymentsType = (props: {
  uid: string | undefined;
  contactId: string | undefined;
  onlyPublic?: boolean;
  includeDrafts?: boolean;
}) => {
  payments: InvoiceType[];
  loading: boolean;
};

export const filterAndSortPayments: FilterPaymentsByClientIdType = ({
  payments,
  onlyPublic = false,
  includeDrafts = false,
}) => {
  const validStatuses: InvoiceType["status"][] = [
    "shared",
    "viewed",
    "paid",
    "failed",
    "scheduled",
    "refunded",
    "partially_refunded",
    "processing",
  ];
  if (!onlyPublic) {
    validStatuses.push("open");
    validStatuses.push("void");
  }

  if (includeDrafts) {
    validStatuses.push("draft");
  }

  const data = payments.filter(
    (payment) => validStatuses.includes(payment.status) && !payment.isDeleted
  ) as ExtendedInvoiceType[];

  const mappedPayments = compact(data.map(mapOldInvoiceToNew));
  return orderBy(mappedPayments, ["dueDate", "createdAt"], ["desc", "desc"]);
};

const useContactPayments: UseContactPaymentsType = ({
  uid,
  contactId,
  onlyPublic,
  includeDrafts,
}) => {
  const groupPath = uid && contactId ? `users/${uid}/clients` : null;
  const { data: groups, loading: loadingGroups } = useCollection<ClientType>(
    groupPath,
    {
      where: [["billingContactId", "==", contactId]],
    }
  );

  const groupIds = groups?.map((group) => group.id);
  const groupInvoicePath =
    uid && groupIds?.length ? `users/${uid}/invoices` : null;

  const { data: groupInvoices, loading: loadingGroupInvoices } =
    useCollection<InvoiceType>(groupInvoicePath, {
      where: [["contactId", "in", groupIds]],
      orderBy: ["createdAt", "desc"],
      listen: true,
    });

  const invoicesPath = uid && contactId ? `users/${uid}/invoices` : null;
  const { data: invoices, loading: loadingInvoices } =
    useCollection<InvoiceType>(invoicesPath, {
      where: [["contactId", "==", contactId]],
      orderBy: ["createdAt", "desc"],
      listen: true,
    });

  const payments = useMemo(() => {
    const paymentsWithoutNegativeValues = [
      ...(groupInvoices ?? []),
      ...(invoices ?? []),
    ];
    return filterAndSortPayments({
      payments: paymentsWithoutNegativeValues,
      onlyPublic,
      includeDrafts,
    });
  }, [groupInvoices, invoices, includeDrafts, onlyPublic]);

  return {
    payments,
    loading: loadingGroups || loadingInvoices || loadingGroupInvoices,
  };
};
export default useContactPayments;
