import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const EventTimeIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0H5V2H13V0H15V2H18V6.5C18 6.77614 17.7761 7 17.5 7H16.5C16.2239 7 16 6.77614 16 6.5V4H2V18H4.5C4.77614 18 5 18.2239 5 18.5V19.5C5 19.7761 4.77614 20 4.5 20H2C0.895431 20 0 19.1046 0 18V2H3V0ZM13 20C15.7614 20 18 17.7614 18 15C18 12.2386 15.7614 10 13 10C10.2386 10 8 12.2386 8 15C8 17.7614 10.2386 20 13 20ZM13 22C16.866 22 20 18.866 20 15C20 11.134 16.866 8 13 8C9.13401 8 6 11.134 6 15C6 18.866 9.13401 22 13 22ZM14 12V15H16V17H12V12H14Z"
      />
    </svg>
  );
};

export default EventTimeIcon;
