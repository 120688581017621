import { PackageInstanceCard } from "@practice/sdk";
import classNames from "classnames";

import DoubleChevronOpenIcon from "@components/Icons/DoubleChevronOpenIcon";
import Listbox, { ListboxOption } from "@components/Listbox";

export interface PackageInstancesSelectorProps {
  packageInstances: PackageInstanceCard[];
  selected: string;
  onChange: (selected: string) => void;
}

const PackageInstancesSelector: React.FC<PackageInstancesSelectorProps> = ({
  packageInstances,
  selected,
  onChange,
}) => {
  const isSinglePackage = packageInstances.length === 1;
  const currentSelected = packageInstances.find(
    (instance) => instance.id === selected
  );
  const isPaused = !!currentSelected?.pausedOn;

  const renderSelected = () => {
    return (
      <p
        className={classNames(
          "flex-1 flex items-center text-lg font-medium text-ellipsis overflow-hidden py-2",
          !isSinglePackage && "cursor-pointer px-1",
          isPaused ? "text-foreground/80" : "text-green-200"
        )}
      >
        <span className="flex-1 truncate">
          {currentSelected?.title ?? "No package selected"}
        </span>
        {!isSinglePackage && <DoubleChevronOpenIcon />}
      </p>
    );
  };

  if (isSinglePackage) {
    return <div className="px-4 pt-2">{renderSelected()}</div>;
  }

  return (
    <Listbox
      className="bg-transparent"
      customListboxClassNames="!bg-transparent !border-none !shadow-none bg-none"
      customMenuWrapperClassNames="rounded-t-none !rounded-b-lg !shadow-lg"
      placeholderClassName="pl-3 pr-2"
      value={selected}
      onChange={onChange}
      placeholder={renderSelected()}
    >
      {packageInstances.map((packageInstance) => (
        <ListboxOption
          key={`package-instance-selector-item-${packageInstance.id}`}
          primaryText={packageInstance.title}
          value={packageInstance.id}
          className="hover:cursor-pointer !p-0 !border-green-800"
        >
          <p
            className={classNames(
              "hover:bg-green-800 px-4 py-2 text-sm font-medium text-green-200 whitespace-nowrap text-ellipsis overflow-hidden",
              selected === packageInstance.id ? "bg-green-800" : "bg-green-900"
            )}
          >
            {packageInstance.title}
          </p>
        </ListboxOption>
      ))}
    </Listbox>
  );
};

export default PackageInstancesSelector;
