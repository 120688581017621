import {
  PackageInstanceCard,
  PackageInstanceCardCyclesInner,
  PackageInstancePublic,
  PackageInstancePublicCyclesInner,
} from "@practice/sdk";
import classNames from "classnames";
import { compact } from "lodash";
import moment from "moment";

import { formattedPackageContentTypeValue } from "@lib/packages/package-instances";

import CheckIcon from "@components/Icons/CheckIcon";
import { frequencyCopies } from "@components/Package/utils";

export interface PackageInstanceCardInfoProps {
  className?: string;
  packageInstance: PackageInstanceCard | PackageInstancePublic;
  cycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null;
}

const formattedDate = (date: Date) => {
  return moment(date).utc().format("MMMM D, YYYY");
};

const formattedAccrue = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  value: number
) => {
  const packageFrequency = packageInstance.frequency ?? "months";
  const isContentTypeSessions = packageInstance?.contentType === "sessions";
  const frequencyString = frequencyCopies[packageFrequency];

  const totalToShow = formattedPackageContentTypeValue(
    packageInstance,
    value,
    isContentTypeSessions ? "appt" : undefined
  );

  return `${totalToShow}/${frequencyString.abbreviation}`;
};

const PackageInstanceCardInfo: React.FC<PackageInstanceCardInfoProps> = ({
  className,
  packageInstance,
  cycle,
}) => {
  const isCycleBased = ["recurring", "usage", "subscription"].includes(
    packageInstance?.packageType ?? ""
  );
  const isUsageBased = packageInstance?.packageType === "usage";

  const isRecurringBased = packageInstance?.packageType === "recurring";
  const isSubscriptionBased = packageInstance?.packageType === "subscription";
  const isRecurringOrSubscriptionBased =
    isRecurringBased || isSubscriptionBased;
  const isOneTimeBased = packageInstance?.packageType === "one-time";
  const isCompleted = packageInstance?.status === "completed";
  const pausedOn = packageInstance?.pausedOn;
  const isPaused = !!pausedOn;
  const startDate =
    isCycleBased && cycle ? cycle.start : packageInstance?.startDate;

  const momentCycleStart = moment(cycle?.start);
  const momentCycleEnd = moment(cycle?.end);
  const packageInstanceEndDate = packageInstance?.endDate;
  const momentPackageInstanceEndDate = moment(packageInstanceEndDate);
  const startingInFuture = startDate && momentCycleStart > moment();
  const isCompletedDateInSelectedCycle =
    momentPackageInstanceEndDate >= momentCycleStart &&
    momentPackageInstanceEndDate <= momentCycleEnd;

  const usageStillActive = isUsageBased && !packageInstance.endDate;

  const isEnding =
    !isCompleted &&
    !usageStillActive &&
    isCompletedDateInSelectedCycle &&
    packageInstanceEndDate &&
    moment() <= momentPackageInstanceEndDate;

  const getCycleEndDate = (cycle: PackageInstanceCardCyclesInner) => {
    if (isEnding) return packageInstanceEndDate;
    if (isPaused && cycle.start <= pausedOn && cycle.end >= pausedOn)
      return pausedOn;
    return cycle.end;
  };

  const lines = compact([
    startDate && {
      label: startingInFuture ? "Starting" : "Start date",
      value: formattedDate(startDate),
    },
    isCycleBased &&
      cycle && {
        label: isEnding ? "Ending date" : "End date",
        value: formattedDate(getCycleEndDate(cycle)),
      },
    !isCycleBased && {
      label: "End date",
      value: packageInstanceEndDate
        ? formattedDate(packageInstanceEndDate)
        : "In progress",
    },
    isSubscriptionBased && {
      label: "Tier",
      value: packageInstance?.tierTitle ?? "-",
    },
    isRecurringOrSubscriptionBased &&
      cycle && {
        label: "Accrue",
        value: formattedAccrue(packageInstance, cycle.frequency),
      },
  ]);

  const renderLine = (label: string, value: string, index: number) => (
    <p
      key={`cycle-info-${index}-${packageInstance.id}`}
      className={classNames(
        "text-xs",
        isPaused ? "text-grey-500" : "text-green-200"
      )}
    >
      <span className="font-medium">{label}:</span> {value}
    </p>
  );

  const renderCompleted = isCompleted && isOneTimeBased && (
    <p className="text-xs py-1 px-2 mb-2 border border-green-800 rounded-full inline-block">
      <CheckIcon className="w-4 h-4 inline-block mr-1" />
      Package completed
    </p>
  );

  return (
    <div
      className={classNames(
        isPaused ? "text-grey-500" : "text-green-200",
        className
      )}
    >
      {isRecurringOrSubscriptionBased && (
        <p className="font-medium text-sm">Package info</p>
      )}
      {renderCompleted}
      {lines.map((line, index) => renderLine(line.label, line.value, index))}
    </div>
  );
};

export default PackageInstanceCardInfo;
