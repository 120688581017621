import { useCallback, useMemo } from "react";
import { EmailApi } from "@practice/sdk";
import axios from "axios";
import { where } from "firebase/firestore";
import { sortBy } from "lodash";
import moment from "moment";
import useSWR from "swr";

import { useAuth } from "@contexts/auth";
import {
  useGetFirestoreCollectionData,
  useGetFirestoreDocumentData,
} from "@contexts/data";
import { EmailThread } from "@lib/shared-types";

import { useRequestIdGenerator } from "./use-request-id-generator";
import { useSDKApi } from "./use-sdk-api";

const mapToClientThread = (thread: EmailThread, contactId: string) => {
  const filteredMessages = thread.messages.filter(
    (message) =>
      message.fromIds.includes(contactId) || message.toIds.includes(contactId)
  );

  return {
    ...thread,
    messages: filteredMessages,
    count: filteredMessages.length,
  };
};

export default function useEmailThreads({
  uid,
  contactId,
  coachEmails = [],
  groupId,
}: {
  uid: string;
  contactId?: string;
  coachEmails: string[];
  groupId?: string;
}) {
  const query: Parameters<typeof where>[] = [];
  if (contactId) query.push(["clientIds", "array-contains", contactId]);
  if (groupId) query.push(["groupId", "==", groupId]);
  if (!query.length)
    query.push([
      "date",
      ">=",
      moment().add(-1, "month").startOf("day").toDate(),
    ]);

  if (coachEmails.length) query.push(["coachEmail", "in", coachEmails]);
  query.push(["inTrash", "==", false]);

  const { data, error } = useGetFirestoreCollectionData({
    collectionName: uid ? `users/${uid}/threads` : undefined,
    queryList: query,
  });

  const filteredData = useMemo(() => {
    if (!data) return;
    const sorted = sortBy(data, "date").reverse();
    if (!contactId) return sorted;

    return sorted?.map((thread) => mapToClientThread(thread, contactId));
  }, [data, contactId]);

  return {
    data: filteredData,
    error,
    loading: !filteredData,
  };
}

export function useEmailThread({
  uid,
  threadId,
  clientId,
}: {
  uid?: string;
  threadId: string;
  clientId?: string;
}) {
  const { data, error } = useGetFirestoreDocumentData(
    uid ? `/users/${uid}/threads/${threadId}` : undefined
  );

  // TODO add type
  const modify = useCallback(
    async (body: any) => {
      if (!uid || !threadId || !data?.coachEmail) return;
      await axios.post(`/api/v1/gmail/${uid}/threads/${threadId}/modify`, {
        email: data.coachEmail,
        body,
      });
    },
    [uid, threadId, data?.coachEmail]
  );

  const markAsRead = useCallback(async () => {
    modify({ removeLabelIds: ["UNREAD"] });
  }, [modify]);

  const markAsUnread = useCallback(async () => {
    modify({ addLabelIds: ["UNREAD"] });
  }, [modify]);

  const mappedData = useMemo(() => {
    if (!data || !clientId) return data;

    return mapToClientThread(data, clientId);
  }, [clientId, data]);

  return {
    data: mappedData,
    error,
    validating: !mappedData,
    markAsRead,
    markAsUnread,
  };
}

export function useEmailMetadata({ contactId }: { contactId?: string }) {
  const { oid, currentWorkspace } = useAuth();
  const orgId = currentWorkspace?.id || oid;

  const generateRequestId = useRequestIdGenerator("use-email-metadata");
  const api = useSDKApi(EmailApi);

  const { data, isLoading, error, mutate } = useSWR(
    `/organizations/${orgId}/contacts/threads?contactId=${contactId}`,
    async () => {
      if (!orgId || !contactId) return;
      return api.getThreads({
        xRequestId: generateRequestId(),
        organizationId: orgId,
        contactId,
        limit: 1_000,
      });
    },
    { dedupingInterval: 60_000 }
  );

  const sorted = useMemo(() => {
    return sortBy(data?.data, "date").reverse();
  }, [data?.data]);

  const withMoment = useMemo(() => {
    return sorted.map((thread) => ({
      ...thread,
      date: moment(thread.date).toDate(),
    }));
  }, [sorted]);

  return {
    data: withMoment,
    loading: isLoading,
    error,
    mutate,
  };
}
