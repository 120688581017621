import { FC } from "react";
import { PackageInstanceCard } from "@practice/sdk";
import { compact, flatten, orderBy } from "lodash";
import moment from "moment";

import { usePackageSchedulers } from "@hooks/use-package-schedulers";
import { useOrgPackages } from "@hooks/use-packages";

import PackageInstanceAcrossRemaining, {
  getRollOverInfo,
} from "@components/PackageInstance/PackageInstanceAcrossRemaining";
import PackageSchedulerItem from "@components/PackageInstance/PackageSchedulerItem";
import {
  getPackageInstanceInfo,
  isPackageInstancePaused,
} from "@components/PackageInstance/utils";

import PackageTooltipDetails from "../PackageTooltipDetails";

interface PackageSchedulerDetailsProps {
  clientId: string;
  clientParentId?: string;
  orgId: string;
  enableDarkMode?: boolean;
  packageInstance: PackageInstanceCard;
}

const PackageSchedulerDetails: FC<PackageSchedulerDetailsProps> = ({
  clientId,
  clientParentId,
  orgId,
  packageInstance,
  enableDarkMode = false,
}) => {
  const { packageSchedulers } = usePackageSchedulers(
    clientId,
    packageInstance.id,
    orgId,
    clientParentId
  );

  const { getPackage, loading: isPackageTemplateLoading } = useOrgPackages();

  if (!packageSchedulers?.length) return null;

  const packageTemplate = getPackage(packageInstance.packageId);
  const allowFutureBooking = packageTemplate?.allowFutureBooking ?? false;

  const {
    isCompleted,
    distributeSessions,
    totalSessions,
    showSchedulerRemaining,
    formattedRemaining,
    isUsagePackage,
    isSubscriptionUBP,
  } = getPackageInstanceInfo(packageInstance, true, undefined);
  const shouldShowAcrossRemaining =
    distributeSessions &&
    !!totalSessions &&
    !isPackageTemplateLoading &&
    !allowFutureBooking;

  const bookedSchedulers = packageSchedulers?.filter(
    (p) => p.appointments?.length
  );
  const allBookedAppointments = orderBy(
    compact(flatten(bookedSchedulers.map((p) => p.appointments))),
    "start",
    "desc"
  );
  const nextAppt = allBookedAppointments?.at(0);

  const firstTitle = packageSchedulers[0]?.title;

  return (
    <PackageTooltipDetails
      title={
        packageSchedulers?.length > 1 || !firstTitle
          ? "Schedulers available"
          : `Scheduler: ${firstTitle}`
      }
      total={packageSchedulers?.length || 0}
      amount={bookedSchedulers?.length || 0}
      subtitle={
        nextAppt
          ? `Next: ${moment(nextAppt?.start).format("MMMM Do YYYY")}`
          : "No upcoming appointment"
      }
      enableDarkMode={enableDarkMode}
      isScheduler
      isPaused={isPackageInstancePaused(packageInstance)}
    >
      {shouldShowAcrossRemaining && (
        <PackageInstanceAcrossRemaining
          position="top"
          isComplete={isCompleted}
          value={formattedRemaining}
          resetDate={packageInstance?.resetDate}
          rollOverInfo={getRollOverInfo(packageInstance)}
          isPaused={isPackageInstancePaused(packageInstance)}
          isUsagePackage={isUsagePackage}
          isSubscription={isSubscriptionUBP}
        />
      )}
      <div className="divide-y">
        {packageSchedulers?.map((scheduler) => (
          <div key={scheduler.id} className="w-full px-4">
            <PackageSchedulerItem
              scheduler={scheduler}
              showSchedulerRemaining={showSchedulerRemaining}
              isUsagePackage={isUsagePackage}
            />
          </div>
        ))}
      </div>
    </PackageTooltipDetails>
  );
};

export default PackageSchedulerDetails;
