import { FC, useMemo } from "react";
import classNames from "classnames";

import useAccount from "@hooks/use-account";
import useContact from "@hooks/use-contact";
import { FormType } from "@lib/data/schemas/form";
import { InternalFormType } from "@lib/data/schemas/internal-form";
import pluralHelper from "@lib/utils/pluralHelper";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";
import AccountDetail from "@components/PublicProfile/AccountDetail";

import CommonMoreMenu from "./CommonMoreMenu";
import RenderWithLink from "./RenderWithLink";
import { classes } from "./shared";
import { TimelineArtifactItemProps } from "./types";

interface FormProps extends TimelineArtifactItemProps {
  item: (FormType | InternalFormType) & { linkUrl?: string; memberId?: string };
  coachSlug?: string;
  wrapperClassNames?: string;
}

export const Form: FC<FormProps> = ({
  item,
  coachSlug,
  contactId,
  isCoach,
  wrapperClassNames,
  artifactSettings,
  onChangeHidden,
}) => {
  const memberId = item?.memberId;
  const { contact } = useContact(contactId);
  const { account } = useAccount(memberId);

  const internalFormLink = `/internal-forms/${item.id}`;
  const clientFormLink = `/me/${coachSlug}/clients/${contactId}/forms/${item.id}`;
  const itemLink = item?.linkUrl;
  const coachFormLink = `/contacts/${contactId}/forms/${item.id}`;

  const getLinkUrl = () => {
    if (memberId) return internalFormLink;
    if (!isCoach) return clientFormLink;
    if (itemLink) return itemLink;
    return coachFormLink;
  };

  const answersLength = item.items?.filter(({ answer }) => answer).length;
  const questionsLength = item.items?.filter(
    (i) => i.questionType !== "statement"
  )?.length;

  const getSubmitters = () => {
    if (account) return [account];
    if (item.contacts) return item.contacts;
    return [contact];
  };

  const submitters = getSubmitters();

  const hidden = useMemo(() => {
    const { forms } = artifactSettings || {};
    return forms?.hidden?.includes(item.id);
  }, [artifactSettings]);

  const renderMoreMenu = isCoach ? (
    <CommonMoreMenu
      artifact="forms"
      item={item}
      artifactSettings={artifactSettings}
      onChangeHidden={onChangeHidden}
    />
  ) : undefined;

  const renderSubmitter = submitters.length && isCoach && (
    <AccountDetail
      accounts={submitters}
      showDividerBefore
      accountClassNames="text-xs font-medium"
      avatarSize="xxsmall"
    />
  );

  return (
    <RenderWithLink
      isCoach={isCoach}
      item={item}
      linkUrl={getLinkUrl()}
      isHidden={hidden}
      moreMenu={renderMoreMenu}
    >
      <ArtifactStatusIcon status={item.status} type="forms" className="mr-4" />
      <div
        className={classNames(
          "flex w-full",
          classes.content,
          wrapperClassNames
        )}
      >
        <div className="truncate">
          <h1 className="truncate">{item.title}</h1>
          <div className="flex items-center gap-2">
            <ArtifactStatusText status={item.status} type="forms" size="xs" />
            {renderSubmitter}
          </div>
        </div>
        <div className="ml-auto text-right shrink-0 group-hover:opacity-0">
          <h2>{pluralHelper(questionsLength, "question")}</h2>
          <h3 className="text-grey-500 text-sm">{`${answersLength}/${questionsLength} completed`}</h3>
        </div>
      </div>
    </RenderWithLink>
  );
};
