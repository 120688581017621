import { FC } from "react";
import { PackageInstanceCard } from "@practice/sdk";
import { compact } from "lodash";
import { useRouter } from "next/router";

import useAccessType from "@hooks/use-access-type";
import useCopyToClipboard from "@hooks/use-copy-to-clipboard";
import { ClientType } from "@lib/data/schemas/client";

import DocumentIcon from "@components/Icons/DocumentIcon";
import EventCreationIcon from "@components/Icons/EventCreationIcon";
import EventTimeIcon from "@components/Icons/EventTimeIcon";
import LinkedIcon from "@components/Icons/LinkedIcon";
import MailIcon from "@components/Icons/MailIcon";
import MarkAsReadIcon from "@components/Icons/MarkAsReadIcon";
import MathIcon from "@components/Icons/MathIcon";
import PauseIcon from "@components/Icons/PauseIcon";
import PencilIcon from "@components/Icons/PencilIcon";
import PlayTriangleOutlineIcon from "@components/Icons/PlayTriangleOutlineIcon";
import RightArrowIcon from "@components/Icons/RightArrowIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import ViewOnIcon from "@components/Icons/ViewOnIcon";
import Menu from "@components/Menu/Menu";
import MoreMenu, { OptionType } from "@components/Menu/MoreMenu";
import {
  getPackageInstanceInfo,
  isPackageInstancePaused,
} from "@components/PackageInstance/utils";

interface PackageInstancesMoreMenuProps {
  className?: string;
  packageUrl: string;
  packageInstance: PackageInstanceCard;
  clientId: string;
  onDelete: () => void;
  onComplete: () => void;
  onActive: () => void;
  onModifyDuration?: () => void;
  onPause: () => void;
  onResume: () => void;
  iconClassNames?: string;
  setIsSharingModalOpen: (isOpen: boolean) => void;
  toggleAddApptsModal: () => void;
  toggleEditStartDateModal: () => void;
  toggleEditResetDateModal: () => void;
  toggleEditSchadulerRatesModal: () => void;
  parentContact?: ClientType;
}

const PackageInstancesMoreMenu: FC<PackageInstancesMoreMenuProps> = ({
  className,
  packageUrl,
  packageInstance,
  clientId,
  onDelete,
  onComplete,
  onActive,
  iconClassNames,
  setIsSharingModalOpen,
  toggleAddApptsModal,
  toggleEditStartDateModal,
  toggleEditResetDateModal,
  toggleEditSchadulerRatesModal,
  onModifyDuration,
  onPause,
  onResume,
  parentContact,
}) => {
  const { copyToClipboard, isCopyLabelVisible } = useCopyToClipboard();
  const router = useRouter();
  const withInvoice = !!packageInstance?.invoiceId;
  const totalPayments = packageInstance?.payments?.length ?? 0;
  const withSplitPayment =
    totalPayments > 1 && !packageInstance?.usageInvoicing;
  const { isCompleted } = getPackageInstanceInfo(packageInstance);
  const isPaused = isPackageInstancePaused(packageInstance);
  const isUsagePackage = packageInstance?.packageType === "usage";
  const isRecurringPackage = packageInstance?.packageType === "recurring";
  const { hasElevatedAccess, hasFullAccess } = useAccessType();
  const isPackageInstanceStatusCompleted =
    packageInstance.status === "completed";
  const isMarkedAsComplete = isUsagePackage
    ? isPackageInstanceStatusCompleted || !!packageInstance?.endDate
    : isPackageInstanceStatusCompleted;
  const canRemovePackage = !withSplitPayment && hasElevatedAccess;
  const canEditStartDate = hasElevatedAccess;
  const canAddSessions =
    !isMarkedAsComplete && hasElevatedAccess && !isUsagePackage;
  const canPausePackage =
    !isUsagePackage &&
    !isMarkedAsComplete &&
    !!packageInstance?.frequency &&
    !isPaused &&
    hasElevatedAccess;
  const canResumePackage = !isMarkedAsComplete && isPaused && hasElevatedAccess;
  const canMarkAsComplete = !isMarkedAsComplete && hasElevatedAccess;
  const canAssignAppointments = isUsagePackage
    ? !isMarkedAsComplete
    : !isCompleted;
  const canEditNextCycleStartDate = isRecurringPackage && !isCompleted;
  const canEditSchedulerRates = isUsagePackage && hasFullAccess;
  const canMarkAsActive = isMarkedAsComplete && !isUsagePackage;
  const canEditPackage = hasElevatedAccess;

  const showInvoice = withInvoice && !withSplitPayment;
  const canMovePackageToParent =
    hasElevatedAccess &&
    !!parentContact &&
    packageInstance.clientId !== parentContact.id;

  const options: OptionType[] = compact([
    {
      children: "Copy link",
      icon: LinkedIcon,
      onClick: () => copyToClipboard(packageUrl),
    },
    canEditStartDate && {
      children: "Edit start date",
      icon: EventTimeIcon,
      onClick: () => toggleEditStartDateModal(),
    },
    canEditNextCycleStartDate && {
      children: "Edit next cycle start date",
      icon: EventTimeIcon,
      onClick: () => toggleEditResetDateModal(),
    },
    canEditSchedulerRates && {
      children: "Edit pricing rates",
      icon: PencilIcon,
      onClick: () => toggleEditSchadulerRatesModal(),
    },
    {
      children: "Send to client",
      icon: MailIcon,
      onClick: () => setIsSharingModalOpen(true),
    },
    {
      children: "View as client",
      icon: ViewOnIcon,
      href: packageUrl,
      target: "_blank",
    },
    canAssignAppointments && {
      children: "Batch assign appts",
      icon: EventCreationIcon,
      onClick: () => toggleAddApptsModal(),
      separator: showInvoice || (!showInvoice && !withSplitPayment),
    },
    showInvoice && {
      children: "View invoice",
      icon: DocumentIcon,
      href: `/clients/${clientId}/invoices/${packageInstance?.invoiceId}`,
      separator: true,
    },
    canAddSessions && {
      children: "Add/Remove sessions",
      icon: MathIcon,
      className: "text-green-200",
      onClick: onModifyDuration,
    },
    canPausePackage && {
      children: "Pause",
      icon: PauseIcon,
      className: "text-green-200",
      onClick: onPause,
    },
    canResumePackage && {
      children: "Resume",
      icon: PlayTriangleOutlineIcon,
      className: "!text-action-500",
      onClick: onResume,
    },
    canMarkAsComplete && {
      children: "Mark as complete",
      icon: MarkAsReadIcon,
      className: "text-green-200",
      onClick: onComplete,
    },
    canMovePackageToParent && {
      children: `Move package to ${parentContact?.clientType}`,
      icon: RightArrowIcon,
      onClick: () =>
        router.push(
          `/package-instances/${packageInstance!.id}/edit?parentId=${
            parentContact!.id
          }`
        ),
    },
    canEditPackage && {
      children: "Edit package",
      icon: PencilIcon,
      onClick: () =>
        router.push(`/package-instances/${packageInstance?.id}/edit`),
    },
    canMarkAsActive && {
      children: "Mark as active",
      icon: PlayTriangleOutlineIcon,
      className: "text-green-200",
      onClick: onActive,
    },
    canRemovePackage && {
      children: "Remove",
      icon: TrashIcon,
      className: "text-peach-500",
      onClick: onDelete,
    },
  ]);

  return (
    <div className="relative">
      <MoreMenu
        name="package-instance"
        className={className}
        options={options}
        iconClassName={iconClassNames}
        menuClassName="bottom-10 top-auto"
      />
      <Menu show={isCopyLabelVisible}>
        <div className="bg-black-ink py-2 px-3 absolute text-white rounded-md text-xs bottom-0 right-0">
          Link copied
        </div>
      </Menu>
    </div>
  );
};

export default PackageInstancesMoreMenu;
