import { memo } from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { capitalize, head } from "lodash";

import { useGetFirestoreDocumentData } from "@contexts/data";
import { displayNameFromContact } from "@lib/contacts";
import {
  ArtifactSettingsType,
  ArtifactTypes,
} from "@lib/data/schemas/artifact-settings";
import { TimelineItemType } from "@lib/shared-types";
import { momentDate } from "@lib/utils/todos";

import ClientAddIcon from "@components/Icons/ClientAddIcon";
import SmileIcon from "@components/Icons/SmileIcon";
import ThunderIcon from "@components/Icons/ThunderIcon";
import StatusDot from "@components/OnTrack/StatusDot";
import { Appointment } from "@components/Timeline/Items/Appointment";
import { EmailThread } from "@components/Timeline/Items/EmailThread";
import { File as FileComponent } from "@components/Timeline/Items/File";
import { Form } from "@components/Timeline/Items/Form";
import { Invoice } from "@components/Timeline/Items/Invoice";
import { Link } from "@components/Timeline/Items/Link";
import { Note } from "@components/Timeline/Items/Note";
import { Subscription } from "@components/Timeline/Items/Subscription";
import { ZeroState } from "@components/Timeline/Items/ZeroState";
import TimelineItemArticle from "@components/Timeline/TimelineItemArticle";

import { Folder } from "./Items/Folder";
import MemberAdded from "./Items/MemberAdded";
import PackageTimelineItem, {
  PackageTimeLineItemType,
} from "./Items/PackageTimelineItem";
import { getItemDate, isOfPackageType } from "./utils";

const mapTypeToComponent = {
  appointments: Appointment,
  groupAppointments: Appointment,
  events: Appointment,
  payments: Invoice,
  recurring_payments: Invoice,
  subscriptions: Subscription,
  forms: Form,
  legal: Form,
  emailThreads: EmailThread,
  notes: Note,
  files: FileComponent,
  folders: Folder,
  links: Link,
  archivedContact: () => null,
  zero: ZeroState,
};

const headings = {
  appointments: "Appointment",
  groupAppointments: "Group appointment",
  events: "Event",
  payments: "One-time invoice",
  subscriptions: "Subscription",
  recurring_payments: "Subscription invoice",
  forms: "Form",
  notes: "Note",
  files: "File",
  folders: "Folder",
  contact: "Client added",
  archivedContact: "Client archived",
  links: "Link",
  legal: "Legal form",
  emailThreads: "Email Thread",
  invoices: "Invoice",
};

type ItemTypeType = keyof typeof headings & ("groupedMembers" | "clientJoined");

type FeedType = {
  actionId: string;
  type: string;
};

const unshownHeaderTypes = [
  "groupedMembers",
  "packageInstances",
  "packageInstanceRenewal",
  "packageInstancePause",
  "packageInstanceResume",
  "packageInstanceTrackingHistory",
];

export const mapTypeToHeading = (
  type: ItemTypeType,
  plural: boolean,
  isRecurringAppt = false
) => {
  const heading = headings[type];

  if (isRecurringAppt && !plural) return "Recurring appointment";
  if (unshownHeaderTypes.includes(type)) return;
  if (plural) return heading + "s";
  return heading;
};

const mapItemToAction = (item: FeedType) => {
  if (item.actionId === "created_from_product") {
    return "created";
  } else if (item.type === "Event" && item.actionId === "needsAction") {
    return "";
  }

  return item.actionId;
};

const isSameDayAndType = (
  current: TimelineItemType,
  sibling: TimelineItemType,
  contactId: string
) =>
  sibling &&
  current.__type === sibling.__type &&
  format(getItemDate(current, contactId), "PP") ===
    format(getItemDate(sibling, contactId), "PP");

const adjustType = (item: TimelineItemType) =>
  item && item.__type === "payments" && item.recurring
    ? { ...item, __type: "recurring_payments", type: "recurring_payments" }
    : item;

export type TimelineItemVariantTypes = "default" | "upcoming";

const useGetScheduledLibraryItemInfo = ({
  item,
  orgId,
  clientId,
}: {
  item: TimelineItemType;
  clientId: string;
  orgId: string;
}) => {
  const shouldFetch =
    ["files", "links", "folders"].includes(item.__type) &&
    item.status === "scheduled";

  const { data: privateLibraryItem } = useGetFirestoreDocumentData(
    shouldFetch
      ? `users/${orgId}/clients/${clientId}/${item.__type}/${item.resourceId}`
      : ""
  );
  const { data: publicLibraryItem } = useGetFirestoreDocumentData(
    shouldFetch ? `users/${orgId}/${item.__type}/${item.resourceId}` : ""
  );

  return privateLibraryItem || publicLibraryItem || {};
};

export const TimelineItem = memo(function TimelineItem({
  item,
  prev,
  next,
  coachSlug,
  contactId,
  clientParentId,
  isCoach,
  variant = "default",
  children,
  isSideModal = false,
  artifactSettings,
  onChangeHidden,
  isHidden = false,
  orgId,
}: {
  item: TimelineItemType;
  prev: TimelineItemType;
  next: TimelineItemType;
  coachSlug: string;
  contactId: string;
  clientParentId?: string;
  isCoach: boolean;
  variant?: TimelineItemVariantTypes;
  children?: React.ReactNode;
  isSideModal?: boolean;
  artifactSettings?: ArtifactSettingsType;
  onChangeHidden?: (id: string, artifact: ArtifactTypes) => void;
  isHidden?: boolean;
  orgId: string;
}) {
  const libItemInfo = useGetScheduledLibraryItemInfo({
    item,
    orgId,
    clientId: contactId,
  });
  item = { ...libItemInfo, ...adjustType(item) };
  prev = adjustType(prev);
  next = adjustType(next);
  const sameDateAndTypeAsPrev = isSameDayAndType(item, prev, contactId);
  const sameDateAndTypeAsNext = isSameDayAndType(item, next, contactId);
  const articleVariant =
    isHidden ||
    ["draft", "scheduled", "open"].includes(item.status as string) ||
    variant === "upcoming"
      ? "dashed"
      : "default";
  const isContactType = item.__type === "contact";
  const isGroupType =
    item.__type === "group" || item.__type === "groupedMembers";
  const isCustomType = item.__type === "custom";
  const isVariantDefault = variant === "default";
  const isVariantUpcoming = variant === "upcoming";
  const isJoinedDate = item.__type === "clientJoined";
  const isPackageType = isOfPackageType(item);

  const showTimelineArticle =
    !isContactType &&
    !isGroupType &&
    !isCustomType &&
    !isJoinedDate &&
    !isPackageType;

  return (
    <section className="relative">
      <div className="absolute top-0 left-0 sm:left-4 bottom-0 flex flex-col items-center w-4 shrink-0">
        {isContactType ? (
          <ClientAddIcon className="mt-2 ml-1" />
        ) : isJoinedDate ? (
          <>
            <SmileIcon className="my-2 text-action-500" />
            <div className="border-l flex-1" />
          </>
        ) : ["packageInstances", "packageInstanceRenewal"].includes(
            item.__type
          ) && !sameDateAndTypeAsPrev ? (
          <>
            <ThunderIcon className="text-green-200 h-7 w-7" />
            <div className="border-l flex-1" />
          </>
        ) : item.__type === "packageTrackingHistory" &&
          !sameDateAndTypeAsPrev ? (
          <>
            <div className="pt-1">
              <StatusDot withIcon status={item.trackingStatus} />
            </div>

            <div className="border-l flex-1" />
          </>
        ) : isGroupType && !sameDateAndTypeAsPrev ? (
          <>
            <div className="ml-1">
              <ThunderIcon className="text-grey-800 h-7 w-7" />
            </div>
            <div
              className={classNames(
                item.__type === "groupedMembers" && "border-l flex-1"
              )}
            />
          </>
        ) : (
          <>
            <div
              className={classNames(
                "w-2 h-2 rounded-full my-2",
                isVariantDefault && "bg-grey-800",
                isVariantUpcoming &&
                  "bg-transparent border-2 border-action-500",
                isCustomType && "border-dotted box-border",
                sameDateAndTypeAsPrev && "hidden"
              )}
            />
            <div
              className={classNames(
                "border-l flex-1",
                isVariantDefault && "border-grey-900",
                isVariantUpcoming && "border-action-500"
              )}
            />
          </>
        )}
      </div>
      <div
        className={classNames(
          "flex-1 ml-6 sm:ml-14",
          sameDateAndTypeAsNext ? "pb-0" : "pb-6"
        )}
      >
        {!sameDateAndTypeAsPrev && !isCustomType && (
          <Header
            item={item}
            sameDateAndTypeAsNext={sameDateAndTypeAsNext}
            contactId={contactId}
          />
        )}
        {showTimelineArticle && (
          <TimelineItemArticle
            item={item}
            coachSlug={coachSlug}
            contactId={contactId}
            clientParentId={clientParentId}
            isCoach={isCoach}
            sameDateAndTypeAsPrev={sameDateAndTypeAsPrev}
            sameDateAndTypeAsNext={sameDateAndTypeAsNext}
            Component={mapTypeToComponent[item.__type]}
            variant={articleVariant}
            isPast={!isVariantUpcoming}
            isSideModal={isSideModal}
            artifactSettings={artifactSettings}
            onChangeHidden={onChangeHidden}
          />
        )}
        {isGroupType && <MemberAdded item={item} />}
        {isPackageType && (
          <PackageTimelineItem
            item={item as PackageTimeLineItemType}
            sameDateAndTypeAsPrev={sameDateAndTypeAsPrev}
          />
        )}
        {children && children}
      </div>
    </section>
  );
});

const headerColor = {
  packageInstances: "!text-green-200 !font-medium",
  clientJoined: "!text-action-500 !font-medium",
};

const FeedHeader = ({
  item,
  isRecurringAppt = false,
}: {
  item: TimelineItemType;
  isRecurringAppt: boolean;
}) => {
  const color = headerColor[item.__type as keyof typeof headerColor];
  return (
    <span className={classNames(color, "font-normal")}>
      {isRecurringAppt ? "Recurring appointment" : capitalize(item.type)}{" "}
      <span className={classNames(color, "font-normal text-grey-500")}>
        {mapItemToAction(item)}
      </span>
    </span>
  );
};

// @TODO: something is making a undefined `toDate` and breaking the modal
//        to the client view. The code that checks by `?.toDate` prevents
//        the error to happens with the client until we figureout what is causing
//        the issue
export const Header = ({
  item,
  sameDateAndTypeAsNext,
  contactId,
}: {
  item: TimelineItemType;
  sameDateAndTypeAsNext: boolean;
  contactId: string;
}) => {
  const isArchivedContact = item.__type === "archivedContact";
  const isContactType = item.__type === "contact";
  const isGroupType = item.__type === "group";
  const isGroupMembersType = item.__type === "groupedMembers";
  const isEvent = item.__type === "event";
  const isJoinedDate = item.__type === "clientJoined";
  const isSubscriptionInvoice = item.__type === "recurring_payments";
  const isPackageType = isOfPackageType(item);
  const isRecurringAppt =
    item.__type === "appointments" && item?.gcal?.event?.recurringEventId;
  const feed = isContactType
    ? {
        type: displayNameFromContact(item, true),
        actionId: "added as new client",
      }
    : isArchivedContact
    ? {
        type: displayNameFromContact(item, true),
        actionId: "has been archived",
      }
    : isJoinedDate
    ? {
        type: displayNameFromContact(item.client, true),
        actionId: "joined the messaging channel",
      }
    : isGroupType
    ? {
        type: item.title,
        actionId: "created",
      }
    : isSubscriptionInvoice
    ? {
        type: "Subscription invoice",
        actionId: item.feed && item.feed[0].actionId,
      }
    : item?.feed?.length
    ? head(item?.feed)
    : {
        type: mapTypeToHeading(
          item.__type,
          sameDateAndTypeAsNext,
          isRecurringAppt
        ),
        actionId: isGroupMembersType ? "" : "created",
      };
  const title = !sameDateAndTypeAsNext ? (
    <FeedHeader
      item={
        isEvent
          ? { ...feed, type: "Event" }
          : isJoinedDate
          ? { ...feed, __type: "clientJoined" }
          : feed
      }
      isRecurringAppt={isRecurringAppt}
    />
  ) : (
    mapTypeToHeading(item.__type, sameDateAndTypeAsNext, isRecurringAppt)
  );

  return (
    <header className="flex items-center">
      {item.__type !== "zero" && (
        <div className="">
          <time className="text-grey-500 text-xs">
            {item &&
              momentDate(getItemDate(item, contactId)).format(
                "ddd, MMM D, YYYY"
              )}
          </time>
          {!isPackageType && (
            <h3
              className={classNames(
                "font-medium leading-tight text-sm",
                item.__type === "archivedContact" && "text-peach-600"
              )}
            >
              {title}
            </h3>
          )}
        </div>
      )}
    </header>
  );
};
