import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PackageInstanceApi, PackageInstanceCard } from "@practice/sdk";
import moment, { Moment } from "moment";

import { useAuth } from "@contexts/auth";
import { useMutatePackages } from "@hooks/use-client-organization-data";
import { useSDKApiExecute } from "@hooks/use-sdk-api";
import { momentDate } from "@lib/utils/todos";

import { PastDatesPicker } from "@components/Form/DatePicker";
import Label from "@components/Form/Label";
import EventCreationIcon from "@components/Icons/EventCreationIcon";
import EventTimeIcon from "@components/Icons/EventTimeIcon";
import FormWrappedSmallModal from "@components/Modals/FormWrappedSmallModal";

const EditPackageResetDateForm = () => {
  const { control } = useFormContext();

  return (
    <>
      <Label className="mb-1">Next cycle start date</Label>
      <Controller
        control={control}
        name="resetDate"
        render={({ field: { value, onChange } }) => (
          <div className="w-full custom_field_input">
            <PastDatesPicker
              CustomInputIcon={EventCreationIcon}
              date={value}
              showClearDate
              onChange={(e) => onChange(e.target.value)}
              placeholder="Select a start date"
              displayYear={true}
              isOutsideRange={(date) => {
                const resetDate = date.startOf("day");
                const today = moment().endOf("day");

                return resetDate.isSameOrBefore(today);
              }}
            />
          </div>
        )}
      />
    </>
  );
};

interface EditPackageResetDateModalProps {
  toggleEditResetDateModal: (val: boolean) => void;
  packageInstance: PackageInstanceCard;
  clientId: string;
  clientParentId?: string;
}

const EditPackageResetDateModal: FC<EditPackageResetDateModalProps> = ({
  toggleEditResetDateModal,
  packageInstance,
  clientId,
  clientParentId,
}) => {
  const { oid } = useAuth();
  const resetDate = packageInstance?.resetDate;
  const { execute, loading } = useSDKApiExecute(PackageInstanceApi, {
    method: "updatePackageInstanceNextCycleStartDate",
    errorLogMessage: "Failed to update next cycle start date",
    keyOrigin: "use-update-package-instance-next-cycle-start-date",
    showErrorSnackbar: true,
  });
  const mutatePackages = useMutatePackages({
    clientId,
    parentId: clientParentId,
  });

  const onSubmit = async (data: { resetDate: Moment }) => {
    if (!oid) return;
    await execute({
      organizationId: oid,
      packageInstanceId: packageInstance.id,
      updatePackageInstanceStartDateRequest: {
        date: data.resetDate.toDate(),
      },
    });
    await mutatePackages();
  };

  const today = moment().startOf("day");
  const tomorrow = moment().add(1, "day").startOf("day");
  const defaultResetStart = moment(resetDate)
    .startOf("day")
    .isSameOrBefore(today)
    ? tomorrow
    : momentDate(resetDate);

  return (
    <FormWrappedSmallModal
      show
      toggleShow={toggleEditResetDateModal}
      icon={EventTimeIcon}
      iconColor="green"
      title="Edit package next cycle start date"
      description="The next cycle start date can only be changed when the package is scheduled or during its initial cycle."
      onActionText="Apply new next cycle start date"
      onAction={onSubmit}
      defaultValues={{ resetDate: defaultResetStart }}
      onActionLoading={loading}
    >
      <EditPackageResetDateForm />
    </FormWrappedSmallModal>
  );
};

export default EditPackageResetDateModal;
