import { FC } from "react";

import { TimelineItemType } from "@lib/shared-types";

import StatusLabel from "@components/OnTrack/StatusLabel";

export type PackageTimeLineItemType = TimelineItemType & {
  title: string;
  clientName: string;
};

interface PackageTimelineItemProps {
  item: PackageTimeLineItemType;
  sameDateAndTypeAsPrev?: boolean;
}

const PackageTimelineItem: FC<PackageTimelineItemProps> = ({
  item,
  sameDateAndTypeAsPrev,
}) => {
  const { title, clientName } = item;

  const cycleInformation = (info: string) => (
    <div className="text-green-200 text-sm font-medium">{info}</div>
  );

  const breakInformation = (name: string, action: string) => (
    <div className="text-sm xs font-medium">
      {name} <span className="text-grey-500 font-normal">has been</span>{" "}
      {action}
    </div>
  );

  const trackingInformation = (
    title: string,
    status: TimelineItemType["trackingStatus"]
  ) =>
    sameDateAndTypeAsPrev ? null : (
      <div className="text-sm">
        <span>{title}</span>
        <span className="text-grey-500 font-base px-1">was updated to</span>
        <StatusLabel status={status} />
      </div>
    );

  switch (item.__type) {
    case "packageInstanceRenewal":
      return cycleInformation(`${title} renewed for ${clientName}`);
    case "packageInstances":
      return cycleInformation(
        `${clientName ?? ""} started a package: ${title}`
      );
    case "packageInstancePause":
      return breakInformation(title, "paused");
    case "packageInstanceResume":
      return breakInformation(title, "resumed");
    case "packageInstanceCompleted":
      return breakInformation(title, "completed");
    case "packageTrackingHistory":
      return trackingInformation(title, item.trackingStatus);
    default:
      return "";
  }
};

export default PackageTimelineItem;
