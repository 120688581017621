import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className, width = 32, height = 32 }) => {
  return (
    <svg
      className={classNames(className)}
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57532 17.0753C8.50539 16.9542 8.47042 16.8936 8.44152 16.842C6.05806 12.5855 7.49977 7.20499 11.6922 4.71046C11.743 4.68021 11.8036 4.64525 11.9247 4.57532C12.0458 4.50539 12.1064 4.47042 12.158 4.44152C16.4145 2.05806 21.795 3.49977 24.2895 7.69216C24.3198 7.743 24.3548 7.80356 24.4247 7.92468C24.4946 8.0458 24.5296 8.10636 24.5585 8.15798C26.9419 12.4145 25.5002 17.795 21.3078 20.2895C21.257 20.3198 21.1964 20.3548 21.0753 20.4247C20.9542 20.4946 20.8936 20.5296 20.842 20.5585C16.5855 22.9419 11.205 21.5002 8.71046 17.3078C8.68021 17.257 8.64525 17.1964 8.57532 17.0753Z"
        fill="#E46767"
      />
      <path
        d="M14.6895 21.4698C15.5899 21.7846 16.4626 21.7638 17.2352 21.7453C18.2967 21.72 19.1694 21.6991 19.6659 22.5591C20.735 22.4095 22.9368 21.1576 23.6282 20.2714C23.1317 19.4115 23.5861 18.6661 24.1388 17.7595C24.5411 17.0996 24.9955 16.3543 25.1731 15.4171L14.6895 21.4698Z"
        fill="#E3A75F"
      />
      <path
        d="M17.9502 19.5873L21.9125 17.2996L26.2598 24.8292C26.5398 25.3143 26.6798 25.5568 26.6924 25.7965C26.7034 26.0074 26.6474 26.2164 26.5324 26.3935C26.4017 26.5948 26.1591 26.7349 25.6741 27.0149L24.4831 27.7025C23.998 27.9826 23.7555 28.1226 23.5158 28.1352C23.3049 28.1462 23.0959 28.0902 22.9188 27.9752C22.7175 27.8444 22.5775 27.6019 22.2974 27.1169L17.9502 19.5873Z"
        fill="#F1C27D"
      />
      <circle cx="7" cy="24" r="3" fill="#F1C27D" />
    </svg>
  );
};

export default Icon;
