import React from "react";

import BlockIcon from "./Icons/BlockIcon";
import CheckIcon from "./Icons/CheckIcon";
import ClockIcon from "./Icons/ClockIcon";
import DocDraftIcon from "./Icons/DocDraftIcon";
import FailedIcon from "./Icons/FailedIcon";
import LoopIcon from "./Icons/LoopIcon";
import SharedIcon from "./Icons/SharedIcon";
import ViewOnIcon from "./Icons/ViewOnIcon";

export function backgroundColorStatus(status) {
  switch (status) {
    case "open":
    case "draft":
      return "bg-grey-900";
    case "scheduled":
      return "bg-action-900";
    case "shared":
      return "bg-blue-900";
    case "viewed":
      return "bg-action-700";
    case "paid":
      return "bg-green-600";
    case "failed":
    case "void":
      return "bg-peach-800";
  }
}

export function iconStatus(status, isRecurring) {
  if (isRecurring) {
    return <LoopIcon />;
  }

  switch (status) {
    case "open":
    case "draft":
      return <DocDraftIcon />;
    case "scheduled":
      return <ClockIcon />;
    case "shared":
      return <SharedIcon />;
    case "viewed":
      return <ViewOnIcon />;
    case "paid":
      return <CheckIcon />;
    case "failed":
      return <FailedIcon />;
    case "void":
      return <BlockIcon />;
  }
}

export function textColorStatus(status) {
  switch (status) {
    case "open":
    case "draft":
      return "text-grey-500";
    case "scheduled":
      return "text-action-500";
    case "shared":
      return "text-blue-300";
    case "viewed":
      return "text-action-400";
    case "paid":
      return "text-green-200";
    case "failed":
    case "void":
      return "text-peach-400";
  }
}

const PaymentStatusIcon = ({ status, isRecurring }) => {
  return (
    <div
      className={`${textColorStatus(status)} ${backgroundColorStatus(
        status
      )} rounded-full h-10 w-10 flex items-center justify-center`}
    >
      {iconStatus(status, isRecurring)}
    </div>
  );
};

export default PaymentStatusIcon;
