import { FC, useMemo } from "react";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";

import CommonMoreMenu from "./CommonMoreMenu";
import RenderWithLink from "./RenderWithLink";
import { classes } from "./shared";
import { TimelineArtifactItemProps } from "./types";

export const EmailThread: FC<TimelineArtifactItemProps> = ({
  item,
  contactId,
  artifactSettings,
  onChangeHidden,
  isCoach = false,
}) => {
  const { status } = item;

  const hidden = useMemo(() => {
    const { emailThreads } = artifactSettings || {};
    return emailThreads?.hidden?.includes(item.id);
  }, [artifactSettings]);

  const renderMoreMenu = isCoach ? (
    <CommonMoreMenu
      artifact="emailThreads"
      item={item}
      artifactSettings={artifactSettings}
      onChangeHidden={onChangeHidden}
    />
  ) : undefined;

  return (
    <RenderWithLink
      item={item}
      linkUrl={`/email-threads/${item.id}${
        contactId ? `?clientId=${contactId}` : ""
      }`}
      moreMenu={renderMoreMenu}
      isHidden={hidden}
    >
      <ArtifactStatusIcon
        status={status}
        type="emailThreads"
        className="mr-4"
      />
      <div className={`flex w-full ${classes.content}`}>
        <div className="truncate mr-4">
          {item.subject}
          <ArtifactStatusText status={status} type="emailThreads" size="xs" />
        </div>
        <div className="ml-auto text-black-ink text-lg font-medium bg-white rounded-lg h-8 w-8 shrink-0 flex items-center justify-center group-hover:opacity-0">
          {item.count}
        </div>
      </div>
    </RenderWithLink>
  );
};
