import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const MathIcon: FC<SVGIconProps> = ({ className, ...rest }) => (
  <svg
    className={classNames("fill-current", className)}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    fill="none"
    viewBox="0 0 26 24"
    {...rest}
  >
    <g id="ic_math">
      <g id="Union">
        <path d="M6 3C5.44772 3 5 3.44772 5 4V6H3C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H5V10C5 10.5523 5.44772 11 6 11C6.55228 11 7 10.5523 7 10V8H9C9.55228 8 10 7.55228 10 7C10 6.44772 9.55228 6 9 6H7V4C7 3.44772 6.55228 3 6 3Z" />
        <path d="M15.1716 8.41423C14.781 8.80476 14.781 9.43792 15.1716 9.82844C15.5621 10.219 16.1953 10.219 16.5858 9.82844L18 8.41421L19.4142 9.82841C19.8047 10.2189 20.4379 10.2189 20.8284 9.82841C21.219 9.43789 21.219 8.80472 20.8284 8.4142L19.4142 7L20.8284 5.5858C21.219 5.19528 21.219 4.56211 20.8284 4.17159C20.4379 3.78107 19.8047 3.78107 19.4142 4.17159L18 5.58579L16.5858 4.17156C16.1953 3.78103 15.5621 3.78103 15.1716 4.17156C14.781 4.56208 14.781 5.19524 15.1716 5.58577L16.5858 7L15.1716 8.41423Z" />
        <path d="M3 17C2.44772 17 2 17.4477 2 18C2 18.5523 2.44772 19 3 19H9C9.55228 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17H3Z" />
        <path d="M14 16C14 15.4477 14.4477 15 15 15H21C21.5523 15 22 15.4477 22 16C22 16.5523 21.5523 17 21 17H15C14.4477 17 14 16.5523 14 16Z" />
        <path d="M15 19C14.4477 19 14 19.4477 14 20C14 20.5523 14.4477 21 15 21H21C21.5523 21 22 20.5523 22 20C22 19.4477 21.5523 19 21 19H15Z" />
      </g>
    </g>
  </svg>
);

export default MathIcon;
