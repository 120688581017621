import {
  PackageInstanceCard,
  PackageInstanceCardCyclesInner,
} from "@practice/sdk";

import { getNormalizedDate } from "@lib/appointments";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageType } from "@lib/data/schemas/packages";
import {
  getAllCycleDates,
  getCycleFromDate,
} from "@lib/models/package-instances/utils";
import { getAppointmentDuration } from "@lib/utils/appointments";

type validateApptsAddedProps = {
  selected: AppointmentType[];
  packageAppointments?: AppointmentType[];
  packageType: PackageType;
  packageInstance?: PackageInstanceCard;
  existingPackageAppts: AppointmentType[];
};

type ErrorType = { apptId: string; message: string };

export const validateApptsAdded = ({
  selected = [],
  packageType,
  packageInstance,
  existingPackageAppts = [],
}: validateApptsAddedProps): {
  errors: ErrorType[];
} => {
  const isRecurring = packageType?.packageType === "recurring";
  const isUsageBased = packageType?.packageType === "usage";
  const isTimeBased = packageInstance?.contentType === "time";
  const timeType = packageInstance?.timeType ?? "hours";
  const allCycles = packageInstance ? getAllCycleDates(packageInstance) : [];
  const errorMessage = isTimeBased
    ? "Package has exceeded maximum total time"
    : "Package has exceeded maximum total sessions";

  const cyclesWithAppointments = new Map<
    number,
    {
      cycle: PackageInstanceCardCyclesInner;
      appointments: AppointmentType[];
      isValid: boolean;
    }
  >();

  const isDataValid = (
    cycle: PackageInstanceCardCyclesInner,
    appointments: AppointmentType[]
  ) => {
    const cycleInMinutes = appointments.reduce(
      (acc, appt) => acc + getAppointmentDuration(appt),
      0
    );
    const totalAvailableInMinutes =
      timeType === "minutes" ? cycle.totalAvailable : cycle.totalAvailable * 60;
    const totalAppointments = isTimeBased
      ? cycleInMinutes
      : appointments.length;
    const totalToCompare = isTimeBased
      ? totalAvailableInMinutes
      : cycle.totalAvailable;
    return totalAppointments <= totalToCompare;
  };

  const addCycleWithAppointments = (
    appt: AppointmentType,
    overwritten?: boolean
  ) => {
    if (!packageInstance) return;
    const apptCycle = getCycleFromDate(
      packageInstance,
      getNormalizedDate(appt.start)
    );

    if (!apptCycle || !allCycles) return;
    const cycleIndex = allCycles.findIndex((c) => c === apptCycle);

    if (cycleIndex === -1) return;

    const current = cyclesWithAppointments.get(cycleIndex);
    if (current) {
      current.appointments.push(appt);
      if (overwritten) {
        current.isValid = isDataValid(current.cycle, current.appointments);
      }
    } else {
      const isValid = isDataValid(apptCycle, [appt]);
      cyclesWithAppointments.set(cycleIndex, {
        cycle: apptCycle,
        appointments: [appt],
        isValid: isValid,
      });
    }
  };

  existingPackageAppts?.forEach((appt: AppointmentType) => {
    addCycleWithAppointments(appt);
  });

  selected.forEach((appt) => {
    addCycleWithAppointments(appt, true);
  });

  const cycleErrors: ErrorType[] = [];

  const hasErrors = isRecurring
    ? Array.from(cyclesWithAppointments.values()).some((c) => !c.isValid)
    : isUsageBased
    ? false
    : selected.length > (packageInstance?.totalAvailable ?? 0);

  if (packageInstance && hasErrors) {
    cycleErrors.push({
      apptId: "",
      message: errorMessage,
    });
  }

  return { errors: cycleErrors };
};
