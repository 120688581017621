import { FC } from "react";
import { useRouter } from "next/router";

import FormIcon from "@components/Icons/FormIcon";

import SmallModal from "../SmallModal";

interface AboutInternalFormsProps {
  show: boolean;
  toggleShow: (value: boolean) => void;
}

const AboutInternalForms: FC<AboutInternalFormsProps> = ({
  show,
  toggleShow,
}) => {
  const router = useRouter();

  const onAction = () => {
    router.push("/form-templates/create?isInternal=true");
  };

  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      icon={FormIcon}
      title="About internal forms"
      description="Internal forms are an easy way to collect internal knowledge and feedback from your team. Create a template first to associate it later with a client."
      onAction={onAction}
      onActionText="Create a form template"
    />
  );
};

export default AboutInternalForms;
