import React from "react";
import classNames from "classnames";

const ItemRowInvoiceDetails = ({
  title,
  value,
  isTotal = false,
  className = "",
  showAsNegative = false,
}) => {
  const totalClassNames = { "text-black-ink text-xl font-medium": isTotal };
  return (
    <div className={classNames("my-4 grid grid-cols-2", className)}>
      <p className={classNames(totalClassNames)}>{title}</p>
      <p className={classNames(totalClassNames, "text-right")}>
        {showAsNegative ? "-" : ""}
        {value}
      </p>
    </div>
  );
};

export default ItemRowInvoiceDetails;
