import { FC } from "react";
import classNames from "classnames";

import InfoIcon from "@components/Icons/InfoIcon";
import {
  Tooltip,
  TooltipKind,
  TooltipSize,
  TooltipVariant,
} from "@components/Tooltips/Tooltip";

interface PackageTooltipDetailsProps {
  total: number;
  amount: number;
  title: string;
  subtitle: string;
  children: React.ReactNode;
  enableDarkMode?: boolean;
  isScheduler?: boolean;
  isPaused?: boolean;
}

const PackageTooltipDetails: FC<PackageTooltipDetailsProps> = ({
  total,
  amount,
  title,
  subtitle,
  children,
  enableDarkMode = false,
  isScheduler = false,
  isPaused = false,
}) => {
  const trigger = (
    <div
      className={classNames(
        "rounded-full flex items-center gap-0.5 p-0.5 text-sm font-medium",

        enableDarkMode && "dark:text-white dark:bg-grey-500/20",
        isPaused
          ? "bg-foreground/5 text-foreground/80"
          : "bg-green-200/10 text-green-200 "
      )}
    >
      <div className="pl-1.5 pt-0.5">
        {isScheduler ? total : `${amount}/${total}`}
      </div>
      <InfoIcon />
    </div>
  );
  return (
    <div className="flex items-center justify-between">
      <div>
        <h2
          className={classNames(
            "font-medium text-sm",
            enableDarkMode && "dark:text-white",
            isPaused ? "text-foreground/80" : "text-green-200"
          )}
        >
          {title}
        </h2>
        {subtitle && (
          <h3
            className={classNames(
              "text-xs",
              enableDarkMode && "dark:text-grey-500",
              isPaused ? "text-foreground/50" : "text-green-500"
            )}
          >
            {subtitle}
          </h3>
        )}
      </div>
      <Tooltip
        size={TooltipSize.larger}
        variant={TooltipVariant.white}
        kind={TooltipKind.button}
        trigger={trigger}
        contentClassName="shadow mr-6 !p-0"
      >
        <div className="bg-white rounded-lg w-[300px]">{children}</div>
      </Tooltip>
    </div>
  );
};

export default PackageTooltipDetails;
