import { useUnreadChannels } from "@hooks/use-chat";

import { NotificationBubble } from "@components/NotificationBubble";

const ChatPortalHeaderButton = ({ contactId }: { contactId: string }) => {
  const { unreadChannels } = useUnreadChannels();
  const unreadMessages = unreadChannels && unreadChannels[contactId];

  return unreadMessages ? (
    <div className={"items-center hidden md:flex"}>
      Open portal
      <NotificationBubble className="ml-2">
        {unreadMessages?.unreadCount}
      </NotificationBubble>
    </div>
  ) : (
    <div className="hidden md:block">Open portal</div>
  );
};

export default ChatPortalHeaderButton;
