import { FC, ReactNode, useContext, useEffect, useState } from "react";
import { PackageInstanceCard } from "@practice/sdk";
import { deletePackageInstance } from "api-services/definitions/package-instances";
import { useApi } from "api-services/endpoints";
import classNames from "classnames";
import { orderBy } from "lodash";
import Link from "next/link";
import { PackagesContext } from "pages/contacts/[contactId]";

import useAccessType from "@hooks/use-access-type";
import { useMutatePackages } from "@hooks/use-client-organization-data";
import { usePackageInstanceInvoices } from "@hooks/use-package-instance";
import { usePackageSchedulers } from "@hooks/use-package-schedulers";
import useToggle from "@hooks/use-toggle";
import { getNormalizedDate } from "@lib/appointments";
import { ClientType } from "@lib/data/schemas/client";
import { OrganizationType } from "@lib/data/schemas/organization";
import {
  getCurrentCycle,
  getLastCycle,
} from "@lib/models/package-instances/utils";
import { getCoachBaseUrl } from "@lib/utils";
import pluralHelper from "@lib/utils/pluralHelper";

import CopyLinkIconButton from "@components/Buttons/CopyLinkIconButton";
import CollapsedHeader from "@components/DisplayDetails/CollapsedHeader";
import OpenNewTabIcon from "@components/Icons/OpenNewTabIcon";
import PackageIcon from "@components/Icons/PackageIcon";
import ViewOffIcon from "@components/Icons/ViewOffIcon";
import ViewOnIcon from "@components/Icons/ViewOnIcon";
import { WarningModal } from "@components/Modal/InfoModal";
import SharingModal from "@components/Modals/SharingModal";
import PackageTrackingStatusInfo from "@components/OnTrack/PackageTrackingStatusInfo";
import AddApptsToPackageInstanceModalWrapper from "@components/PackageInstance/AddApptsToPackageInstanceModalWrapper";
import EditPackageInstanceStartDateModal from "@components/PackageInstance/EditPackageInstanceStartDateModal";
import EditPackageResetDateModal from "@components/PackageInstance/EditPackageResetDateModal";
import EditSchedulerRatesModal from "@components/PackageInstance/EditSchedulerRatesModal";
import { isPackageInstancePaused } from "@components/PackageInstance/utils";
import PackageInstancesMoreMenu from "@components/PackageInstances/PackageInstancesMoreMenu";

import CurriculumSchedulerDetails from "./CurriculumSchedulerDetails";
import PackageBillingDetails from "./PackageBillingDetails";
import PackageCyclePagination from "./PackageCyclePagination";
import PackageCycleProgress from "./PackageCycleProgress";
import PackageInstanceActiveModal from "./PackageInstanceActiveModal";
import PackageInstanceCardInfo from "./PackageInstanceCardInfo";
import PackageInstanceCompleteModal from "./PackageInstanceCompleteModal";
import PackageInstanceDateTracker from "./PackageInstanceDateTracker";
import PackageInstanceModifyContent from "./PackageInstanceModifyContent";
import PackageInstancePauseContent from "./PackageInstancePauseContent";
import PackageInstanceResumeContent from "./PackageInstanceResumeContent";
import PackageInstancesSelector from "./PackageInstancesSelector";
import PackagePaymentDetails from "./PackagePaymentDetails";
import PackageProgress from "./PackageProgress";
import PackageSchedulerDetails from "./PackageSchedulerDetails";
import UsagePackagePaymentDetails from "./UsagePackagePaymentDetails";
import useUsagePackageCycleInvoicesAPI from "./use-usage-package-payments";

export interface PackageInstancesProps {
  className?: string;
  packageInstances: (PackageInstanceCard & {
    ownerId?: string;
  })[];
  selectedPackageInstanceIndex: number;
  setSelectedPackageInstanceIndex: React.Dispatch<React.SetStateAction<number>>;
  org: OrganizationType;
  clientId: string;
  isCoach?: boolean;
  variant?: "internal" | "client";
  setShowPackageFilter?: (show: boolean) => void;
  showPackageFilter?: boolean;
  enableDarkMode?: boolean;
  parentContact?: ClientType;
}

interface ActionButtonProps {
  className?: string;
  children: ReactNode;
  enableDarkMode?: boolean;
  isPaused?: boolean;
}

const ActionButton: FC<ActionButtonProps> = ({
  children,
  className,
  enableDarkMode = false,
  isPaused = false,
}) => (
  <div
    className={classNames(
      "rounded-lg",
      enableDarkMode && "dark:bg-grey-500/20 dark:text-white",
      isPaused
        ? "bg-foreground/5 text-foreground/80"
        : "bg-green-200/10 text-green-200",
      className
    )}
  >
    {children}
  </div>
);

const PackageInstances: FC<PackageInstancesProps> = ({
  className,
  packageInstances,
  selectedPackageInstanceIndex,
  setSelectedPackageInstanceIndex,
  org,
  clientId,
  isCoach,
  variant,
  setShowPackageFilter,
  showPackageFilter = false,
  enableDarkMode = false,
  parentContact,
}) => {
  const { hasFullAccess } = useAccessType();
  const { id: coachId, slug: coachSlug } = org;
  const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
  const { value: isCompleteModalOpen, toggle: toggleCompleteModal } =
    useToggle();
  const { value: isAddApptsModalOpen, toggle: toggleAddApptsModal } =
    useToggle();
  const { value: isActiveModalOpen, toggle: toggleActiveModal } = useToggle();

  const { packageInstancesAppointmentsMap } = useContext(PackagesContext);

  const { value: isEditStartDateModalOpen, toggle: toggleEditStartDateModal } =
    useToggle();
  const { value: isEditResetDateModalOpen, toggle: toggleEditResetDateModal } =
    useToggle();
  const {
    value: isEditSchadulerRatesModalOpen,
    toggle: toggleEditSchadulerRatesModal,
  } = useToggle();

  const {
    value: isModifyDurationToggleOpen,
    toggle: toggleModifyDurationModal,
  } = useToggle();

  const { value: isPausePackageToggleOpen, toggle: togglePausePackageModal } =
    useToggle();
  const { value: isResumePackageToggleOpen, toggle: toggleResumePackageModal } =
    useToggle();

  const { value: showDeleteModal, toggle: toggleDeleteModal } = useToggle();

  const packageInstance = packageInstances?.length
    ? packageInstances[selectedPackageInstanceIndex]
    : undefined;

  const packageInstanceOwnerId = packageInstance?.clientId || clientId;

  const { packageSchedulers } = usePackageSchedulers(
    clientId,
    packageInstance?.id || "",
    coachId,
    parentContact?.id
  );

  const packageInstanceId = packageInstance?.id || "";

  const isUsagePackage = packageInstance?.packageType === "usage";
  const isRecurring = packageInstance?.packageType === "recurring";
  const isSubscriptionBased = packageInstance?.packageType === "subscription";
  const hasRegularSchedulers = packageInstance?.items?.some(
    (item) => !item.curriculum
  );

  const { data: usagePackageCycleInvoices } = useUsagePackageCycleInvoicesAPI({
    orgId: org.id,
    clientId,
    packageInstanceId: isUsagePackage ? packageInstanceId : "",
  });
  const {
    data: packageInstanceInvoices,
    loading: loadingPackageInstanceInvoices,
  } = usePackageInstanceInvoices(org.id, packageInstanceId);

  const appointments =
    packageInstancesAppointmentsMap?.[packageInstanceId]?.appointments;
  const sortedAppts = orderBy(appointments, "start", "asc");
  const lastAppt = sortedAppts?.[sortedAppts.length - 1];
  const completedDate = getNormalizedDate(
    packageInstance?.endDate ??
      getLastCycle(packageInstance)?.end ??
      lastAppt?.start
  );
  const isPackageCompleted = packageInstance?.status === "completed";

  const packageLink = `/clients/${packageInstanceOwnerId}/packages/${packageInstanceId}`;
  const href = `/me/${coachSlug}${packageLink}`;
  const url = `${getCoachBaseUrl(org)}${packageLink}`;

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const { apiCall: deleteInstance } = useApi(deletePackageInstance);

  const mutatePackages = useMutatePackages({
    clientId: packageInstanceOwnerId,
    parentId: parentContact?.id,
  });

  const onDelete = async () => {
    if (selectedPackageInstanceIndex !== 0) {
      setSelectedPackageInstanceIndex(selectedPackageInstanceIndex - 1);
    }
    await deleteInstance(
      {
        userId: coachId,
        clientId: packageInstanceOwnerId,
        packageInstanceId: packageInstanceId,
      },
      {},
      {}
    );

    mutatePackages();
  };

  const handleToggleAddApptsModal = () => {
    toggleAddApptsModal();
  };

  const isInternal = variant === "internal";
  const [selectedCycle, setSelectedCycle] = useState(
    isPackageCompleted
      ? getLastCycle(packageInstance)
      : getCurrentCycle(packageInstance)
  );

  useEffect(() => {
    if (isPackageCompleted) {
      setSelectedCycle(getLastCycle(packageInstance));
    } else {
      setSelectedCycle(getCurrentCycle(packageInstance));
    }
  }, [packageInstance]);

  if (!packageInstances?.length || !packageInstance) return null;

  const isPaused = isPackageInstancePaused(packageInstance);

  const iconClassNames = classNames(
    isPaused ? "!text-foreground/80" : "!text-green-200",
    enableDarkMode && "dark:!text-white dark:hover:!text-white/50"
  );

  const renderActionButtons = (
    <div
      className={classNames("flex items-center gap-1", !isInternal && "mr-1.5")}
    >
      <ActionButton
        className="h-8 w-8"
        isPaused={isPaused}
        enableDarkMode={enableDarkMode}
      >
        <CopyLinkIconButton
          link={href}
          isHref
          iconClassName={classNames("h-6 w-6", iconClassNames)}
        />
      </ActionButton>
      {isCoach && (
        <ActionButton
          className={classNames("h-8 w-8")}
          enableDarkMode={enableDarkMode}
          isPaused={isPaused}
        >
          <PackageInstancesMoreMenu
            packageUrl={url}
            packageInstance={packageInstance}
            clientId={packageInstanceOwnerId}
            onDelete={toggleDeleteModal}
            onComplete={toggleCompleteModal}
            onActive={toggleActiveModal}
            onModifyDuration={toggleModifyDurationModal}
            onPause={togglePausePackageModal}
            onResume={toggleResumePackageModal}
            iconClassNames={iconClassNames}
            setIsSharingModalOpen={setIsSharingModalOpen}
            toggleAddApptsModal={toggleAddApptsModal}
            toggleEditStartDateModal={toggleEditStartDateModal}
            toggleEditResetDateModal={toggleEditResetDateModal}
            toggleEditSchadulerRatesModal={toggleEditSchadulerRatesModal}
            parentContact={parentContact}
          />
        </ActionButton>
      )}
      {!isInternal && (
        <CollapsedHeader
          className="pt-2"
          collapsed={collapsed}
          toggleCollapsed={setCollapsed}
        />
      )}
    </div>
  );

  const title = (
    <div className="flex items-center justify-between w-full">
      <h1 className="text-md font-medium flex items-center gap-2">
        <PackageIcon />
        {pluralHelper(packageInstances.length, "Package", false)}
      </h1>
    </div>
  );

  const renderHeader = (
    <div>
      {isInternal ? (
        <>
          <PackageInstanceDateTracker
            packageInstance={packageInstance}
            completedDate={completedDate}
          />
        </>
      ) : (
        <CollapsedHeader
          collapsed={collapsed}
          toggleCollapsed={setCollapsed}
          leftElement={title}
          disableHeaderClickable
          className="pt-4"
        />
      )}
      {!collapsed && (
        <div className="flex items-center justify-between mt-6 mb-2">
          <h3 className="font-medium">{packageInstance?.title}</h3>
        </div>
      )}
    </div>
  );

  const renderFooterAction = (
    <div className="self-center cursor-pointer">
      <ActionButton
        className="text-sm font-medium px-2 py-1"
        enableDarkMode={enableDarkMode}
        isPaused={isPaused}
      >
        {isInternal ? (
          <div
            onClick={
              setShowPackageFilter &&
              (() => setShowPackageFilter(!showPackageFilter))
            }
            className="flex gap-1 items-center"
          >
            <span className="hidden lg:block">
              {showPackageFilter ? "Reset" : "Show on "} timeline
            </span>
            {showPackageFilter ? <ViewOffIcon /> : <ViewOnIcon />}
          </div>
        ) : (
          <Link href={href} legacyBehavior>
            <a target="_blank" className="flex items-center gap-1">
              Open package page
              <OpenNewTabIcon />
            </a>
          </Link>
        )}
      </ActionButton>
    </div>
  );

  const wrapperClassNames = classNames(
    "border mt-4",
    isInternal ? "rounded-2xl" : "rounded-lg",
    !isPaused ? "bg-green-900 border-transparent" : "bg-white border-grey-900",
    enableDarkMode && "dark:bg-grey-250"
  );

  const headerClassNames = classNames(
    "w-full p-4 pt-0 flex flex-col gap-4",
    enableDarkMode && "dark:text-white",
    isPaused ? "text-grey-500" : "text-green-200"
  );

  const renderPaymentDetails = () => {
    if (isCoach && !hasFullAccess) return null;

    if (isUsagePackage) {
      return (
        <UsagePackagePaymentDetails
          usagePackageCycleInvoices={usagePackageCycleInvoices}
          enableDarkMode={enableDarkMode}
          isPaused={isPaused}
          clientView={false}
        />
      );
    }

    if (isSubscriptionBased && packageInstance?.tierPriceTitle) {
      return (
        <PackageBillingDetails priceTitle={packageInstance.tierPriceTitle} />
      );
    }

    if (loadingPackageInstanceInvoices) return null;

    return (
      <PackagePaymentDetails
        packageInstance={packageInstance}
        invoices={packageInstanceInvoices ?? []}
        enableDarkMode={enableDarkMode}
        isPaused={isPaused}
      />
    );
  };

  const curriculumids = packageInstance?.items
    ?.filter((item) => item.curriculum)
    .map((item) => item.schedulerId);

  return (
    <div id="package-card-container">
      {collapsed ? (
        <div className={classNames(headerClassNames, wrapperClassNames)}>
          {renderHeader}
        </div>
      ) : (
        <>
          <div className={wrapperClassNames}>
            <PackageInstancesSelector
              selected={packageInstance.id}
              packageInstances={packageInstances}
              onChange={(packageInstanceId) =>
                setSelectedPackageInstanceIndex(
                  packageInstances.findIndex(
                    (packageInstance) =>
                      packageInstance.id === packageInstanceId
                  )
                )
              }
            />
            <div className={classNames(headerClassNames, className)}>
              <div className="space-y-2">
                {selectedCycle && (
                  <div className="flex items-center space-x-2">
                    <div className="flex-1">
                      <PackageInstanceDateTracker
                        packageInstance={packageInstance}
                        completedDate={completedDate}
                        selectedCycle={selectedCycle}
                      />
                    </div>
                    <PackageCyclePagination
                      packageInstance={packageInstance}
                      cycle={selectedCycle}
                      onChangeCycle={setSelectedCycle}
                    />
                  </div>
                )}
                <PackageInstanceCardInfo
                  packageInstance={packageInstance}
                  cycle={selectedCycle}
                />

                <div className="space-y-4">
                  {selectedCycle && (
                    <PackageCycleProgress
                      packageInstance={packageInstance}
                      cycle={selectedCycle}
                    />
                  )}

                  {!isUsagePackage && hasRegularSchedulers && (
                    <>
                      <PackageTrackingStatusInfo
                        packageInstance={packageInstance}
                        isInternal={isInternal}
                        className="mt-4"
                      />
                      <PackageProgress
                        className="w-full"
                        enableDarkMode={enableDarkMode}
                        packageInstance={packageInstance}
                        title={isRecurring ? undefined : "Package progress"}
                      />
                    </>
                  )}
                </div>
              </div>

              {curriculumids?.map((schedulerId) => {
                return (
                  <CurriculumSchedulerDetails
                    key={schedulerId}
                    schedulerId={schedulerId}
                    oid={coachId}
                  />
                );
              })}

              <PackageSchedulerDetails
                orgId={coachId}
                clientId={packageInstanceOwnerId}
                clientParentId={parentContact?.id}
                packageInstance={packageInstance}
                enableDarkMode={enableDarkMode}
              />
              {renderPaymentDetails()}

              <div className="flex justify-between">
                {renderFooterAction}
                {renderActionButtons}
              </div>
            </div>
          </div>
        </>
      )}
      {isSharingModalOpen && (
        <SharingModal
          show
          toggleShow={setIsSharingModalOpen}
          artefactType="package"
          artefactItem={packageInstance}
          body={`Here is the package I want to share with you:\n${url}`}
          artefactLink={url}
          client={{ id: packageInstanceOwnerId }}
          showRecipient
        />
      )}
      {isModifyDurationToggleOpen && (
        <PackageInstanceModifyContent
          toggle={toggleModifyDurationModal}
          packageInstance={packageInstance}
          clientId={clientId}
          clientParentId={parentContact?.id}
        />
      )}
      {isResumePackageToggleOpen && (
        <PackageInstanceResumeContent
          toggle={toggleResumePackageModal}
          packageInstance={packageInstance}
          clientId={clientId}
          clientParentId={parentContact?.id}
          orgId={coachId}
        />
      )}
      {isPausePackageToggleOpen && (
        <PackageInstancePauseContent
          toggle={togglePausePackageModal}
          packageInstance={packageInstance}
          clientId={clientId}
          clientParentId={parentContact?.id}
          orgId={coachId}
        />
      )}
      {isCompleteModalOpen && (
        <PackageInstanceCompleteModal
          packageInstance={packageInstance}
          clientId={clientId}
          clientParentId={parentContact?.id}
          onClose={toggleCompleteModal}
        />
      )}
      {isActiveModalOpen && (
        <PackageInstanceActiveModal
          packageInstance={packageInstance}
          clientId={clientId}
          onClose={toggleActiveModal}
          clientParentId={parentContact?.id}
        />
      )}

      {isAddApptsModalOpen && (
        <AddApptsToPackageInstanceModalWrapper
          clientId={clientId}
          packageInstance={packageInstance}
          setShowModal={handleToggleAddApptsModal}
        />
      )}
      {isEditStartDateModalOpen && (
        <EditPackageInstanceStartDateModal
          toggleEditStartDateModal={toggleEditStartDateModal}
          packageInstance={packageInstance}
          clientId={packageInstanceOwnerId}
          clientParentId={parentContact?.id}
        />
      )}
      {isEditResetDateModalOpen && (
        <EditPackageResetDateModal
          toggleEditResetDateModal={toggleEditResetDateModal}
          packageInstance={packageInstance}
          clientId={packageInstanceOwnerId}
          clientParentId={parentContact?.id}
        />
      )}
      {isEditSchadulerRatesModalOpen && (
        <EditSchedulerRatesModal
          toggle={toggleEditSchadulerRatesModal}
          clientId={packageInstanceOwnerId}
          packageInstance={packageInstance}
          schedulers={packageSchedulers || []}
        />
      )}
      {showDeleteModal && (
        <WarningModal
          visible
          toggle={toggleDeleteModal}
          actionText="Yes, remove"
          onActionClick={onDelete}
          autoClose
          hasOverflow
        >
          <div className="text-center">
            Are you sure you want to remove this package?
          </div>
        </WarningModal>
      )}
    </div>
  );
};

export default PackageInstances;
