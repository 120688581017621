import React, { FC } from "react";

import Tag from "@components/Tag/Tag";

import InfoBoxLabelDetailsPage from "./InfoBoxLabelDetailsPage";

interface InfoBoxDateDetailsPageProps {
  className?: string;
  label: string;
  tag?: string;
}

const InfoBoxDateDetailsPage: FC<InfoBoxDateDetailsPageProps> = ({
  className,
  label,
  tag,
}) => {
  return (
    <InfoBoxLabelDetailsPage>
      <div className="flex items-center">
        <p className={`${className} text-black-ink`}>{label}</p>
        {tag && (
          <Tag className="ml-2" isSelected>
            {tag}
          </Tag>
        )}
      </div>
    </InfoBoxLabelDetailsPage>
  );
};

export default InfoBoxDateDetailsPage;
