import { FC } from "react";
import { CreditNote, CreditNoteReason } from "@practice/sdk";
import classNames from "classnames";
import moment from "moment";

import { formatPrice } from "@lib/utils";

import { SelectableList } from "@components/SelectableList";

type ExtendedCreditNote = CreditNote & {
  id: string;
};

interface CreditNotesListProps {
  currency: string;
  creditNotes: CreditNote[];
}

const styles = {
  amount: "flex-1 max-w-[15%] text-left",
  cnNumber: "flex-1 max-w-[20%] truncate text-left hidden sm:block",
  reason: "flex-1 text-left mx-4",
  dateIssued: "flex-1 text-right",
};

const reasonMap = {
  [CreditNoteReason.Duplicate]: "Duplicate",
  [CreditNoteReason.Fraudulent]: "Fraudulent",
  [CreditNoteReason.OrderChange]: "Order change",
  [CreditNoteReason.ProductUnsatisfactory]: "Product unsatisfactory",
};

const CreditNotesList: FC<CreditNotesListProps> = ({
  currency,
  creditNotes,
}) => {
  const headers = [
    { label: "Amount", className: styles.amount },
    { label: "CN Number", className: styles.cnNumber },
    { label: "Reason", className: styles.reason },
    { label: "Date issued", className: styles.dateIssued },
  ];

  const renderAmountCol = (amountInCents: number) => {
    const formattedPrice = formatPrice(
      amountInCents,
      currency,
      undefined,
      false
    );
    return <div className={styles.amount}>{formattedPrice}</div>;
  };

  const renderCNNumberCol = (cnNumber: string, stripeUrl: string) => {
    return (
      <div className={styles.cnNumber}>
        <a
          href={stripeUrl}
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          {cnNumber}
        </a>
      </div>
    );
  };

  const renderReasonCol = (reason: string) => {
    return (
      <div className={classNames("truncate", styles.reason)}>
        {reasonMap?.[reason] ?? "not specified"}
      </div>
    );
  };

  const renderDateIssuedCol = (dateIssued: Date) => {
    const formattedDate = moment(dateIssued).format("M/D/YYYY h:mm a");
    return <div className={styles.dateIssued}>{formattedDate}</div>;
  };

  return (
    <SelectableList<ExtendedCreditNote>
      items={creditNotes as ExtendedCreditNote[]}
      selectable={false}
      headerClassNames="border-none"
      header={
        <div className="flex-1 flex items-center">
          {headers.map((item, index) => (
            <div
              key={`credit-note-header-${index}`}
              className={classNames("font-medium", item.className)}
            >
              {item.label}
            </div>
          ))}
        </div>
      }
      rowRenderer={(creditNote, index) => (
        <div
          className="flex-1 flex items-center py-1"
          key={`credit-note-item-${index}`}
        >
          {renderAmountCol(creditNote.amountInCents)}
          {renderCNNumberCol(creditNote.cnNumber, creditNote.stripeUrl)}
          {renderReasonCol(creditNote.reason)}
          {renderDateIssuedCol(creditNote.dateIssued)}
        </div>
      )}
    />
  );
};

export default CreditNotesList;
