import { FC } from "react";
import { PackageInstanceCard } from "@practice/sdk";

import { useAuth } from "@contexts/auth";
import { useActivateCompletedPackageInstance } from "@hooks/use-package-instance";

import PlayTriangleOutlineIcon from "@components/Icons/PlayTriangleOutlineIcon";
import SmallModal from "@components/Modals/SmallModal";

interface PackageInstanceActiveModalProps {
  onClose: () => void;
  packageInstance: PackageInstanceCard & {
    ownerId?: string;
  };
  clientId: string;
  clientParentId?: string;
}

const PackageInstanceActiveModal: FC<PackageInstanceActiveModalProps> = ({
  onClose,
  packageInstance,
  clientId,
  clientParentId,
}) => {
  const { oid } = useAuth();
  const packageInstanceOwnerId = packageInstance?.clientId || clientId;
  const packageInstanceId = packageInstance?.id;

  const { active, isActivating } = useActivateCompletedPackageInstance(
    packageInstanceOwnerId,
    packageInstanceId,
    clientParentId
  );

  const handleActivate = async () => {
    if (!oid) return;

    await active();
    onClose();
  };

  return (
    <SmallModal
      show
      disableClose
      isCloseAfterPrimaryActionDisabled
      toggleShow={onClose}
      icon={PlayTriangleOutlineIcon}
      iconColor="green"
      title="Mark as active?"
      description="This package will be marked as active and your client can continue to use it."
      onAction={handleActivate}
      onActionLoading={isActivating}
      onActionText="Mark as active"
      isActionDisabled={isActivating}
    />
  );
};

export default PackageInstanceActiveModal;
