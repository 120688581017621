import { useEffect, useMemo, useState } from "react";
import axios, { AxiosPromise } from "axios";
import {
  collection,
  DocumentData,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { firebaseDataMapper } from "types/utils";

import { AccessType } from "@lib/data/schemas/account";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { compatDB as db } from "@lib/firebase-config";
import { fixAppointment } from "@lib/utils/appointments";

import { useGetCalendarOwnerCallback } from "@components/availabilities/AvailabilityOwnerPreview";

import useLogger from "./use-logger";

export const syncGoogleCal = (
  oid: string | undefined
): AxiosPromise<void> | void => {
  if (!oid) return;

  return axios(`/api/v1/users/${oid}/sync`);
};

interface UseAppointmentsProps {
  oid: string | undefined;
  aid: string | undefined;
  accessType?: AccessType;
  isPast?: boolean;
  onlyEvents?: boolean;
  startDate?: Date;
  limitOverride?: number;
  clientId?: string;
}

export type Appointment = AppointmentType & {
  id: string;
  __refPath: string;
  __parentId: string;
};

const useAppointments = ({
  aid,
  oid,
  accessType,
  isPast,
  onlyEvents,
  startDate,
  limitOverride,
  clientId,
}: UseAppointmentsProps): {
  appointments?: AppointmentType[];
  loading: boolean;
  loadMore: () => void;
  hasMore: boolean;
} => {
  const { logger } = useLogger("useAppointments");
  const path = oid ? `users/${oid}/appointments` : null;
  const LIMIT = limitOverride ?? 20;
  const TODAY = new Date(new Date().setHours(0, 0, 0, 0));

  const getCalendarOwner = useGetCalendarOwnerCallback();

  const [data, setData] = useState<Array<Appointment>>([]);
  const [cursor, setCursor] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!collection) return;
    setData([]);
    setCursor(null);
    load();
  }, [oid, isPast, onlyEvents]);

  const buildQuery = (
    currentCursor?: QueryDocumentSnapshot<DocumentData> | null
  ): QueryConstraint[] => {
    const query: QueryConstraint[] = [
      where("status", "in", [
        "pending",
        "confirmed",
        "maybe",
        "accepted",
        "tentative",
        "shared",
        "needsAction",
      ]),
      where("start", isPast ? "<" : ">=", TODAY),
      orderBy("start", isPast ? "desc" : "asc"),
      limit(LIMIT),
    ];

    if (onlyEvents) query.push(orderBy("eventData"));
    if (currentCursor) query.push(startAfter(currentCursor));
    if (startDate) query.push(where("start", ">=", startDate));
    if (clientId) query.push(where("contactId", "==", clientId));

    return query;
  };

  const load = async (
    isLoadingMore: boolean = false
  ): Promise<Unsubscribe | undefined> => {
    if (!path || (isLoadingMore && !cursor)) return;

    setLoading(true);

    try {
      const queryConstraints = buildQuery(isLoadingMore ? cursor : undefined);

      const q = query(collection(db, path), ...queryConstraints);

      const unsubscribe = onSnapshot(
        q,
        (d) => {
          const docs = d.docs
            .filter((d) => d.data().status !== "deleted")
            .map((d) => firebaseDataMapper(d)) as Appointment[];

          setData(isLoadingMore ? [...data, ...docs] : docs);

          if (d.docs.length > 0 && d.docs.length === LIMIT)
            setCursor(d.docs[d.docs.length - 1]);
          else setCursor(null);
        },
        (e) => {
          logger.error(e, "Error reading firebase data");
          throw e;
        }
      );

      return unsubscribe;
    } catch (err) {
      logger.error(err, "Error reading firebase data");
      return;
    } finally {
      setLoading(false);
    }
  };

  const fixedAppointments = useMemo(() => {
    return data
      ?.map((appointment) => {
        return fixAppointment(appointment, oid, getCalendarOwner);
      })
      .filter((appointment) => {
        return accessType === "full" || appointment.organizer?.id == aid;
      });
  }, [data, oid, getCalendarOwner, accessType, aid]);

  return {
    appointments: fixedAppointments,
    loading,
    loadMore: () => load(true),
    hasMore: !!cursor,
  };
};

export default useAppointments;
