import { FC, useContext, useMemo } from "react";
import classNames from "classnames";
import { compact, groupBy, orderBy, uniqBy } from "lodash";
import moment from "moment";

import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import useClientChatStatus from "@hooks/use-client-chat-status";
import useContact from "@hooks/use-contact";
import useContactSmartActions from "@hooks/use-contact-smart-actions";
import useFormTemplates from "@hooks/use-form-templates";
import useOutcomes from "@hooks/use-outcomes";
import {
  filterIsEvent,
  filterIsGroupAppt,
  filterIsNotEventOrGroup,
  getNormalizedDate,
  mapDeclinedAppointment,
} from "@lib/appointments";
import { displayNameFromContact } from "@lib/contacts";
import { AppointmentType } from "@lib/data/schemas/appointment";
import {
  ArtifactSettingsType,
  ArtifactTypes,
} from "@lib/data/schemas/artifact-settings";
import { ClientType } from "@lib/data/schemas/client";
import { InvoiceType } from "@lib/data/schemas/invoice";
import { SubscriptionType } from "@lib/data/schemas/subscription";
import {
  EmailThread,
  FileType,
  FirebaseDateTimeType,
  FolderType,
  FormType,
  GroupType,
  NoteType,
  SmartActionActionTypes,
  SmartActionDataType,
  TimelineItemType,
} from "@lib/shared-types";
import groupedTimelineItems from "@lib/timeline/grouped-timeline-items";
import { ARTEFACT_NAME_MAP } from "@lib/utils/library";
import { momentDate } from "@lib/utils/todos";

import LoadingSpinner from "@components/LoadingSpinner";
import { SelectableList } from "@components/SelectableList";

import { PackagesContext } from "../../pages/contacts/[contactId]";

import TimelineEmpty from "./TimelineEmpty";
import {
  mapTypeToHeading,
  TimelineItem,
  TimelineItemVariantTypes,
} from "./TimelineItem";
import {
  getPackageInstanceBreaks,
  getPackageTrackingHistory,
  mapTypeToDateField,
} from "./utils";

interface TimelineViewProps {
  group?: GroupType;
  contactId?: string;
  clientParentId?: string;
  onClickPlanning?: () => void;
  appointments?: AppointmentType[];
  payments?: InvoiceType[];
  forms?: FormType[];
  legal?: FormType[];
  files?: FileType[];
  notes?: NoteType[];
  links?: FileType[];
  subscriptions?: SubscriptionType[];
  folders?: FolderType[];
  emailThreads?: EmailThread[];
  loading?: boolean;
  sortBy?: string;
  filterPackage?: string;
  artifactSettings?: ArtifactSettingsType;
  onChangeHidden?: (id: string, artifact: ArtifactTypes) => void;
  selectedTab?: "upcoming" | "past";
}

export const UPCOMING_ITEMS = [
  "all",
  "appointments",
  "events",
  "forms",
  "payments",
];

export const TimelineView: FC<TimelineViewProps> = ({
  group,
  contactId,
  clientParentId,
  onClickPlanning,
  appointments = [],
  payments = [],
  forms = [],
  legal = [],
  files = [],
  notes = [],
  links = [],
  folders = [],
  emailThreads = [],
  subscriptions = [],
  loading = false,
  sortBy = "all",
  filterPackage,
  artifactSettings,
  onChangeHidden,
  selectedTab = "upcoming",
}) => {
  const { uid } = useAuth();
  const { hasFullAccess } = useAccessType();
  const canHaveUpcomingItems = UPCOMING_ITEMS.includes(sortBy);
  const isUpcomingTabSelected = selectedTab === "upcoming";
  const isPastTabSelected = selectedTab === "past";
  const { contact, loading: loadingContacts } = useContact(contactId);
  const { joinDate } = useClientChatStatus(contact);
  const { data: allTemplates, loading: loadingFormTemplates } =
    useFormTemplates(uid);
  const { smartActions: allSmartActions, loading: loadingSmartActions } =
    useContactSmartActions({
      coachId: uid,
      clientId: contactId,
    });

  const smartActions = allSmartActions
    ?.filter((sa) => sa.status === "pending")
    .filter((sa) => sa.when);

  const { outcomes } = useOutcomes();
  const { packageInstances: packages } = useContext(PackagesContext);
  const packageInstances = compact(
    packages?.map((p) => {
      if (p.startDate && momentDate(p.startDate).isAfter(moment()))
        return undefined;
      return {
        ...p,
        clientName: displayNameFromContact(contact, true),
        packageInstanceId: p.id,
      };
    })
  );

  const isLoading =
    loading || loadingContacts || loadingSmartActions || loadingFormTemplates;

  const isAppointmentBased = (item: TimelineItemType) =>
    ["appointments", "events", "groupAppointments"].includes(item.__type);
  const isSortByAppointmentBased = isAppointmentBased({ __type: sortBy });
  const isSortByAll = sortBy == "all";
  const isSortByAppointmentOrAll = isSortByAppointmentBased || isSortByAll;

  const filterByActionType =
    (type: SmartActionActionTypes) => (item: SmartActionDataType) =>
      item.action.type === type;
  const scheduledDate = (
    date: FirebaseDateTimeType,
    convertToUtc: boolean = false
  ) => {
    let momentDate = moment(date.toDate());
    if (convertToUtc) {
      momentDate = momentDate.utc();
    }
    return momentDate.format("h:mm A");
  };
  const getCommomScheduledData = (item: SmartActionDataType) => ({
    status: "scheduled",
    start: item.when,
    createdAt: item.when,
    feed: [
      {
        type: ARTEFACT_NAME_MAP[item.action.artifactType],
        actionId: `scheduled for ${scheduledDate(item.when)}`,
      },
    ],
    __type:
      item?.action?.artifactType === "invoices"
        ? "payments"
        : item?.action?.artifactType,
  });

  const scheduledForms = smartActions
    .filter(filterByActionType("send-form"))
    .map((item) => {
      const template = allTemplates?.find(
        (t) => t.id === item.action.resourceId
      );
      return {
        ...template,
        ...getCommomScheduledData(item),
        linkUrl: `/form-templates/${template?.id}`,
      };
    });

  const pastSubscriptions = subscriptions
    .filter((sub) => sub.status !== "scheduled")
    .map((sub) => ({
      ...sub,
      createdAt: sub.startDate,
      __type: "subscriptions",
      feed: [
        {
          type: "subscription",
          actionId: sub.status === "canceled" ? "cancelled" : "started",
        },
      ],
    }));

  const scheduledSubscriptions = subscriptions
    .filter((sub) => sub.status === "scheduled")
    .map((sub) => ({
      ...sub,
      createdAt: sub.startDate,
      start: sub.startDate,
      __type: "subscriptions",
      feed: [
        {
          type: "subscription",
          actionId: "scheduled",
        },
      ],
    }));

  const scheduledPayments = smartActions
    .filter(filterByActionType("send-invoice"))
    .map((item) => {
      const payment = payments.find((t) => t.id === item.action.resourceId);
      if (!payment) return;
      return {
        ...payment,
        ...getCommomScheduledData(item),
        linkUrl: `/invoices/${payment?.id}`,
      };
    })
    .filter(Boolean);

  const scheduledUsageBasedInvoices = payments
    .filter(
      (payment) =>
        payment.status === "scheduled" && payment?.kind === "usage-based"
    )
    ?.map((payment) => ({
      ...payment,
      type: "payments",
      feed: [
        {
          type: ARTEFACT_NAME_MAP["invoices"],
          actionId: `scheduled for ${scheduledDate(payment.dueDate, true)}`,
        },
      ],
      __type: "payments",
    }));

  const scheduledPackagePayments = compact(
    smartActions
      ?.filter(filterByActionType("bill-scheduled-invoice"))
      ?.map((item) => {
        if (filterPackage && item.action.resourceId !== filterPackage)
          return undefined;
        const payment = payments.find((t) => t.id === item.event.resourceId);
        if (!payment?.id) return undefined;
        return {
          ...payment,
          ...getCommomScheduledData(item),
          __type: "payments",
          feed: [
            {
              type: "invoice",
              actionId: `scheduled for ${scheduledDate(item.when)}`,
            },
          ],
          linkUrl: `/invoices/${payment?.id}`,
        };
      })
  );

  const upcomingPayments = uniqBy(
    [
      ...(hasFullAccess ? scheduledPayments : []),
      ...(hasFullAccess ? scheduledUsageBasedInvoices : []),
      ...(hasFullAccess ? scheduledPackagePayments : []),
    ],
    "id"
  );

  const packageInstanceRenewals = compact(
    allSmartActions
      ?.filter(filterByActionType("recurring-package-instance"))
      .map((item) => {
        if (item.status !== "completed") return undefined;
        const packageInstance = packageInstances?.find(
          (p) => p.id === item.action.resourceId
        );
        const whenDate = getNormalizedDate(item.when);
        if (!packageInstance || whenDate > new Date()) return undefined;
        return {
          title: packageInstance.title,
          createdAt: item.when,
          clientName: displayNameFromContact(contact, true),
          __type: "packageInstanceRenewal",
          packageInstanceId: packageInstance.id,
          feed: [
            {
              type: "packageInstanceRenewal",
              actionId: "renewed",
            },
          ],
        };
      })
  );
  const packageInstanceBreaks = (packageInstances || [])
    .map((packageInstance) =>
      getPackageInstanceBreaks(packageInstance, contact as ClientType)
    )
    .flat();

  const {
    packageInstancePause = [],
    packageInstanceResume = [],
    packageInstanceCompleted = [],
  } = groupBy(packageInstanceBreaks, (item) => item.__type);

  const packageTrackingHistory = (packageInstances || [])
    .map((packageInstance) => getPackageTrackingHistory(packageInstance))
    .flat();

  const scheduledSubscriptionPayments = payments
    .filter(
      (item) =>
        item.status === "scheduled" &&
        item.subscriptionId &&
        !item.scheduledSmartActions
    )
    .map((item) => ({
      ...item,
      createdAt: item.sendAt,
      __type: "payments",
      feed: [
        {
          type: "payments",
          actionId: "scheduled",
        },
      ],
    }));

  const scheduledFiles = compact(
    smartActions.filter(filterByActionType("send-library-item")).map((item) => {
      return {
        ...getCommomScheduledData(item),
        resourceId: item.action.resourceId,
      };
    })
  );

  const orderByDate = (itemsArray: any[]) =>
    orderBy(
      itemsArray,
      (item) => {
        const time = mapTypeToDateField(item, item);

        if (time?.seconds) {
          return new Date(time.seconds * 1000);
        }
        return time;
      },
      "desc"
    );

  const timelineData = useMemo(() => {
    let mergedWithType = [
      {
        appointments:
          appointments
            ?.map(mapDeclinedAppointment)
            ?.filter(filterIsNotEventOrGroup) ?? [],
      },
      { groupAppointments: appointments?.filter(filterIsGroupAppt) },
      { events: appointments?.filter(filterIsEvent) },
      {
        payments:
          payments
            ?.filter((payment) => payment?.status !== "scheduled")
            ?.filter((payment) => {
              const upcomingInvoiceIds = upcomingPayments.map(
                (p) => p?.id ?? ""
              );
              return !upcomingInvoiceIds.includes(payment.id);
            }) ?? [],
      },
      { forms: [...(forms ?? []), ...(legal ?? [])] },
      { files: files ?? [] },
      { folders: folders ?? [] },
      { notes: notes ?? [] },
      { links: links ?? [] },
      { emailThreads: emailThreads ?? [] },
      { memberAdded: group?.members ?? [] },
      { clientJoined: joinDate ? [joinDate] : [] },
      { subscriptions: pastSubscriptions ?? [] },
      { packageInstances: packageInstances ?? [] },
      { packageInstanceRenewal: packageInstanceRenewals ?? [] },
      { packageInstancePause },
      { packageInstanceResume },
      { packageInstanceCompleted },
      { packageTrackingHistory },
    ];

    // If contact is archived and there's an archived date field, add archived
    // contact to the types array
    if (contact?.status === "archived" && contact.archivedAt) {
      mergedWithType.push({ archivedContact: [contact] });
    }

    mergedWithType = mergedWithType
      .map((array) =>
        Object.values(array)[0].map((value) => ({
          ...value,
          __type: Object.keys(array)[0],
        }))
      )
      .flat();

    return orderByDate(mergedWithType);
  }, [
    appointments,
    files,
    folders,
    forms,
    notes,
    payments,
    links,
    contact,
    legal,
    emailThreads,
    group,
    joinDate,
    subscriptions,
    packageInstances,
    filterPackage,
  ]);

  const timelineWithFeedData = timelineData.map((item) => ({
    ...item,
    // @TODO: this feed condition was made in this way to be able to remove
    //        the dependency of the feed collection.
    feed: item.feed
      ? item.feed
      : ["private", "scheduled"].includes(item?.status)
      ? []
      : [{ type: mapTypeToHeading(item?.__type), actionId: item?.status }],
  }));

  const filterByPackage = (items: TimelineItemType[]) => {
    if (!filterPackage) return items;
    return items.filter((item) => {
      if (item.__type === "payments")
        return item.invoice?.metadata?.packageInstanceId === filterPackage;
      return item.packageInstanceId === filterPackage;
    });
  };

  const adjustGroupApptType = (itemType: TimelineItemType["__type"]) =>
    itemType === "groupAppointments" ? "appointments" : itemType;

  const timelineSortBy =
    sortBy === "all"
      ? timelineWithFeedData
      : timelineWithFeedData.filter(
          (item) => adjustGroupApptType(item.__type) === sortBy
        );

  const filteredByPackage = filterPackage
    ? filterByPackage(timelineSortBy)
    : timelineSortBy;

  const now = moment();
  const groupData = filteredByPackage.reduce(
    (agg, item) => {
      if (isAppointmentBased(item)) {
        const outcome = outcomes?.find(
          (o) => o.id === item?.outcome?.outcomeId
        );
        const isCancelledRecurringAppointment =
          item.gcal?.event?.recurringEventId && item.status === "cancelled";
        const hasOutcome = !!outcome;
        const isLinkedToPackage = !!item.packageInstanceId;
        if (
          !isCancelledRecurringAppointment ||
          !!filterPackage ||
          (isCancelledRecurringAppointment && (hasOutcome || isLinkedToPackage))
        ) {
          const start = moment(item.start);
          const diff = now.diff(start, "days", true);

          if (diff < 0) {
            agg[0].push(item);
          } else {
            agg[1].push(item);
          }
        }
      } else {
        agg[1].push(item);
      }

      return agg;
    },
    [[], []]
  );

  const renderTimelineItems = (
    items,
    variant: TimelineItemVariantTypes = "default"
  ) => {
    const isVariantUpcoming = variant === "upcoming";
    const isEmptyUpcoming = isVariantUpcoming && !items.length;
    const itemsWithMembers = groupedTimelineItems(items, orderByDate);
    const emptyText =
      sortBy === "all"
        ? "All scheduled items will be displayed right here"
        : `Scheduled ${sortBy} will be
              displayed right here.`;
    return (
      <div className={classNames(isVariantUpcoming && "mb-4")}>
        {isEmptyUpcoming && isUpcomingTabSelected && (
          <TimelineItem
            item={{ __type: "custom" }}
            variant="upcoming"
            isPast={!isVariantUpcoming}
            orgId={uid}
          >
            <p className="font-medium text-sm text-black-ink">
              All quiet here.
            </p>
            <p className="font-normal text-sm text-grey-500">{emptyText}</p>
          </TimelineItem>
        )}

        <SelectableList<TimelineItemType & { id: string }>
          listItemClassNames="!block"
          rowRenderer={(item, index) => {
            return (
              <TimelineItem
                key={`timeline-item-${index}-${item.id}`}
                artifactSettings={artifactSettings}
                item={item}
                orgId={uid}
                prev={itemsWithMembers[index - 1]}
                next={itemsWithMembers[index + 1]}
                contactId={contactId}
                clientParentId={clientParentId}
                variant={variant}
                isHidden={
                  !!item?.hidden ||
                  !!artifactSettings?.[
                    item.__type as ArtifactTypes
                  ]?.hidden?.includes(item.id)
                }
                onChangeHidden={onChangeHidden}
                isCoach={true}
              />
            );
          }}
          loadingPlaceholder={
            <div className="flex items-center justify-center my-4">
              <LoadingSpinner />
            </div>
          }
          items={itemsWithMembers}
          selectable={false}
        />
      </div>
    );
  };

  // If loading display loading spinner. Display zero state encouragement
  // if there are less than 2 items and the contact isn't archived
  // @TODO: check the `timelineData` because we grouped the artifacts in two

  const renderEmpty = () => {
    if (isLoading) return <LoadingSpinner className="mx-auto mt-16" />;
    const shouldShowEmpty =
      onClickPlanning &&
      timelineData.length < 2 &&
      contact &&
      contact.status !== "deleted";
    if (!shouldShowEmpty) return null;
    return <TimelineEmpty onClickPlanning={onClickPlanning} client={contact} />;
  };

  // Show contact info when not loading so that there isn't a jump once
  // timeline data has loaded
  const renderContact = !isLoading && (
    <TimelineItem orgId={uid!} item={{ ...contact, __type: "contact" }} />
  );

  const upcomingItems = canHaveUpcomingItems
    ? orderBy(
        compact([
          ...groupData[0],
          ...scheduledForms,
          ...scheduledFiles,
          ...upcomingPayments,
          ...scheduledSubscriptions,
          ...scheduledSubscriptionPayments,
        ]).filter((item) =>
          isSortByAll ? true : adjustGroupApptType(item.__type) === sortBy
        ),
        [isSortByAppointmentOrAll ? "start" : "createdAt"],
        ["asc"]
      )
    : [];

  const filteredByPackageUpcoming = filterPackage
    ? filterByPackage(upcomingItems)
    : upcomingItems;

  const renderUpcoming = renderTimelineItems(
    filteredByPackageUpcoming,
    "upcoming"
  );

  const hasUpcomingItems = upcomingItems.length > 0;

  return (
    <main className="my-4">
      {!group && renderEmpty()}
      {!hasUpcomingItems ? (
        <div className="">{renderUpcoming}</div>
      ) : (
        isUpcomingTabSelected && (
          <div
            className={classNames(
              "bg-action-950 border-black-ink border-opacity-7 border rounded-xl p-4 mb-8 group/toggle relative",
              "pb-6"
            )}
          >
            {renderUpcoming}
          </div>
        )
      )}
      {isPastTabSelected && (
        <div className={classNames(hasUpcomingItems && "px-4 pb-4")}>
          {renderTimelineItems(groupData[1], "default")}

          {group ? (
            <TimelineItem orgId={uid} item={{ ...group, __type: "group" }} />
          ) : (
            renderContact
          )}
        </div>
      )}
    </main>
  );
};
