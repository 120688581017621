import { FC } from "react";
import { PackageInstanceApi, PackageInstanceCard } from "@practice/sdk";
import moment from "moment";

import { useAuth } from "@contexts/auth";
import { useMutatePackages } from "@hooks/use-client-organization-data";
import {
  useCompletePackageInstanceUsageBased,
  usePackageInstancePreviewEndOfService,
} from "@hooks/use-package-instance";
import { useSDKApiExecute } from "@hooks/use-sdk-api";
import useSnackbar from "@hooks/use-snackbar";

import SmallBanner from "@components/Banner/SmallBanner";
import ActivityPathIcon from "@components/Icons/ActivityPathIcon";
import PermissionFullIcon from "@components/Icons/PermissionFullIcon";
import SmallModal from "@components/Modals/SmallModal";

interface PackageInstanceCompleteModalProps {
  onClose: () => void;
  packageInstance: PackageInstanceCard & {
    ownerId?: string;
  };
  clientId: string;
  clientParentId?: string;
}

const PackageInstanceCompleteModal: FC<PackageInstanceCompleteModalProps> = ({
  onClose,
  packageInstance,
  clientId,
  clientParentId,
}) => {
  const { oid } = useAuth();

  const packageInstanceId = packageInstance?.id;
  const isUsageBased = packageInstance?.packageType === "usage";
  const snackbar = useSnackbar();

  const { completeUBP, isCompleting: isUBPCompleting } =
    useCompletePackageInstanceUsageBased(clientId, packageInstanceId);

  const { execute: completePackageInstance, loading: isPackageCompleting } =
    useSDKApiExecute(PackageInstanceApi, {
      method: "completePackageInstance",
      errorLogMessage: "Failed to complete package",
      keyOrigin: "complete-package-instance-modal",
      showErrorSnackbar: true,
      onSuccess: () => {
        snackbar.showMessage("Package marked as complete");
      },
    });
  const { data: previewData, isLoading: isLoadingPreviewData } =
    usePackageInstancePreviewEndOfService(
      clientId,
      isUsageBased ? packageInstanceId : undefined
    );
  const mutatePackages = useMutatePackages({
    clientId,
    parentId: clientParentId,
  });

  const getDescription = () => {
    if (isUsageBased) {
      if (isLoadingPreviewData) return "Calculating the final invoice date...";
      return (
        <>
          Your client can continue to make bookings until the end of the billing
          cycle, which concludes on{" "}
          <strong className="font-medium">
            {moment(previewData?.endDate).format("MMMM Do, YYYY")}
          </strong>
          .
        </>
      );
    }

    const suffix =
      packageInstance?.packageType === "recurring"
        ? ", and no additional cycles will be added."
        : ".";
    return `Unused sessions will be marked as completed${suffix}`;
  };

  const handleComplete = async () => {
    if (!oid) return;

    if (isUsageBased) {
      await completeUBP();
    } else {
      await completePackageInstance({
        organizationId: oid,
        packageInstanceId,
      });
    }
    await mutatePackages();
    onClose();
  };

  const renderUBPBanner = isUsageBased && !isLoadingPreviewData && (
    <SmallBanner
      variant="info"
      items={[
        {
          Icon: ActivityPathIcon,
          text: "The final invoice for this package may reflect a refund depending on the package usage.",
        },
      ]}
    />
  );

  return (
    <SmallModal
      show
      disableClose
      isCloseAfterPrimaryActionDisabled
      toggleShow={onClose}
      icon={PermissionFullIcon}
      iconColor="green"
      title="Mark as complete?"
      description={getDescription()}
      onAction={handleComplete}
      onActionLoading={isPackageCompleting || isUBPCompleting}
      onActionText="Mark as complete"
      isActionDisabled={isUsageBased ? isLoadingPreviewData : false}
    >
      {renderUBPBanner}
    </SmallModal>
  );
};

export default PackageInstanceCompleteModal;
