import { z } from "zod";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";

export const ArtifactSchema = z.enum([
  "emailThreads",
  "payments",
  "subscriptions",
  "files",
  "links",
  "folders",
  "notes",
  "forms",
]);
export type ArtifactTypes = z.infer<typeof ArtifactSchema>;

const CommonArtifactSettings = z.object({
  hidden: z.string().array().optional(),
});

export const ArtifactSettingsSchema = BaseSchema.extend({
  id: z.string(),
  emailThreads: CommonArtifactSettings.optional(),
  payments: CommonArtifactSettings.optional(),
  subscriptions: CommonArtifactSettings.optional(),
  files: CommonArtifactSettings.optional(),
  links: CommonArtifactSettings.optional(),
  folders: CommonArtifactSettings.optional(),
  notes: CommonArtifactSettings.optional(),
  forms: CommonArtifactSettings.optional(),
});

export type ArtifactSettingsType = z.infer<typeof ArtifactSettingsSchema>;

export const schemaProperties: SchemaProperties = {
  collectionName: "settings",
  deleteMode: "none",
};
