import { FC } from "react";
import { ExtendedAccount, RefundReason } from "@practice/sdk";
import moment from "moment";

import { displayNameFromContact } from "@lib/contacts";
import { formatPrice } from "@lib/utils";

import SmallBanner from "@components/Banner/SmallBanner";
import InfoIcon from "@components/Icons/InfoIcon";

type RefundReasonType = `${RefundReason}`;

export interface RefundInfoType {
  details?: string;
  refundedAt: Date;
  refundedBy?: string;
  reason?: RefundReasonType | null;
  amountInCents: number;
}

interface RefundsInfoProps {
  currency: string;
  members: ExtendedAccount[];
  refunds: RefundInfoType[];
}

const reasonMap: Record<RefundReasonType, string> = {
  requested_by_customer: "customer request",
  duplicate: "duplicate payment",
  fraudulent: "fraudulent payment",
};

const RefundsInfo: FC<RefundsInfoProps> = ({ currency, refunds, members }) => {
  return (
    <SmallBanner
      variant="regular"
      items={refunds.map((refund) => {
        const member = members.find((m) => m.id === refund.refundedBy);
        const memberName = displayNameFromContact(member) ?? refund.refundedBy;
        const formattedAmount = formatPrice(
          refund.amountInCents,
          currency,
          undefined,
          false
        );
        const formattedRefundedAt = moment(refund.refundedAt).format(
          "MMMM DD, YYYY"
        );
        return {
          Icon: InfoIcon,
          text: (
            <>
              <span>
                {`This payment was ${formattedAmount} refunded on
              ${formattedRefundedAt} due to`}
              </span>{" "}
              <strong>
                {reasonMap[refund.reason ?? "requested_by_customer"]}
              </strong>
              .
              {refund?.details && (
                <>
                  <br />
                  <strong>{`${memberName} wrote:`}</strong>{" "}
                  <span>{refund.details}</span>
                </>
              )}
            </>
          ),
        };
      })}
    />
  );
};

export default RefundsInfo;
