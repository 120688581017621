import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const CalendarArrowOutIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.99976H8V3.99976H16V1.99976H18V3.99976H21V8.49976C21 8.7759 20.7761 8.99976 20.5 8.99976H19.5C19.2239 8.99976 19 8.7759 19 8.49976V5.99976H5V19.9998H7.5C7.77614 19.9998 8 20.2236 8 20.4998V21.4998C8 21.7759 7.77614 21.9998 7.5 21.9998H5C3.89543 21.9998 3 21.1043 3 19.9998V3.99976H6V1.99976ZM21.0536 16.3889C21.2488 16.5841 21.2488 16.9007 21.0536 17.096L16.8109 21.3386C16.6157 21.5339 16.2991 21.5339 16.1038 21.3386L15.3967 20.6315C15.2014 20.4362 15.2014 20.1196 15.3967 19.9244L17.5787 17.7424L9.5 17.7495C9.22386 17.7495 9 17.5185 9 17.2424V16.2424C9 15.9663 9.21676 15.7451 9.4929 15.7451L17.5787 15.7424L15.3967 13.5604C15.2015 13.3652 15.2015 13.0486 15.3967 12.8533L16.1038 12.1462C16.2991 11.9509 16.6157 11.9509 16.8109 12.1462L21.0536 16.3889Z"
      />
    </svg>
  );
};

export default CalendarArrowOutIcon;
