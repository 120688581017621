import Link from "next/link";

import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";

import CircleIcon from "./Icons/CircleIcon";

const OrganizationMemberSidebarInformation: React.FC<{
  client: ClientType;
  parent: ClientType;
}> = ({ client, parent }) => {
  const type = parent.clientType;

  return (
    <div className="w-full bg-blue-950 rounded-xl p-4 mt-4 flex flex-col">
      <div className="flex items-center text-blue-300">
        <CircleIcon />
        <div className="ml-2">Part of {type}</div>
      </div>
      <div className="text-sm text-blue-300 mt-2 mb-4 ">
        {displayNameFromContact(client, true)} is linked to a {type} record.
      </div>

      <Link
        href={`/client-organizations/${parent.id}`}
        className="w-full bg-blue-900 text-blue-300 hover:bg-blue-700 py-3 rounded-lg text-sm font-medium text-center"
      >
        View {type} record
      </Link>
    </div>
  );
};

export default OrganizationMemberSidebarInformation;
