import {
  OnTrackHistory,
  OnTrackHistoryActionEnum,
  PackageInstanceCard,
} from "@practice/sdk";
import { addMinutes } from "date-fns";
import { compact, orderBy } from "lodash";

import { getNormalizedDate } from "@lib/appointments";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";
import { TimelineItemType } from "@lib/shared-types";

const isAppointmentBased = (item: TimelineItemType) =>
  ["appointments", "events", "groupAppointments"].includes(item.__type);

export const isOfPackageType = (item: TimelineItemType) =>
  [
    "packageInstances",
    "packageInstanceRenewal",
    "packageInstancePause",
    "packageInstanceResume",
    "packageInstanceCompleted",
    "packageTrackingHistory",
  ].includes(item.__type);

const isUsageBasedInvoice = (item: TimelineItemType) =>
  item.__type === "payments" &&
  item.status === "scheduled" &&
  (item as any).kind === "usage-based";

export const mapTypeToDateField = (
  item: TimelineItemType,
  contactId: string
) => {
  if (isAppointmentBased(item)) return item.start;
  if (
    contactId &&
    item.sharedWithData &&
    item.sharedWithData[contactId]?.sharedWithDate
  )
    return item.sharedWithData[contactId].sharedWithDate;

  const itemType = item.__type;

  if (itemType === "packageInstances") return item.startDate ?? item.createdAt;
  if (itemType === "archivedContact") return item.archivedAt;
  if (itemType === "emailThreads") return item.date;
  if (["groupedMembers", "memberAdded"].includes(itemType)) return item.addedAt;
  if (itemType === "forms") return item?.submittedAt || item?.createdAt;

  return item.createdAt;
};

export const getItemDate = (item: TimelineItemType, contactId: string) => {
  const dateVal = mapTypeToDateField(item, contactId);
  if (isAppointmentBased(item) && item.allDayEvent) {
    return addMinutes(dateVal, new Date().getTimezoneOffset());
  }
  if (isOfPackageType(item)) {
    return addMinutes(
      getNormalizedDate(item.startDate || item.createdAt),
      new Date().getTimezoneOffset()
    );
  }
  if (isUsageBasedInvoice(item)) {
    const seconds = Object.values(dateVal)[0];
    const secondsToDate = new Date(Number(seconds) * 1000);
    const adjustedDate = addMinutes(
      secondsToDate,
      new Date().getTimezoneOffset()
    );
    return adjustedDate;
  }
  if (dateVal instanceof Date) return dateVal || Date();

  // in case dateVal is null or undefined, return current date to prevent
  // timeline to crash
  if (!dateVal) return new Date();

  const seconds = Object.values(dateVal)[0];
  return new Date(Number(seconds) * 1000);
};

/**
 * Gets the package instance breaks in the timeline format
 * */
export const getPackageInstanceBreaks = (
  packageInstance: PackageInstanceCard,
  client: ClientType
) => {
  const cycles = packageInstance?.cycles ?? [];

  if (cycles.length === 0) return [];

  const pausedCycles = cycles.filter((cycle) => cycle?.pausedOn);
  const resumedCycles = cycles.filter((cycle) => cycle?.resumedOn);
  const orderedCycles = orderBy(cycles, ["start"], ["desc"]);
  const lastCycle = orderedCycles?.at(0);

  const isCompleted = packageInstance.status === "completed";
  const completedDate = packageInstance?.endDate ?? lastCycle?.end ?? null;

  const formattedPausedBreaks = pausedCycles.map((cycle) => ({
    title: packageInstance.title,
    createdAt: cycle.pausedOn!,
    clientName: displayNameFromContact(client, true),
    __type: "packageInstancePause",
    packageInstanceId: packageInstance.id,
    feed: [
      {
        type: "packageInstancePause",
        actionId: "pause",
      },
    ],
  }));

  const formattedResumedBreaks = resumedCycles.map((cycle) => ({
    title: packageInstance.title,
    createdAt: cycle.resumedOn!,
    clientName: displayNameFromContact(client, true),
    __type: "packageInstanceResume",
    packageInstanceId: packageInstance.id,
    feed: [
      {
        type: "packageInstanceResume",
        actionId: "resume",
      },
    ],
  }));

  const formattedCompletedBreak = isCompleted &&
    completedDate && {
      title: packageInstance.title,
      createdAt: completedDate,
      clientName: displayNameFromContact(client, true),
      __type: "packageInstanceCompleted",
      packageInstanceId: packageInstance.id,
      feed: [
        {
          type: "packageInstanceCompleted",
          actionId: "completed",
        },
      ],
    };

  return compact([
    ...formattedPausedBreaks,
    ...formattedResumedBreaks,
    formattedCompletedBreak,
  ]);
};

/**
 * Gets package instance tracking changes in the timeline format
 * */
export const getPackageTrackingHistory = (
  packageInstance: PackageInstanceCard
) => {
  const onTrackHistory = packageInstance?.onTrackHistory ?? [];

  if (onTrackHistory.length === 0) return [];

  const formattedOnTrackHistory = onTrackHistory.map(
    (historyItem: OnTrackHistory) => {
      if (historyItem.action !== OnTrackHistoryActionEnum.UpdatedStatus) return;
      return {
        title: packageInstance.title,
        createdAt: historyItem.date,
        __type: "packageTrackingHistory",
        packageInstanceId: packageInstance.id,
        trackingStatus: historyItem.status,
      };
    }
  );

  return compact(formattedOnTrackHistory);
};
