import { FC, useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PackageInstanceCard } from "@practice/sdk";
import {
  ChosenAppointmentType,
  postAddAppointmentsToPackage,
} from "api-services/definitions/package-instances";
import { useApi } from "api-services/endpoints";
import { PackagesContext } from "pages/contacts/[contactId]";

import { useAuth } from "@contexts/auth";
import { useContact } from "@hooks/data/clients";
import { useMutatePackages } from "@hooks/use-client-organization-data";

import { SelectableListContextProvider } from "@components/SelectableList";

import AddApptsToPackageInstanceModal from "../AddApptsToPackageInstanceModal";

export interface AddApptsToPackageInstanceModalWrapperProps {
  clientId: string;
  packageInstance: PackageInstanceCard & {
    ownerId?: string;
  };
  setShowModal: (showModal: boolean) => void;
}

const AddApptsToPackageInstanceModalWrapper: FC<
  AddApptsToPackageInstanceModalWrapperProps
> = ({ clientId, packageInstance, setShowModal }): JSX.Element => {
  const { oid } = useAuth();
  const { parentContact } = useContact(clientId);

  const { apiCall: addAppointmentsToPackage } = useApi(
    postAddAppointmentsToPackage
  );

  const methods = useForm({
    defaultValues: {
      packageId: packageInstance?.packageId,
      startDate: packageInstance?.startDate,
    },
  });

  const packageInstanceOwnerId = packageInstance?.clientId || clientId;

  const { packageInstancesAppointmentsMap } = useContext(PackagesContext);

  const existingPackageAppts = packageInstance
    ? packageInstancesAppointmentsMap?.[packageInstance?.id]?.appointments
    : [];

  const mutatePackages = useMutatePackages({
    clientId: packageInstanceOwnerId,
    parentId: parentContact?.id,
  });

  const handleSubmitAddAppointments = async (
    chosenAppointments: ChosenAppointmentType[]
  ) => {
    if (!oid) return;

    await addAppointmentsToPackage(
      {
        userId: oid,
        clientId: packageInstanceOwnerId,
        packageInstanceId: packageInstance.id,
      },
      {
        chosenAppointments,
      },
      {}
    );
    mutatePackages();
  };

  return (
    <FormProvider {...methods}>
      <SelectableListContextProvider>
        <AddApptsToPackageInstanceModal
          handleSubmit={handleSubmitAddAppointments}
          show
          clientId={clientId}
          setShow={setShowModal}
          packageData={packageInstance}
          startDate={packageInstance?.startDate}
          existingPackageAppts={existingPackageAppts}
          clientParentId={parentContact?.id}
          showCyclePreview
        />
      </SelectableListContextProvider>
    </FormProvider>
  );
};

export default AddApptsToPackageInstanceModalWrapper;
