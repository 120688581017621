import { FC } from "react";

interface PackageBillingDetailsProps {
  priceTitle: string;
}

const PackageBillingDetails: FC<PackageBillingDetailsProps> = ({
  priceTitle,
}) => {
  return (
    <div className="flex items-center">
      <div className="flex-1">
        <p className="text-sm font-medium">Billing</p>
        <p className="text-xs text-green-500">Paid in full</p>
      </div>
      <p className="rounded-full px-2 flex items-center text-sm font-medium bg-green-200/10 text-green-200">
        {priceTitle}
      </p>
    </div>
  );
};

export default PackageBillingDetails;
