import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { useRouter } from "next/router";

import { Button } from "@components/Button";

import { UPCOMING_ITEMS } from "./TimelineView";

export type TimelineTabTypes = "upcoming" | "past";

interface TimelineHeaderProps {
  children?: React.ReactNode;
  sortBy: string;
}

const TimelineHeader: FC<TimelineHeaderProps> = ({ sortBy, children }) => {
  const router = useRouter();
  const { query } = router;
  const canHaveUpcomingItems = UPCOMING_ITEMS.includes(sortBy);
  const [selectedTab, setSelectedTab] = useState<TimelineTabTypes>(
    (query?.tab as TimelineTabTypes) ?? "upcoming"
  );
  useEffect(() => {
    if (!canHaveUpcomingItems) {
      handleChangeTab("past");
    }
  }, [canHaveUpcomingItems]);

  const handleChangeTab = (value: TimelineTabTypes) => {
    setSelectedTab(value);
    router.replace({
      query: { ...router.query, tab: value },
    });
  };

  const renderButtonTab = ({
    label,
    value,
    disabled = false,
  }: {
    label: string;
    value: TimelineTabTypes;
    disabled?: boolean;
  }) => (
    <Button
      small
      overrideColors
      onClick={() => handleChangeTab(value)}
      disabled={disabled}
      className={classNames(
        "hover:border-action-950 focus:border-action-900 text-lg font-normal disabled:bg-transparent",
        "mr-2 border-b-4 rounded-none pb-4 pt-6",
        selectedTab === value
          ? "font-medium border-action-700 hover:border-action-700 focus:border-action-700"
          : "font-normal border-transparent"
      )}
    >
      {label}
    </Button>
  );

  return (
    <div className="sm:sticky sm:top-0 sm:bg-white sm:z-10">
      <header className="flex items-center justify-between items-center space-x-4 border-b border-grey-900">
        <div className="flex flex-1">
          {renderButtonTab({
            label: "Upcoming",
            value: "upcoming",
            disabled: !canHaveUpcomingItems,
          })}
          {renderButtonTab({
            label: "Past",
            value: "past",
          })}
        </div>
        {children}
      </header>
    </div>
  );
};

export default TimelineHeader;
