import React, { FC, useEffect, useRef, useState } from "react";
import { getContactAppointments } from "api-services/definitions/contact-appointments";
import { useApiGetMutate } from "api-services/endpoints";
import classNames from "classnames";

import { useAuth } from "@contexts/auth";
import { useReconciliationInvoiceAppointmentRelated } from "@hooks/use-appointment";
import useAppointment from "@hooks/use-appointment";
import useLinkPackage from "@hooks/use-link-package";
import { useUpdateAppointmentsCache } from "@hooks/useUpdateAppointmentsCache";

import { Button } from "@components/Button";
import BookmarkIcon from "@components/Icons/BookmarkIcon";
import EventExternalIcon from "@components/Icons/EventExternalIcon";
import MoreIcon from "@components/Icons/MoreIcon";
import NoteIcon from "@components/Icons/NoteIcon";
import PackageIcon from "@components/Icons/PackageIcon";
import PencilIcon from "@components/Icons/PencilIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import ViewOffIcon from "@components/Icons/ViewOffIcon";
import ViewOnIcon from "@components/Icons/ViewOnIcon";
import Menu from "@components/Menu/Menu";
import MenuButton from "@components/Menu/MenuButton";

interface AppointmentMoreMenuProps {
  className?: string;
  appointmentId: string;
  packageInstanceId?: string;
  packageInstanceIds?: string[];
  packageInstanceOwnerId?: string;
  isEvent?: boolean;
  isGroup?: boolean;
  isPast?: boolean;
  showMenu: boolean;
  onAddLinkedNote?: () => void;
  handleLinkPackage: (appointmentId: string) => void;
  toggleOutcomeModal: () => void;
  canEditOutcome?: boolean;
  canDelete?: boolean;
  toggleDeleteModal?: () => void;
  shouldCheckInvoice?: boolean;
}

const AppointmentMoreMenu: FC<AppointmentMoreMenuProps> = ({
  className,
  appointmentId,
  packageInstanceId,
  packageInstanceIds,
  packageInstanceOwnerId,
  isEvent = false,
  isGroup = false,
  isPast = true,
  showMenu,
  onAddLinkedNote,
  handleLinkPackage,
  toggleOutcomeModal,
  shouldCheckInvoice = false,
  canEditOutcome,
  canDelete,
  toggleDeleteModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const { oid, uid } = useAuth();
  const { appointment, update } = useAppointment(uid, appointmentId);
  const { link } = useLinkPackage({
    coachId: uid,
    clientId: appointment?.contactId,
  });
  const hidden = appointment?.hidden;

  // appointment invoice related must be related to a package instance id,
  // if not, it's not a package related appointment we don't need to call the
  // endpoint to get the invoice related data
  const { data: invoiceData } = useReconciliationInvoiceAppointmentRelated(
    shouldCheckInvoice && packageInstanceId ? appointmentId : undefined
  );
  const invoiceRelatedIsApproved = !!invoiceData?.isApproved;

  const mutateContactAppointments = useApiGetMutate(
    oid && appointment?.contactId ? getContactAppointments : undefined,
    {
      orgId: oid ?? "",
      contactId: appointment?.contactId ?? "",
    },
    undefined,
    {
      ignoreQuery: true,
    }
  );
  const { onUpdateAppointment } = useUpdateAppointmentsCache();

  const showMoreInfo = (e) => {
    if (e.which === 3) return;
    if (!ref?.current?.contains(e.target)) {
      setIsOpen(false);
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", showMoreInfo);
    Array.from(document.getElementsByClassName("contentbox")).map((box) =>
      box.addEventListener("contextmenu", (e) => e.preventDefault())
    );

    return () => {
      document.removeEventListener("mousedown", showMoreInfo);
      Array.from(document.getElementsByClassName("contentbox")).map((box) =>
        box.removeEventListener("contextmenu", (e) => e.preventDefault())
      );
    };
  }, []);

  const showAddToPackageOption =
    !packageInstanceId && !isEvent && !isGroup && !packageInstanceIds;

  const editUrl = `/${
    isEvent ? "events" : "appointments"
  }/${appointmentId}/edit`;

  const options = [
    packageInstanceId &&
      !invoiceRelatedIsApproved && {
        icon: PackageIcon,
        children: "Remove from package",
        onClick: () =>
          link({
            appointmentId,
            packageInstanceId,
            action: "remove",
            packageInstanceOwnerId,
          }),
      },
    showAddToPackageOption && {
      icon: EventExternalIcon,
      children: "Add to package",
      onClick: () => handleLinkPackage(appointmentId),
    },
    !isPast &&
      !appointment?.customEventInformation &&
      !appointment?.groupSchedulerInformation && {
        icon: PencilIcon,
        children: `Edit ${isEvent ? "event" : "appointment"}`,
        href: editUrl,
      },
    canEditOutcome &&
      !invoiceRelatedIsApproved && {
        icon: BookmarkIcon,
        children: "Edit outcome",
        onClick: () => toggleOutcomeModal(),
      },
    !packageInstanceId &&
      !isEvent && {
        icon: hidden ? ViewOnIcon : ViewOffIcon,
        children: `${hidden ? "Unhide" : "Hide"} appointment`,
        onClick: async () => {
          await update({ hidden: !hidden });
          await mutateContactAppointments();
          await onUpdateAppointment(appointmentId);
        },
      },
    appointment?.contactId && {
      icon: NoteIcon,
      children: "Add session note",
      onClick: onAddLinkedNote,
    },
    canDelete && {
      icon: TrashIcon,
      children: "Delete appointment",
      onClick: () => toggleDeleteModal?.(),
    },
  ].filter(Boolean);

  return (
    <div className="relative z-50">
      {showMenu && !!options?.length && (
        <Button
          className={classNames(className, "m-0")}
          onClick={() => setIsOpen(!isOpen)}
          white
          square
        >
          <MoreIcon />
        </Button>
      )}

      <Menu show={isOpen} className="absolute top-12 right-0 pt-1 w-56">
        <div
          className="rounded-md bg-white ring-1 ring-black/5 py-2"
          onMouseLeave={() => setIsOpen(false)}
        >
          <div
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map(({ icon: Icon, ...rest }, index: number) => (
              <MenuButton
                key={`option-menu-item-${appointmentId}-${index}`}
                icon={<Icon />}
                {...rest}
              />
            ))}
          </div>
        </div>
      </Menu>
    </div>
  );
};

export default AppointmentMoreMenu;
