import { useMemo } from "react";
import {
  CollectionQueryType,
  CollectionSWROptions,
  useCollection,
} from "swr-firebase";

import { FormType } from "@lib/data/schemas/form";

type UseContactFormsType = (
  {
    uid,
    contactId,
    onlyPublic,
  }: {
    uid?: string;
    contactId?: string;
    onlyPublic?: boolean;
  },
  options?: CollectionSWROptions
) => {
  forms: FormType[];
  regularForms: FormType[];
  legalForms: FormType[];
  loading: boolean;
};

const useContactForms: UseContactFormsType = (
  { uid, contactId, onlyPublic = false },
  options?: CollectionSWROptions
) => {
  const query: CollectionQueryType<FormType> = {
    orderBy: ["createdAt", "desc"],
    ...(options && options),
  };
  if (onlyPublic) {
    query.where = ["status", "in", ["shared", "viewed", "submitted"]];
  }
  const { data, loading } = useCollection<FormType>(
    uid && contactId ? `users/${uid}/clients/${contactId}/forms` : null,
    { ...query, listen: true }
  );

  const forms = useMemo(
    () => data?.filter(({ status }) => status !== "deleted") ?? [],
    [data]
  );

  const { regularForms, legalForms } = useMemo(() => {
    return forms.reduce(
      (acc, form) => {
        const isLegal = form.items?.some((it) => it.questionType === "legal");
        return isLegal
          ? { ...acc, legalForms: [...acc.legalForms, form] }
          : { ...acc, regularForms: [...acc.regularForms, form] };
      },
      { regularForms: [] as FormType[], legalForms: [] as FormType[] }
    );
  }, [forms]);

  return { forms, regularForms, legalForms, loading };
};

export default useContactForms;
