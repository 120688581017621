import usePersistentState from "./use-persistent-state";

type SetShowHiddenArtifacts = (value: boolean) => void;

const useHiddenArtifacts = (key: string = "showHiddenArtifacts") => {
  const {
    persistentValue: showHiddenArtifacts,
    persistentSetValue: setShowHiddenArtifacts,
  } = usePersistentState(key, false);

  return {
    showHiddenArtifacts,
    setShowHiddenArtifacts: setShowHiddenArtifacts as SetShowHiddenArtifacts,
  };
};

export default useHiddenArtifacts;
