import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PackageInstanceApi, PackageInstanceCard } from "@practice/sdk";
import moment, { Moment } from "moment";

import { useAuth } from "@contexts/auth";
import { useMutatePackages } from "@hooks/use-client-organization-data";
import { useSDKApiExecute } from "@hooks/use-sdk-api";
import { isCycleBased } from "@lib/models/package-instances/utils";
import { momentDate } from "@lib/utils/todos";

import { PastDatesPicker } from "@components/Form/DatePicker";
import Label from "@components/Form/Label";
import EventCreationIcon from "@components/Icons/EventCreationIcon";
import EventTimeIcon from "@components/Icons/EventTimeIcon";
import FormWrappedSmallModal from "@components/Modals/FormWrappedSmallModal";

interface EditPackageInstanceStartDateFormProps {
  packageInstance: PackageInstanceCard;
}
const EditPackageInstanceStartDateForm: FC<
  EditPackageInstanceStartDateFormProps
> = ({ packageInstance }) => {
  const { control } = useFormContext();
  const startDate = packageInstance.startDate;
  const isRecurringBased = isCycleBased(packageInstance?.packageType);
  const isPackageInProgress = packageInstance.totalBooked > 0;
  return (
    <>
      <Label className="mb-1">Start date</Label>
      <Controller
        control={control}
        name="startDate"
        render={({ field: { value, onChange } }) => (
          <div className="w-full custom_field_input">
            <PastDatesPicker
              CustomInputIcon={EventCreationIcon}
              date={value}
              showClearDate
              onChange={(e) => onChange(e.target.value)}
              placeholder="Select a start date"
              displayYear={true}
              isOutsideRange={(date) => {
                if (isRecurringBased && isPackageInProgress)
                  return date.isAfter(momentDate(startDate));
                return false;
              }}
            />
          </div>
        )}
      />
    </>
  );
};

interface EditPackageInstanceStartDateModalProps {
  toggleEditStartDateModal: (val: boolean) => void;
  packageInstance: PackageInstanceCard;
  clientId: string;
  clientParentId?: string;
}

const EditPackageInstanceStartDateModal: FC<
  EditPackageInstanceStartDateModalProps
> = ({
  toggleEditStartDateModal,
  packageInstance,
  clientId,
  clientParentId,
}) => {
  const { oid } = useAuth();
  const startDate = packageInstance.startDate;

  const { execute, loading } = useSDKApiExecute(PackageInstanceApi, {
    method: "updatePackageInstanceStartDate",
    errorLogMessage: "Failed to update package start date",
    keyOrigin: "use-update-package-instance-start-date",
    showErrorSnackbar: true,
  });
  const mutatePackages = useMutatePackages({
    clientId,
    parentId: clientParentId,
  });

  const onSubmit = async (data: { startDate: Moment }) => {
    if (!oid) return;
    await execute({
      organizationId: oid,
      packageInstanceId: packageInstance.id,
      updatePackageInstanceStartDateRequest: {
        date: data.startDate.toDate(),
      },
    });
    await mutatePackages();
  };

  const defaultStart = startDate ? momentDate(startDate).utc() : moment().utc();

  return (
    <FormWrappedSmallModal
      show
      toggleShow={toggleEditStartDateModal}
      icon={EventTimeIcon}
      iconColor="green"
      title="Edit package start date"
      description="The package start date can only be changed when the package is scheduled or during its initial cycle."
      onActionText="Apply new start date"
      onAction={onSubmit}
      defaultValues={{ startDate: defaultStart }}
      onActionLoading={loading}
    >
      <EditPackageInstanceStartDateForm packageInstance={packageInstance} />
    </FormWrappedSmallModal>
  );
};

export default EditPackageInstanceStartDateModal;
