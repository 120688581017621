import React from "react";

import { useContact } from "@hooks/data/clients";
import { displayNameFromContact } from "@lib/contacts";

import GroupIcon from "@components/Icons/GroupIcon";

interface AppointmentClientProps {
  clientId: string;
}

const AppointmentClient: React.FC<AppointmentClientProps> = ({ clientId }) => {
  const { contact } = useContact(clientId);

  return <>{displayNameFromContact(contact, true)}</>;
};

interface AppointmentClientsProps {
  clientIds: string[];
}

const AppointmentClients: React.FC<AppointmentClientsProps> = ({
  clientIds,
}) => {
  if (clientIds.length == 0) return null;

  return (
    <div className="flex items-center gap-2 text-xs font-medium">
      <span>·</span>
      <>
        <div className="p-1 rounded-full bg-foreground/20">
          <GroupIcon className="h-4 w-4" />
        </div>
        <span>
          {clientIds.map((id, index) => {
            return (
              <React.Fragment key={id}>
                {index !== 0 && <span>, </span>}
                <AppointmentClient key={id} clientId={id} />
              </React.Fragment>
            );
          })}
        </span>
      </>
    </div>
  );
};

export default AppointmentClients;
