import { FC, useMemo } from "react";
import classNames from "classnames";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";
import AccountDetail from "@components/PublicProfile/AccountDetail";

import CommonMoreMenu from "./CommonMoreMenu";
import RenderWithLink from "./RenderWithLink";
import { classes } from "./shared";
import { TimelineArtifactItemProps } from "./types";

interface NoteProps extends TimelineArtifactItemProps {
  coachSlug: string;
}

export const Note: FC<NoteProps> = ({
  item,
  coachSlug,
  contactId,
  isCoach,
  artifactSettings,
  onChangeHidden,
}) => {
  const linkUrl = !isCoach
    ? `/me/${coachSlug}/clients/${contactId}/notes/${item.id}`
    : item?.linkUrl
    ? item.linkUrl
    : `/contacts/${contactId}/notes/${item.id}`;

  const hidden = useMemo(() => {
    const { notes } = artifactSettings || {};
    return notes?.hidden?.includes(item.id);
  }, [artifactSettings]);

  const renderMoreMenu = isCoach ? (
    <CommonMoreMenu
      artifact="notes"
      item={item}
      artifactSettings={artifactSettings}
      onChangeHidden={onChangeHidden}
    />
  ) : undefined;

  return (
    <RenderWithLink
      isCoach={isCoach}
      item={item}
      linkUrl={linkUrl}
      isHidden={hidden}
      moreMenu={renderMoreMenu}
    >
      <ArtifactStatusIcon status={item.status} type="notes" className="mr-4" />
      <div className={classNames(classes.content, "truncate")}>
        <p className="truncate">{item.title || item.url}</p>
        <div className="flex items-center space-x-2">
          <ArtifactStatusText status={item.status} type="notes" size="xs" />
          {item.contacts && (
            <AccountDetail
              accounts={item.contacts}
              showDividerBefore
              accountClassNames="text-xs font-medium"
              avatarSize="xxsmall"
            />
          )}
        </div>
      </div>
    </RenderWithLink>
  );
};
