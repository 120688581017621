import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import useSnackbar from "@hooks/use-snackbar";
import analytics from "@lib/analytics";

import SmallBanner from "./Banner/SmallBanner";
import InfoIcon from "./Icons/InfoIcon";
import MergeIcon from "./Icons/MergeIcon";
import { InfoModal } from "./Modal/InfoModal";
import ClientPicker from "./ClientPicker";

interface ContactMergeModalProps {
  contactId: string;
  visible: boolean;
  toggle: () => void;
}

const ContactMergeModal: FC<ContactMergeModalProps> = ({
  contactId: defaultSecondaryId,
  visible,
  toggle,
}) => {
  const [, setLoading] = useState(false);
  const { uid } = useAuth();
  const [primaryId, setPrimaryId] = useState<string | null>(null);
  const [secondaryId, setSecondaryId] = useState(defaultSecondaryId);
  const snackbar = useSnackbar();
  const router = useRouter();

  useEffect(() => {
    if (visible) {
      setLoading(false);
      setPrimaryId(null);
      setSecondaryId(defaultSecondaryId);
    }
  }, [visible, defaultSecondaryId]);

  const onMerge = async () => {
    if (!primaryId || !secondaryId) return;
    // TODO encapsulate this behaviour
    analytics.track({
      event: "Merge clients",
      userId: uid,
    });
    setLoading(true);
    await axios.post(`/api/v1/users/${uid}/clients/merge`, {
      primaryId,
      secondaryId,
    });
    toggle();
    router.push(`/contacts/${primaryId}`);
    snackbar.showMessage("Contacts merged", undefined, "Back to list", () =>
      router.push(`/contacts`)
    );
  };

  return (
    <InfoModal
      actionText="Confirm merge"
      onActionClick={primaryId && secondaryId && onMerge}
      settings={{
        hasBack: false,
        heading: "Merge clients",
        Icon: MergeIcon,
        positiveAction: true,
      }}
      visible={visible}
      toggle={toggle}
      hasOverflow
    >
      <p className="text-center text-grey-500">
        Select which clients you&apos;d like to merge.
      </p>
      <ClientPicker
        className="mt-4"
        contactId={secondaryId}
        label="Merge this client"
        handleClientSelection={setSecondaryId}
        readonlyContact={!!defaultSecondaryId}
      />
      <ClientPicker
        className="mt-4"
        contactId={primaryId}
        label="Into this client"
        handleClientSelection={setPrimaryId}
        excludedContacts={[secondaryId]}
      />
      <SmallBanner
        className="mt-4"
        variant="info"
        items={[
          {
            Icon: InfoIcon,
            text: "Merge is permanent and only the second client chat will be kept.",
          },
        ]}
      />
    </InfoModal>
  );
};

export default ContactMergeModal;
