import { FC } from "react";

import Toggle from "@components/Form/Toggle";

interface HideArtifactToggleProps {
  label?: string;
  setShowHiddenArtifacts: (value: boolean) => void;
  showHiddenArtifacts: boolean;
}

const HideArtifactToggle: FC<HideArtifactToggleProps> = ({
  label = "Show hidden",
  setShowHiddenArtifacts,
  showHiddenArtifacts,
}) => (
  <div className="flex justify-between items-center w-full text-left px-4 py-5 border-t border-grey-950 leading-5 text-black-ink">
    {label}
    <Toggle
      onChange={() => {
        setShowHiddenArtifacts(!showHiddenArtifacts);
      }}
      value={showHiddenArtifacts}
    />
  </div>
);

export default HideArtifactToggle;
