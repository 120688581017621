import React, { FC } from "react";

import usePersistentState from "@hooks/use-persistent-state";
import { displayNameFromContact } from "@lib/contacts";
import { ClientType } from "@lib/data/schemas/client";

import { Button } from "@components/Button";
import DrawingColorIcon from "@components/Icons/DrawingColorIcon";
import PlusIcon from "@components/Icons/PlusIcon";

interface TimelineEmptyProps {
  onClickPlanning: () => void;
  client: ClientType;
}

const TimelineEmpty: FC<TimelineEmptyProps> = ({ onClickPlanning, client }) => {
  const { persistentValue, persistentSetValue } = usePersistentState(
    "presistentState",
    {
      timelineEmpty: true,
    }
  );

  const handleClickDismiss = () =>
    persistentSetValue({ ...persistentSetValue, timelineEmpty: false });

  const renderActions = () => {
    const buttonStyle = "mb-4 xl:mb-0 xl:mr-4 xl:last:mr-0";
    return (
      <div className="mt-4 flex flex-col xl:flex-row">
        <Button
          className={buttonStyle}
          onClick={() => onClickPlanning(true)}
          primary
          data-heap-event-name="clicked_continue_planning_client_record"
        >
          <span className="flex items-center">
            <PlusIcon className="mr-3" />
            Continue planning
          </span>
        </Button>
        <Button
          className={buttonStyle}
          onClick={handleClickDismiss}
          data-heap-event-name="clicked_dont_need_help_client_record"
        >
          I don’t need help
        </Button>
      </div>
    );
  };

  if (!persistentValue.timelineEmpty) return null;

  return (
    <div className="mb-10">
      <DrawingColorIcon className="mb-4" />
      <h2 className="font-medium text-2xl text-black-ink mb-2">
        You’re off to a great start!
      </h2>
      <p className="font-normal text-base text-grey-500">
        Find all you need to know and plan for your next step with{" "}
        {displayNameFromContact(client, true)}.
      </p>
      {renderActions()}
    </div>
  );
};

export default TimelineEmpty;
