import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PlayTriangleOutlineIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("stroke-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2854 11.5713C16.6091 11.7655 16.6091 12.2345 16.2854 12.4287L7.75725 17.5457C7.42399 17.7456 7 17.5056 7 17.1169L7 6.88309C7 6.49445 7.42399 6.25439 7.75725 6.45435L16.2854 11.5713Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlayTriangleOutlineIcon;
