import { format } from "date-fns";

import { FirebaseDateTimeType } from "@lib/shared-types";

type ItemType = {
  __type: string;
  addedAt?: FirebaseDateTimeType;
  start?: FirebaseDateTimeType;
  archivedAt?: FirebaseDateTimeType;
  createdAt?: FirebaseDateTimeType;
  date?: Date;
  clients?: any;
  hidden?: boolean;
};

const groupedTimelineItems = (
  items: ItemType[],
  orderByDate: (newItems: ItemType[], groupType: string) => ItemType[]
): ItemType[] => {
  if (!items.find((item) => item.__type === "memberAdded")) return items;
  const newItems: ItemType[] = [];
  const dateGroups: any = {};
  items.forEach((item: ItemType) => {
    if (item.__type !== "memberAdded") {
      newItems.push(item);
      return;
    }
    const getDate = (item: ItemType) =>
      item.addedAt && format(new Date(item?.addedAt), "PP");
    const date = getDate(item);
    dateGroups[date] = dateGroups[date]
      ? dateGroups[date].concat(item)
      : [item];
  });
  const dateKeys = Object.keys(dateGroups);
  dateKeys.forEach((date) => {
    newItems.push({
      addedAt: dateGroups[date][0].addedAt,
      __type: "groupedMembers",
      clients: dateGroups[date],
    });
  });
  return orderByDate(newItems, "groupedMembers");
};

export default groupedTimelineItems;
