import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PackageInstanceCard } from "@practice/sdk";
import moment, { Moment } from "moment";

import useResumePackage from "@hooks/use-resume-package";
import useSnackbar from "@hooks/use-snackbar";

import { PastDatesPicker } from "@components/Form/DatePicker";
import Label from "@components/Form/Label";
import EventCreationIcon from "@components/Icons/EventCreationIcon";
import PlayIcon from "@components/Icons/PlayIcon";
import FormWrappedSmallModal from "@components/Modals/FormWrappedSmallModal";

const EditPackageStartDateForm: React.FC<{ min: Moment }> = ({ min }) => {
  const { control } = useFormContext();

  return (
    <>
      <Label className="mb-1">Start date</Label>
      <Controller
        control={control}
        rules={{ required: "Start date is required" }}
        name="date"
        render={({ field: { value, onChange } }) => (
          <div className="w-full custom_field_input">
            <PastDatesPicker
              CustomInputIcon={EventCreationIcon}
              date={value}
              showClearDate
              isOutsideRange={(date) => min.isAfter(date, "day")}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Select a resume date"
              displayYear={true}
            />
          </div>
        )}
      />
    </>
  );
};

const PackageInstanceResumeContent: React.FC<{
  packageInstance: PackageInstanceCard;
  toggle: () => void;
  clientId: string;
  clientParentId?: string;
  orgId: string;
}> = ({ toggle, clientId, clientParentId, orgId, packageInstance }) => {
  const snackbar = useSnackbar();

  const { resumePackageInstance, loading } = useResumePackage(
    orgId,
    clientId,
    clientParentId,
    {
      failMode: "throw",
      logName: "PackageInstanceResumeContent",
    }
  );

  const onSubmit = async ({ date }: { date: Moment }) => {
    try {
      await resumePackageInstance(packageInstance.id, date);
      toggle();
    } catch (e) {
      snackbar.showWarning("Failed to update package instance");
    }
  };

  const getMinDate = useCallback(() => {
    return moment.max(moment(), moment(packageInstance.resetDate));
  }, [packageInstance.resetDate]);

  return (
    <FormWrappedSmallModal
      show
      toggleShow={toggle}
      icon={PlayIcon}
      iconColor="action"
      title="Resume package"
      description="We'll resume all reminders and session accrual regarding this package on the new start date."
      onAction={onSubmit}
      onActionLoading={loading}
      isCloseAfterPrimaryActionDisabled
      onActionText="Resume package"
      defaultValues={{ date: getMinDate() }}
    >
      <EditPackageStartDateForm min={getMinDate()} />
    </FormWrappedSmallModal>
  );
};

export default PackageInstanceResumeContent;
