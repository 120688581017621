import { FC } from "react";
import classNames from "classnames";

import { useContact } from "@hooks/data/clients";
import { displayNameFromContact } from "@lib/contacts";

interface MemberAddedInterface {
  item: { clients: { clientId: string }[] };
}

const ClientName: FC<{ clientId: string }> = ({ clientId }) => {
  const contact = useContact(clientId);
  if (!contact) return null;
  return <span>{displayNameFromContact(contact)}</span>;
};

const MemberAdded: FC<MemberAddedInterface> = ({ item }) => {
  const clientIds = item.clients?.map(
    ({ clientId }: { clientId: string }) => clientId
  );
  const last = clientIds && clientIds[clientIds?.length - 1];
  const alone = clientIds?.length === 1;
  const secondToLast = clientIds && clientIds[clientIds?.length - 2];
  return (
    <>
      {clientIds?.map((clientId: string) => (
        <span key={clientId} className="text-sm">
          {clientId === last && !alone && (
            <span
              className={classNames(
                clientId !== secondToLast && "ml-1",
                "text-grey-500 mr-1"
              )}
            >
              and
            </span>
          )}
          <span className="font-medium text-sm">
            <ClientName clientId={clientId} />
            {clientId !== last && !alone && clientId !== secondToLast && (
              <span className="mr-1">,</span>
            )}
          </span>
          <span className="text-grey-500">
            {clientId === last && (
              <span className="ml-1">{`${
                alone ? "was" : "were"
              } added to the group`}</span>
            )}
          </span>
        </span>
      ))}
    </>
  );
};

export default MemberAdded;
