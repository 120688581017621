import { FC, useContext, useEffect, useState } from "react";
import { PackageInstanceCard } from "@practice/sdk";
import { getContactAppointments } from "api-services/definitions/contact-appointments";
import { useApiGetMutate } from "api-services/endpoints";
import classNames from "classnames";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { PackagesContext } from "pages/contacts/[contactId]";

import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import useAppointmentLinkNotes from "@hooks/use-appointment-link-notes";
import useLinkPackage from "@hooks/use-link-package";
import { useCreateNotes } from "@hooks/use-notes";
import useOutcomes from "@hooks/use-outcomes";
import { usePackageSchedulers } from "@hooks/use-package-schedulers";
import { usePackages } from "@hooks/use-packages";
import useToggle from "@hooks/use-toggle";
import { AppointmentWithOrganizerType } from "@lib/appointments";
import { getPackageInstanceTag } from "@lib/packages/package-instances";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";
import { DeleteAppointmentModal } from "@components/DeleteAppointmentModal";
import { OutcomeIconLabel } from "@components/Outcomes/OutcomeIcon";
import OutcomeModal from "@components/Outcomes/OutcomeModal";
import AssignPackageSchedulerModal from "@components/Package/AssignPackageSchedulerModal";
import AddApptsToPackageInstanceModalWrapper from "@components/PackageInstance/AddApptsToPackageInstanceModalWrapper";
import AccountDetail from "@components/PublicProfile/AccountDetail";
import Tag from "@components/Tag/Tag";
import AppointmentMoreMenu from "@components/Timeline/Items/AppointmentMoreMenu";

import AppointmentClients from "./AppointmentClients";
import RenderWithLink from "./RenderWithLink";
import { classes } from "./shared";

interface AppointmentProps {
  item: AppointmentWithOrganizerType;
  isCoach?: boolean;
  isPast?: boolean;
  isSideModal?: boolean;
  clientParentId?: string;
}

export const Appointment: FC<AppointmentProps> = ({
  item,
  isCoach,
  isPast = true,
  isSideModal = false,
  clientParentId,
}) => {
  const router = useRouter();
  const { oid, aid } = useAuth();
  const { permissions } = useAccessType();
  const clientId = item?.contactId as string;
  const { link } = useLinkPackage({ coachId: oid!, clientId });
  const { linkNotes } = useAppointmentLinkNotes({
    oid,
    contactId: clientId,
  });
  const { outcome } = useOutcomes(item.outcome?.outcomeId);
  const isOwner = aid === item?.organizer?.id;
  const canEditOutcome = isOwner || permissions?.canEditAllContacts;
  const canDelete =
    (isOwner || permissions?.canEditAllContacts) &&
    !item.packageInstanceId &&
    !item.groupSchedulerInformation;
  const { toggle: toggleAssignPackageModal, value: assignPackageModalVisible } =
    useToggle();
  const { toggle: toggleAddApptsModal, value: addApptsModalVisible } =
    useToggle();

  const { toggle: toggleOutcomeModal, value: outcomeModalVisible } =
    useToggle();
  const { toggle: toggleDeleteModal, value: deleteModalVisible } = useToggle();
  const [selectedPackageInstance, setSelectedPackageInstance] = useState<
    PackageInstanceCard | undefined
  >(undefined);

  const mutateContactAppointments = useApiGetMutate(
    oid ? getContactAppointments : undefined,
    {
      orgId: oid!,
      contactId: item.contactId!,
    },
    undefined,
    {
      ignoreQuery: true,
    }
  );

  const currentContactId =
    (router?.query?.contactId as string) || clientId || "";

  const createNote = useCreateNotes(clientId);
  const { outcomes } = useOutcomes();

  const isEvent = !!item?.eventData;
  const isGroup = !!item?.groupId;
  const status = isEvent ? "shared" : item?.status;
  const artifactType = isGroup
    ? "groupAppointments"
    : isEvent
    ? "eventAttendees"
    : "appointments";
  const {
    packageInstanceId,
    packageInstancesAppointmentsMap,
    packageInstances,
    packageInstance,
  } = useContext(PackagesContext);
  const itemPackageInstanceId =
    item?.packageInstanceId ||
    item?.packageInstanceIds?.find(
      (id) => packageInstances?.some((pi) => pi.id === id)
    );
  const packageInstanceTag =
    itemPackageInstanceId &&
    getPackageInstanceTag(
      itemPackageInstanceId,
      item?.id,
      packageInstancesAppointmentsMap
    );

  const { packages } = usePackages();
  const { packageSchedulers } = usePackageSchedulers(
    currentContactId,
    undefined,
    undefined,
    clientParentId ?? undefined
  );

  const noActivePackage = isEmpty(packageInstances);
  const showMenu = isCoach && !(isEvent && noActivePackage) && !isSideModal;

  const onAddLinkedNote = async () => {
    if (!clientId || !item?.id) return;
    const noteId = await createNote({
      title: "Untitled note",
      richContent: "",
    });
    await linkNotes(item.id, [noteId], "add");
    router.push(`/contacts/${clientId}/notes/${noteId}/edit?mode=create`);
  };

  const linkAppt = async (
    packageInstanceId: string,
    packageInstanceOwnerId: string | undefined,
    appointmentId: string,
    schedulerId?: string
  ) => {
    await link({
      appointmentId,
      packageInstanceId,
      packageInstanceOwnerId,
      availabilityId: schedulerId,
    });
  };

  const handleAddSeries = (packageInstanceId: string) => {
    const packageInstance = packageInstances?.find(
      (packageObject) => packageObject.id === packageInstanceId
    );

    if (!packageInstance) return;

    setSelectedPackageInstance(packageInstance);
  };

  const handleToggleAddApptsModal = () => {
    setSelectedPackageInstance(undefined);
    toggleAddApptsModal();
  };

  useEffect(() => {
    if (selectedPackageInstance) {
      toggleAddApptsModal();
    }
  }, [selectedPackageInstance]);

  return (
    <>
      <RenderWithLink
        isCoach={true} // same for coaches and cclients, always show modal
        item={item}
        linkUrl={`/appointments/${item.id}`}
        isHidden={!!item?.hidden}
        moreMenu={
          isCoach && (
            <AppointmentMoreMenu
              appointmentId={item?.id}
              packageInstanceId={
                !noActivePackage && itemPackageInstanceId
                  ? itemPackageInstanceId
                  : undefined
              }
              packageInstanceIds={item?.packageInstanceIds}
              selectedPackageInstanceId={packageInstanceId}
              packageInstanceOwnerId={packageInstance?.clientId}
              shouldCheckInvoice={packageInstance?.packageType === "usage"}
              isEvent={isEvent}
              isGroup={isGroup}
              isPast={isPast}
              packageInstanceTag={packageInstanceTag}
              showMenu={showMenu}
              onAddLinkedNote={onAddLinkedNote}
              handleLinkPackage={toggleAssignPackageModal}
              toggleOutcomeModal={toggleOutcomeModal}
              canEditOutcome={canEditOutcome}
              canDelete={canDelete}
              toggleDeleteModal={toggleDeleteModal}
            />
          )
        }
      >
        <ArtifactStatusIcon
          status={status}
          outcome={outcome}
          type={artifactType}
          className="mr-4"
        />
        <div className={`flex w-full ${classes.content}`}>
          <div className="truncate">
            <div className="flex items-center">
              <p className="truncate">{item.title}</p>
              {packageInstanceTag && (
                <Tag
                  className="ml-2 mr-2"
                  isSelected={packageInstanceId === itemPackageInstanceId}
                >
                  {packageInstanceTag}
                </Tag>
              )}
            </div>
            <div className="flex items-center space-x-2">
              {outcome ? (
                <OutcomeIconLabel outcome={outcome} className="text-xs" />
              ) : (
                <ArtifactStatusText
                  status={status}
                  type={artifactType}
                  size="xs"
                />
              )}

              {item.contacts ? (
                <AccountDetail
                  accounts={item.contacts}
                  showDividerBefore
                  accountClassNames="text-xs font-medium"
                  avatarSize="xxsmall"
                />
              ) : (
                <AppointmentClients clientIds={item.contactIds ?? []} />
              )}
            </div>
          </div>
          <div
            className={classNames(
              "ml-auto text-right shrink-0",
              showMenu && "group-hover:opacity-0"
            )}
          >
            {item.allDayEvent ? (
              <>
                <div className="text-grey-500 text-sm">All day</div>
              </>
            ) : (
              <>
                <div>{format(item.start, "p")}</div>
                <div className="text-grey-500 text-sm">
                  {format(item.end, "p")}
                </div>
              </>
            )}
          </div>
        </div>
      </RenderWithLink>
      {assignPackageModalVisible && (
        <AssignPackageSchedulerModal
          packages={packages ?? []}
          packageInstances={packageInstances ?? []}
          packageSchedulers={packageSchedulers ?? []}
          toggleShow={toggleAssignPackageModal}
          show
          appointment={item}
          linkAppt={linkAppt}
          onAddSeries={handleAddSeries}
        />
      )}
      {addApptsModalVisible && selectedPackageInstance && (
        <AddApptsToPackageInstanceModalWrapper
          clientId={clientId}
          packageInstance={selectedPackageInstance}
          setShowModal={handleToggleAddApptsModal}
        />
      )}
      {outcomeModalVisible && outcomes && (
        <OutcomeModal
          setShowModal={toggleOutcomeModal}
          appointment={item}
          outcomes={outcomes}
        />
      )}
      {deleteModalVisible && (
        <DeleteAppointmentModal
          item={item}
          visible={deleteModalVisible}
          toogle={toggleDeleteModal}
          organizationId={oid!}
          onSuccess={mutateContactAppointments}
        />
      )}
    </>
  );
};
