import { createContext, useEffect, useMemo, useState } from "react";
import {
  ActivityAction,
  ActivityApi,
  ActorType,
  PackageInstanceCard,
  ResourceType,
} from "@practice/sdk";
import { getArtifactSettings } from "api-services/definitions/artifact-settings";
import { useApiGet } from "api-services/endpoints";
import classNames from "classnames";
import { compact, isEmpty } from "lodash";
import { NextPage } from "next";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import { useClientCollection } from "@contexts/data";
import { useContact } from "@hooks/data/clients";
import { useGetLibraryItemsForClient } from "@hooks/data/library";
import useAccessType from "@hooks/use-access-type";
import useClientChatStatus from "@hooks/use-client-chat-status";
import { useMutatePackages } from "@hooks/use-client-organization-data";
import useClientPersistentState from "@hooks/use-client-persistent-state";
import useContactAppointments from "@hooks/use-contact-appointments";
import useContactForms from "@hooks/use-contact-forms";
import useContactPayments from "@hooks/use-contact-payments";
import { useEmailMetadata } from "@hooks/use-email-threads";
import useFormTemplates from "@hooks/use-form-templates";
import { useGetCalendars } from "@hooks/use-get-integrations";
import useHasFeature from "@hooks/use-has-feature";
import useHiddenArtifacts from "@hooks/use-hidden-artifacts";
import { useInternalFormsForContact } from "@hooks/use-internal-forms";
import { useMatchMedia } from "@hooks/use-match-media";
import useNotes, { useCreateNotes } from "@hooks/use-notes";
import { useOrganizationAccounts } from "@hooks/use-organization-accounts";
import usePackageInstances from "@hooks/use-package-instances";
import { usePackages } from "@hooks/use-packages";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import useSnackbar from "@hooks/use-snackbar";
import useToggle from "@hooks/use-toggle";
import analytics from "@lib/analytics";
import {
  filterIsEvent,
  filterIsNotEvent,
  getRecurringAppointments,
} from "@lib/appointments";
import { FeatureNames } from "@lib/constants/featureNames";
import { displayNameFromContact } from "@lib/contacts";
import { FormTemplateType } from "@lib/data/schemas/form-template";
import { OrganizationType } from "@lib/data/schemas/organization";
import { PackageInstanceType } from "@lib/data/schemas/package-instance";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import {
  countPackageInstancesAppointmentsMap,
  PackageInstancesAppointmentsMapType,
} from "@lib/packages/package-instances";
import { handleShareScheduler } from "@lib/schedulers";
import { getSupportUrl } from "@lib/utils";
import { hasEmail } from "@lib/utils/email";

import { MenuActionType } from "@components/App/ActionsMenu";
import AppFrame from "@components/App/AppFrame";
import ChatPortalHeaderButton from "@components/App/ChatPortalHeaderButton";
import ClientAvatar from "@components/Client/ClientAvatar";
import ClientRecordSidebar from "@components/ClientRecord/ClientRecordSidebar";
import ContactMergeModal from "@components/ContactMergeModal";
import SendEmailModal from "@components/Email/SendEmailModal";
import HideArtifactToggle from "@components/HideArtifactToggle";
import ArchiveIcon from "@components/Icons/ArchiveIcon";
import AssignIcon from "@components/Icons/AssignIcon";
import CalendarIcon from "@components/Icons/CalendarIcon";
import ChatIcon from "@components/Icons/ChatIcon";
import CloseIcon from "@components/Icons/CloseIcon";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import DocumentIcon from "@components/Icons/DocumentIcon";
import EmailIcon from "@components/Icons/EmailIcon";
import EventCreationIcon from "@components/Icons/EventCreationIcon";
import EventFoundIcon from "@components/Icons/EventFoundIcon";
import EventsIcon from "@components/Icons/EventsIcon";
import FolderIcon from "@components/Icons/FolderIcon";
import FormIcon from "@components/Icons/FormIcon";
import HouseIcon from "@components/Icons/HouseIcon";
import InvoiceIcon from "@components/Icons/InvoiceIcon";
import LeftArrowIcon from "@components/Icons/LeftArrowIcon";
import LockIcon from "@components/Icons/LockIcon";
import LoopIcon from "@components/Icons/LoopIcon";
import MergeIcon from "@components/Icons/MergeIcon";
import NoteIcon from "@components/Icons/NoteIcon";
import PackageIcon from "@components/Icons/PackageIcon";
import PencilIcon from "@components/Icons/PencilIcon";
import SharedIcon from "@components/Icons/SharedIcon";
import SocialWebIcon from "@components/Icons/SocialWebIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import UnarchiveIcon from "@components/Icons/UnarchiveIcon";
import UploadIcon from "@components/Icons/UploadIcon";
import LabelsAddButton from "@components/Labels/LabelsAddButton";
import { LinkFormModal } from "@components/Modals";
import AssignClientToMemberModal from "@components/Modals/AssignClientToMemberModal";
import DeleteClientModal from "@components/Modals/DeleteClientModal";
import AboutInternalForms from "@components/Modals/InternalForms/AboutInternalForms";
import InternalFormModal from "@components/Modals/InternalForms/InternalFormModal";
import InternalFormTemplatePickerModal, {
  InternalFormPickerType,
} from "@components/Modals/InternalForms/InternalFormTemplatePickerModal";
import InviteAndShareModal from "@components/Modals/InviteAndShareModals";
import SharingModal from "@components/Modals/SharingModal";
import ReconciliationSidebar from "@components/Reconciliation/ReconciliationSidebar";
import { Redirect } from "@components/Redirect";
import SortDropdown from "@components/SortDropdown";
import TimelineHeader, {
  TimelineTabTypes,
} from "@components/Timeline/TimelineHeader";
import { TimelineView } from "@components/Timeline/TimelineView";
import { UploadModal } from "@components/UploadModal";

import { updateContactStatus } from "../contacts";

export const PackagesContext = createContext<{
  packageInstanceId?: string;
  packageInstancesAppointmentsMap?: PackageInstancesAppointmentsMapType;
  packageInstances?: Array<
    PackageInstanceCard & {
      ownerId?: string;
    }
  >;
  packageInstance?: PackageInstanceType & {
    ownerId?: string;
  };
}>({});

const ContactDetail: NextPage = () => {
  const router = useRouter();
  const snackbar = useSnackbar();
  const { query } = router;
  const { contactId: contactFromQuery } = query;
  const contactId = contactFromQuery as string;
  const { oid, aid, organization, account } = useAuth();
  const { hasMoreThanOneMember } = useOrganizationAccounts();
  const { contact, parentContact, error } = useContact(contactId);
  const mutatePackages = useMutatePackages({
    clientId: contactId,
    parentId: parentContact?.id,
  });
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  useEffect(() => {
    mutatePackages();
  }, [contactId, parentContact]);

  useEffect(() => {
    if (error) {
      snackbar.showActionsMessage("Error to load contact", error.message, [
        {
          title: "Return to contacts list",
          onClick: () => router.push("/contacts"),
        },
        {
          title: "Open support ticket",
          onClick: () =>
            router.push(
              getSupportUrl(
                account?.email ?? "",
                "bug_report",
                "Error to load contact",
                `Error loading contact ${contactId} from organization ${oid}. \nError: ${JSON.stringify(
                  error
                )}`
              )
            ),
        },
      ]);
    }
  }, [error]);

  const { appointments: allClientAppointments } = useContactAppointments({
    oid,
    contactId,
  });

  const { packages } = usePackages();
  const [hasMembersFeature] = useHasFeature(oid, FeatureNames.members);
  const { clientHasJoined } = useClientChatStatus(contact);
  const { setShowHiddenArtifacts, showHiddenArtifacts } = useHiddenArtifacts();
  const {
    setShowHiddenArtifacts: setShowHiddenZeroAmountInvoices,
    showHiddenArtifacts: showHiddenZeroAmountInvoices,
  } = useHiddenArtifacts("zero-amount-invoices");
  const [showPackageFilter, setShowPackageFilter] = useState(false);
  const [selectedInternalTemplate, setSelectedInternalTemplate] =
    useState<FormTemplateType | null>(null);

  const [hasUsageBasedPackageFeature] = useHasFeature(
    oid,
    FeatureNames.usageBasedPackage
  );
  const { hasFullAccess, hasElevatedAccess } = useAccessType();
  const canAssignContacts =
    hasMembersFeature && hasMoreThanOneMember && hasFullAccess;
  const canArchiveContacts = hasFullAccess;
  const canDeleteContacts = hasFullAccess;
  const canMergeContacts = hasElevatedAccess;
  const canCreateInvoice = hasElevatedAccess;
  const canUpdateContacts = hasFullAccess;
  const canViewReconciliationInvoices =
    hasUsageBasedPackageFeature && hasFullAccess;
  const canAssignPackage =
    hasElevatedAccess && packages?.some((p) => p.status === "active");
  const { accounts } = useGetCalendars(!hasFullAccess);
  const hasCalendarIntegrated = useMemo(() => !!accounts?.length, [accounts]);

  const {
    data: artifactSettings,
    loading: loadingArtifactSettings,
    mutate,
  } = useApiGet(getArtifactSettings, {
    userId: oid!,
    accountId: aid!,
  });

  const appointments = useMemo(
    () =>
      getRecurringAppointments(allClientAppointments).filter(
        (appt) => !appt.hidden || showHiddenArtifacts
      ),
    [allClientAppointments, showHiddenArtifacts]
  );

  const { payments: allPayments } = useContactPayments({
    uid: oid,
    contactId,
    includeDrafts: canViewReconciliationInvoices,
  });

  const payments = useMemo(() => {
    if (!hasFullAccess) return [];
    return (
      allPayments
        // hide zero amount invoices when toggle related is true
        ?.filter((payment) => {
          if (!showHiddenZeroAmountInvoices && payment.total === 0)
            return false;
          return true;
        })
        // hide artifact when toggle related is true
        ?.filter(
          (payment) =>
            !artifactSettings?.payments?.hidden?.includes(payment.id) ||
            showHiddenArtifacts
        )
        // only admins can see the reconciliation invoices
        ?.filter((payment) => {
          const hasCredits = (payment?.credits?.length ?? 0) > 0;
          const hasItems = (payment?.items?.length ?? 0) > 0;
          const hasPendingTransactions =
            (payment?.pendingTransactions?.length ?? 0) > 0;
          return payment?.kind === "usage-based"
            ? canViewReconciliationInvoices &&
                (hasCredits || hasItems || hasPendingTransactions)
            : true;
        })
    );
  }, [
    hasFullAccess,
    allPayments,
    showHiddenArtifacts,
    showHiddenZeroAmountInvoices,
  ]);

  const {
    regularForms,
    legalForms,
    loading: loadingForms,
  } = useContactForms({ uid: oid, contactId });

  const forms = useMemo(
    () =>
      regularForms?.filter(
        (form) =>
          !artifactSettings?.forms?.hidden?.includes(form.id) ||
          showHiddenArtifacts
      ),
    [regularForms, showHiddenArtifacts, artifactSettings]
  );

  const legal = useMemo(
    () =>
      legalForms?.filter(
        (form) =>
          !artifactSettings?.forms?.hidden?.includes(form.id) ||
          showHiddenArtifacts
      ),
    [legalForms, showHiddenArtifacts, artifactSettings]
  );

  const { activeInternalTemplates: internalTemplates } = useFormTemplates(oid!);

  const { forms: internalForms } = useInternalFormsForContact(contactId);

  const canCreateFormTemplate = useMemo(() => {
    return (
      hasElevatedAccess || (internalTemplates && internalTemplates.length > 0)
    );
  }, [hasElevatedAccess, internalTemplates]);

  const { data: allFiles } = useGetLibraryItemsForClient({
    clientId: contactId,
    organizationId: oid!,
    collection: "files",
  });
  const { data: allLinks } = useGetLibraryItemsForClient({
    clientId: contactId,
    organizationId: oid!,
    collection: "links",
  });
  const { data: allFolders } = useGetLibraryItemsForClient({
    clientId: contactId,
    organizationId: oid!,
    collection: "folders",
  });

  const loadingLibrary = !allFiles || !allLinks || !allFolders;

  const files = useMemo(
    () =>
      allFiles?.filter(
        (file) =>
          !artifactSettings?.files?.hidden?.includes(file.id) ||
          showHiddenArtifacts
      ),
    [allFiles, showHiddenArtifacts, artifactSettings]
  );

  const links = useMemo(
    () =>
      allLinks?.filter(
        (link) =>
          !artifactSettings?.links?.hidden?.includes(link.id) ||
          showHiddenArtifacts
      ),
    [allLinks, showHiddenArtifacts, artifactSettings]
  );

  const folders = useMemo(
    () =>
      allFolders?.filter(
        (folder) =>
          !artifactSettings?.folders?.hidden?.includes(folder.id) ||
          showHiddenArtifacts
      ),
    [allFolders, showHiddenArtifacts, artifactSettings]
  );

  const { notes: allNotes, loading: loadingNotes } = useNotes({
    oid,
    contactId,
  });
  const createNote = useCreateNotes(contactId);

  const notes = useMemo(
    () =>
      allNotes?.filter(
        (note) =>
          !artifactSettings?.notes?.hidden?.includes(note.id) ||
          showHiddenArtifacts
      ),
    [allNotes, showHiddenArtifacts, artifactSettings]
  );

  const {
    data: allEmailThreads,
    loading: loadingEmailThreads,
    mutate: mutateEmailThreads,
  } = useEmailMetadata({ contactId });

  const emailThreads = useMemo(
    () =>
      allEmailThreads?.filter(
        (thread) =>
          !artifactSettings?.emailThreads?.hidden?.includes(thread.id) ||
          showHiddenArtifacts
      ),
    [allEmailThreads, showHiddenArtifacts, artifactSettings]
  );

  const { packageInstances } = usePackageInstances(
    oid,
    contactId,
    parentContact?.id
  );

  const { data: allSubscriptions } = useClientCollection(
    "subscriptions",
    contactId
  );

  const subscriptions = useMemo(() => {
    if (!hasFullAccess) return [];
    return allSubscriptions?.filter(
      (subscription) =>
        !artifactSettings?.subscriptions?.hidden?.includes(subscription.id) ||
        showHiddenArtifacts
    );
  }, [hasFullAccess, allSubscriptions, showHiddenArtifacts, artifactSettings]);

  const [selectedPackageInstanceIndex, setSelectedPackageInstanceIndex] =
    useState(0);
  const packageInstance =
    packageInstances && packageInstances[selectedPackageInstanceIndex];
  const packageInstanceId = packageInstance?.id;

  const packageInstancesAppointmentsMap = useMemo(
    () =>
      countPackageInstancesAppointmentsMap(
        packageInstances,
        allClientAppointments
      ),
    [packageInstances, allClientAppointments]
  );

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const { value: showSendEmailModal, toggle: toggleSendEmalModal } =
    useToggle();
  const { value: showAssignClientModal, toggle: toggleAssignClientModal } =
    useToggle();
  const [showSharingModal, setShowSharingModal] = useState(false);
  const matches = useMatchMedia("md");
  const { value: mergeModalVisible, toggle: toggleMergeModal } = useToggle();
  const { value: showDeleteModal, toggle: toggleDeleteModal } = useToggle();
  const {
    value: showAboutInternalFormsModal,
    toggle: toggleAboutInternalFormsModal,
  } = useToggle();
  const {
    value: showInternalFormTemplatePickerModal,
    toggle: toggleInternalFormTemplatePickerModal,
  } = useToggle();
  const { value: showInternalFormModal, toggle: toggleShowInternalFormModal } =
    useToggle();
  const { controls, updateSortBy } = useClientPersistentState(contactId);
  const sortBy = controls?.sortBy;
  const [openHeaderDropdown, setOpenHeaderDropdown] = useState(false);

  const activityApi = useSDKApi(ActivityApi);
  const generateRequestId = useRequestIdGenerator("pages/contacts/[contactId]");

  const loadingTimeline =
    loadingArtifactSettings ||
    loadingForms ||
    loadingLibrary ||
    loadingNotes ||
    loadingEmailThreads;

  const registerArchiveActivityMessage = async (action: ActivityAction) => {
    await activityApi.publishActivityMessage({
      publishActivityMessageRequest: {
        activityMessage: {
          action,
          actorType: ActorType.Account,
          actorId: aid || "",
          resourceType: ResourceType.Client,
          resourceId: contactId,
          organizationId: oid,
        },
      },
      xRequestId: generateRequestId(),
    });
  };

  const onArchiveContact = () => {
    if (!oid) return;

    const callback = () => {
      snackbar.showMessage("Contact archived", null, "Undo", () => {
        updateContactStatus(oid, contactId, "active");
        registerArchiveActivityMessage(ActivityAction.Unarchive);
      });
    };

    updateContactStatus(oid, contactId, "archived", callback);
    registerArchiveActivityMessage(ActivityAction.Archive);
  };

  const newAttachment = () => setIsUploadModalOpen(true);

  const addLink = () => setShowLinkModal(true);

  const showUnarchiveSnackbar = () => {
    snackbar.showMessage("Contact unarchived");
  };

  const onShareScheduler = async (clientId: string, scheduler: SchedulerType) =>
    await handleShareScheduler({
      userId: oid,
      clientId,
      scheduler,
    });

  const track = (event) => {
    analytics.track({
      event,
      userId: oid,
    });
  };

  const handleInternalFormSelect = async (data: InternalFormPickerType) => {
    const selectedTemplate = internalTemplates?.find(
      (f) => f.id === data.internalFormId
    ) as FormTemplateType;
    setSelectedInternalTemplate(selectedTemplate);
    toggleShowInternalFormModal();
    toggleInternalFormTemplatePickerModal();
  };

  const chatIcon = (
    <>
      <LeftArrowIcon className="transform -scale-x-1 -rotate-45 h-5 w-5 font-bold -mr-2 hidden md:block" />
      <ChatIcon className="md:hidden -ml-1" />
    </>
  );

  const portalButton = contact && (
    <ChatPortalHeaderButton contactId={contact.id} />
  );

  const handleClickInvite = () =>
    window.open(
      `/me/${organization?.slug}/clients/${contact?.id}/messaging`,
      "_ blank"
    );

  const headerButtons = [
    clientHasJoined && {
      text: portalButton,
      className: "sm:rounded-full rounded-lg px-3 md:px-6",
      rightIcon: chatIcon,
      onClick: handleClickInvite,
    },
    !clientHasJoined && {
      text: "Invite to portal",
      className:
        "bg-blue-300/10 hover:bg-blue-300/20 active:bg-blue-300/30 sm:rounded-full rounded-lg px-3 md:px-6",
      rightIcon: chatIcon,
      onClick: () => setShowInviteModal(true),
    },
    canUpdateContacts && {
      icon: <PencilIcon />,
      text: "Edit client details",
      isIconButton: true,
      href: `/contacts/${encodeURIComponent(contactId)}/edit`,
      heapEventName: "edit_client_header_button_clicked",
    },
    canAssignContacts && {
      icon: <AssignIcon />,
      text: "Assign member",
      isIconButton: true,
      onClick: toggleAssignClientModal,
    },
    canMergeContacts && {
      icon: <MergeIcon />,
      text: "Merge client",
      isIconButton: true,
      onClick: () => {
        toggleMergeModal();
        track("Merge clients initiated");
      },
    },
    canArchiveContacts &&
      contact?.status === "archived" && {
        icon: <UnarchiveIcon />,
        text: "Unarchive client",
        isIconButton: true,
        onClick: () => {
          updateContactStatus(oid, contactId, "active", showUnarchiveSnackbar);
          registerArchiveActivityMessage(ActivityAction.Unarchive);
        },
      },
    canArchiveContacts &&
      contact?.status !== "archived" && {
        icon: <ArchiveIcon />,
        text: "Archive client",
        isIconButton: true,
        onClick: onArchiveContact,
      },
    canDeleteContacts &&
      contact?.status !== "deleted" && {
        icon: <TrashIcon />,
        text: "Delete client",
        isIconButton: true,
        onClick: toggleDeleteModal,
        className: "text-peach-500",
        textClassName: "text-peach-500",
      },
  ].filter(Boolean);

  const handleNewNote = async () => {
    const noteId = await createNote({
      title: "Untitled note",
      richContent: "",
    });
    track("note_created");
    await activityApi.publishActivityMessage({
      publishActivityMessageRequest: {
        activityMessage: {
          action: ActivityAction.Create,
          actorType: ActorType.Account,
          actorId: aid || "",
          resourceType: ResourceType.Note,
          resourceId: noteId,
          organizationId: oid,
          clientId: contactId,
        },
      },
      xRequestId: generateRequestId(),
    });
    const redirect = `/contacts/${encodeURIComponent(
      contactId
    )}/notes/${noteId}/edit?mode=create`;
    router.push(redirect);
  };

  const handleInternalFormClick = () => {
    if (isEmpty(internalTemplates)) toggleAboutInternalFormsModal();
    else toggleInternalFormTemplatePickerModal();
  };

  const handleCloseReconciliationSidebar = () => setSelectedInvoiceId(null);

  const formActions = hasMembersFeature
    ? [
        {
          icon: <FormIcon />,
          actions: [
            {
              icon: <SharedIcon />,
              text: "New client-facing form",
              href: `/contacts/${encodeURIComponent(contactId)}/forms/create`,
            },
            canCreateFormTemplate && {
              icon: <LockIcon />,
              text: "New internal form",
              onClick: () => handleInternalFormClick(),
            },
          ],
          parentTitle: "New form",
        },
      ]
    : [
        {
          icon: <FormIcon />,
          text: "New form",
          href: `/contacts/${encodeURIComponent(contactId)}/forms/create`,
        },
      ];

  const actions = [
    {
      icon: <EventCreationIcon />,
      text: "New appointment",
      href: `/appointments/create?contactId=${contactId}`,
    },
    hasCalendarIntegrated && {
      icon: <EventFoundIcon />,
      text: "Send scheduler",
      onClick: () => setShowSharingModal(true),
      heapEventName: "open-scheduler-sharing-modal",
    },
    ...formActions,
    hasFullAccess &&
      organization?.stripe?.stripe_user_id &&
      canCreateInvoice && {
        icon: <InvoiceIcon />,
        text: "New invoice",
        href: `/invoices/create?contactId=${contactId}`,
      },
    canAssignPackage && {
      icon: <PackageIcon />,
      text: "Assign package",
      onClick: () =>
        router.push(`/package-instances/create?clientId=${contactId}`),
      heapEventName: "open-assign-package-modal",
    },
    {
      icon: <NoteIcon />,
      text: "Add a note",
      onClick: handleNewNote,
    },
    {
      icon: <UploadIcon />,
      text: "New files",
      onClick: newAttachment,
      heapEventName: "open-new-file-modal",
    },
    {
      icon: <SocialWebIcon className="w-6 h-6 text-black-ink" />,
      text: "New link",
      onClick: addLink,
      heapEventName: "open-new-link-modal",
    },
    hasEmail(account) && {
      icon: <EmailIcon className="w-6 h-6" />,
      text: "New email",
      onClick: toggleSendEmalModal,
      heapEventName: "email_profile_modal_open",
    },
  ].filter(Boolean);

  const renderContactInfo = () => {
    if (!contact || !oid) return null;
    const { company } = contact;
    const nameStyle = classNames(
      "text-3xl sm:font-light text-black-ink flex items-center truncate",
      !company && "mb-4 sm:mb-auto"
    );
    return (
      <div className="flex-col sm:flex-row sm:inline-flex items-center flex-1 w-full max-w-xl">
        <ClientAvatar size="large" className="self-start" client={contact} />
        <div className="flex relative flex-col items-center sm:items-start sm:ml-4 w-full">
          <span className={nameStyle}>
            <span id="contact-display-name">
              {displayNameFromContact(contact)}
            </span>
            {contact.status === "archived" && (
              <span className="text-base font-normal bg-peach-950 border-peach-950 text-peach-500 border px-2 py-1 rounded ml-3">
                Archived
              </span>
            )}
          </span>
          {company && (
            <span className="mb-4 font-normal text-base text-grey-500 sm:hidden">
              {company}
            </span>
          )}
          <LabelsAddButton userId={oid} contact={contact} />
        </div>
      </div>
    );
  };

  const renderSortItem = (total = 0) => (
    <span className="ml-2 bg-grey-950 font-medium text-black-ink text-sm inline-block py-0.5 px-1 rounded">
      {total}
    </span>
  );

  // @TODO: to guarantee that anything will break, we need to make sure
  //        all local storage old value will be converted to `all` value
  //        - values that needs coverage: `type` and `date`
  const renderSortSelect = () => {
    const totalAppts = appointments?.filter(filterIsNotEvent)?.length;
    const totalEvents = appointments?.filter(filterIsEvent)?.length;
    const totalFiles = files?.length;
    const totalFolders = folders?.length;
    const totalForms =
      (forms?.length || 0) +
      (legal?.length || 0) +
      (internalForms?.length || 0);
    const totalPayments = payments?.length;
    const totalLinks = links?.length;
    const totalNotes = notes?.length;
    const totalEmailThreads = emailThreads?.length;
    const totalSubscriptions = subscriptions?.length;
    const items = compact([
      {
        icon: <HouseIcon />,
        text: "All",
        value: "all",
      },
      totalAppts && {
        icon: <CalendarIcon />,
        text: "Appointments",
        value: "appointments",
        textRight: renderSortItem(totalAppts),
      },
      totalEvents && {
        icon: <EventsIcon />,
        text: "Events",
        value: "events",
        textRight: renderSortItem(totalEvents),
      },
      totalFiles && {
        icon: <DocumentIcon />,
        text: "Files",
        value: "files",
        textRight: renderSortItem(totalFiles),
      },
      totalFolders && {
        icon: <FolderIcon />,
        text: "Folders",
        value: "folders",
        textRight: renderSortItem(totalFolders),
      },
      totalForms && {
        icon: <FormIcon />,
        text: "Forms",
        value: "forms",
        textRight: renderSortItem(totalForms),
      },
      hasFullAccess &&
        totalPayments && {
          icon: <CreditCardIcon />,
          text: "Invoices",
          value: "payments",
          textRight: renderSortItem(totalPayments),
        },
      hasFullAccess &&
        totalSubscriptions && {
          icon: <LoopIcon />,
          text: "Subscriptions",
          value: "subscriptions",
          textRight: renderSortItem(totalSubscriptions),
        },
      totalLinks && {
        icon: <SocialWebIcon />,
        text: "Links",
        value: "links",
        textRight: renderSortItem(totalLinks),
      },
      totalNotes && {
        icon: <NoteIcon />,
        text: "Notes",
        value: "notes",
        textRight: renderSortItem(totalNotes),
      },
      totalEmailThreads && {
        icon: <EmailIcon />,
        text: "Email Threads",
        value: "emailThreads",
        textRight: renderSortItem(totalEmailThreads),
      },
    ]);

    // @TODO: We are storing the sortBy in local storage. Since we changed the
    //        `date` and `type` values, this page can break if the `sortBy` value
    //        is set. That's why we are checking it and set with `all` value.
    return (
      <SortDropdown
        className="ml-auto"
        wrapOptionsClassName="w-60"
        size="medium"
        onClickChange={updateSortBy}
        selected={["date", "type"].includes(sortBy) ? "all" : sortBy}
        selectedLabel="Show"
        firstRowLabel="View options"
        items={items}
        itemsFooter={
          <>
            <HideArtifactToggle
              setShowHiddenArtifacts={setShowHiddenArtifacts}
              showHiddenArtifacts={showHiddenArtifacts}
            />
            <HideArtifactToggle
              label="Show $0 invoices"
              setShowHiddenArtifacts={setShowHiddenZeroAmountInvoices}
              showHiddenArtifacts={showHiddenZeroAmountInvoices}
            />
          </>
        }
      />
    );
  };

  if (contact && contact.clientType !== "individual") {
    return <Redirect to={`/client-organizations/${contact.id}`} />;
  }

  return (
    <AppFrame
      variant="record"
      title={displayNameFromContact(contact)}
      description="contact"
      customTitle={<div className="hidden sm:block">{renderContactInfo()}</div>}
      headerButtons={!contact || error ? [] : headerButtons}
      actions={!contact || error ? [] : (actions as MenuActionType[])}
      isLoading={!contact && !error}
      openHeaderDropdown={openHeaderDropdown}
      setOpenHeaderDropdown={setOpenHeaderDropdown}
      showOnlyDropdownOnHeader={true}
    >
      {contact && (
        <>
          <div className="sm:hidden">{renderContactInfo()}</div>
          <ContactMergeModal
            toggle={toggleMergeModal}
            visible={mergeModalVisible}
            contactId={contactId}
          />
          <UploadModal
            open={isUploadModalOpen}
            toggleModal={setIsUploadModalOpen}
            uploadPath={`users/${oid}/clients/${contact?.id}/files`}
            heapEventName="client_record_file_uploaded"
          />
          <LinkFormModal
            show={showLinkModal}
            toggleShow={setShowLinkModal}
            contactId={contact?.id}
            heapEventName="client_record_link_saved"
          />
          {hasEmail(account) && showSendEmailModal && (
            <SendEmailModal
              show
              clientIds={[contact?.id]}
              email={contact?.email}
              toggleShow={toggleSendEmalModal}
              heapEventName="email_profile_modal_action_send"
              secondaryHeapEventName="email_profile_modal_action_save_draft"
              onEmailSent={() => {
                mutateEmailThreads();
              }}
            />
          )}

          {showAssignClientModal && (
            <AssignClientToMemberModal
              show
              toggleShow={toggleAssignClientModal}
              client={contact}
            />
          )}
          {showSharingModal && (
            <SharingModal
              show
              toggleShow={setShowSharingModal}
              artefactType="scheduler"
              defaultSendWithPackageId={!!packageInstanceId}
              client={contact}
              onShare={onShareScheduler}
              expandWithClientId={true}
            />
          )}
          {showDeleteModal && (
            <DeleteClientModal
              show
              toggleShow={toggleDeleteModal}
              clientId={contact?.id}
            />
          )}
          {showAboutInternalFormsModal && (
            <AboutInternalForms
              show
              toggleShow={toggleAboutInternalFormsModal}
            />
          )}
          {showInternalFormTemplatePickerModal && (
            <InternalFormTemplatePickerModal
              show
              toggleShow={toggleInternalFormTemplatePickerModal}
              internalForms={internalTemplates as FormTemplateType[]}
              onSubmit={handleInternalFormSelect}
            />
          )}
          {showInternalFormModal && (
            <InternalFormModal
              show
              toggleShow={toggleShowInternalFormModal}
              template={selectedInternalTemplate as FormTemplateType}
              clientId={contact?.id}
            />
          )}
          <div className="flex md:space-x-10 lg:space-x-14 flex-col-reverse md:flex-row">
            <PackagesContext.Provider
              value={{
                packageInstanceId,
                packageInstancesAppointmentsMap,
                packageInstances,
                packageInstance,
              }}
            >
              <>
                <div className="w-full min-w-0 flex flex-col">
                  <div className="sm:sticky sm:top-0 sm:bg-white sm:z-10">
                    <TimelineHeader sortBy={sortBy}>
                      {showPackageFilter && packageInstance && (
                        <div
                          onClick={() => setShowPackageFilter(false)}
                          className="rounded-md bg-green-900 py-2.5 px-3 flex items-center gap-1 mr-2 cursor-pointer max-w-[200px] "
                        >
                          <div className="truncate">
                            {packageInstance.title}
                          </div>
                          <CloseIcon className="flex-shrink-0" />
                        </div>
                      )}
                      {renderSortSelect()}
                    </TimelineHeader>
                  </div>

                  <TimelineView
                    contactId={contactId}
                    clientParentId={parentContact?.id}
                    sortBy={["date", "type"].includes(sortBy) ? "all" : sortBy}
                    appointments={appointments}
                    payments={[
                      // the invoice reconciliation based should be open in the
                      // preview mode, so we need to add the onClick prop to
                      // prevent this invoice to be open in the detail view
                      ...payments.map((payment) => {
                        const isReconciliationBased =
                          payment?.kind === "usage-based" ||
                          payment?.items?.some((item) => !!item?.transactionId);
                        return {
                          ...payment,
                          ...(isReconciliationBased && {
                            onClick: (invoiceId: string) =>
                              setSelectedInvoiceId(invoiceId),
                            createdAt: payment.dueDate,
                            start: payment.dueDate,
                            status:
                              payment?.status === "draft"
                                ? "scheduled"
                                : payment.status,
                          }),
                        };
                      }),
                    ]}
                    loading={loadingTimeline}
                    forms={[...forms, ...(internalForms || [])]}
                    legal={legal}
                    files={files}
                    notes={notes}
                    links={links}
                    folders={folders}
                    emailThreads={emailThreads}
                    subscriptions={subscriptions}
                    onClickPlanning={setOpenHeaderDropdown}
                    filterPackage={
                      showPackageFilter ? packageInstanceId : undefined
                    }
                    artifactSettings={artifactSettings}
                    onChangeHidden={() => mutate()}
                    selectedTab={query.tab as TimelineTabTypes}
                  />
                </div>
                <aside className="md:w-60 lg:w-80 shrink-0 mt-3 mb-4 md:mt-0 md:mb-0">
                  <ClientRecordSidebar
                    contactId={contactId}
                    organization={organization as OrganizationType}
                    isMd={matches || false}
                    packageInstances={packageInstances ?? []}
                    selectedPackageInstanceIndex={selectedPackageInstanceIndex}
                    setSelectedPackageInstanceIndex={
                      setSelectedPackageInstanceIndex
                    }
                    onClientAssigneeClick={toggleAssignClientModal}
                    setShowPackageFilter={setShowPackageFilter}
                    showPackageFilter={showPackageFilter}
                  />
                </aside>
              </>
            </PackagesContext.Provider>
          </div>
          <InviteAndShareModal
            showInviteModal={showInviteModal}
            setShowInviteModal={setShowInviteModal}
            setShowShareModal={setShowShareModal}
            showShareModal={showShareModal}
            client={contact}
          />
          {!!selectedInvoiceId && (
            <ReconciliationSidebar
              show
              toggleShow={handleCloseReconciliationSidebar}
              onClickClose={handleCloseReconciliationSidebar}
              invoiceId={selectedInvoiceId}
              clientId={contactId}
            />
          )}
        </>
      )}
    </AppFrame>
  );
};

export default ContactDetail;
