import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 10.7614 13.7614 13 11 13C8.23858 13 6 10.7614 6 8C6 5.23858 8.23858 3 11 3C13.7614 3 16 5.23858 16 8ZM14 8C14 9.65685 12.6569 11 11 11C9.34315 11 8 9.65685 8 8C8 6.34315 9.34315 5 11 5C12.6569 5 14 6.34315 14 8Z"
      />
      <path d="M7 16C5.89543 16 5 16.8954 5 18V19.5C5 19.7761 4.77614 20 4.5 20H3.5C3.22386 20 3 19.7761 3 19.5V18C3 15.7909 4.79086 14 7 14H15C17.2091 14 19 15.7909 19 18V19.5C19 19.7761 18.7761 20 18.5 20H17.5C17.2239 20 17 19.7761 17 19.5V18C17 16.8954 16.1046 16 15 16H7Z" />
      <circle cx="17.5" cy="17.5" r="4.5" />
      <path
        d="M17.25 15C17.1119 15 17 15.1119 17 15.25V17L15.25 17C15.1119 17 15 17.1119 15 17.25V17.75C15 17.8881 15.1119 18 15.25 18H17V19.75C17 19.8881 17.1119 20 17.25 20H17.75C17.8881 20 18 19.8881 18 19.75V18H19.75C19.8881 18 20 17.8881 20 17.75V17.25C20 17.1119 19.8881 17 19.75 17L18 17V15.25C18 15.1119 17.8881 15 17.75 15H17.25Z"
        fill="white"
      />
    </svg>
  );
};

export default Icon;
