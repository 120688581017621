import { useMemo } from "react";
import {
  ActivityAction,
  ActivityApi,
  ActorType,
  ResourceType,
} from "@practice/sdk";
import { addDoc, collection } from "firebase/firestore";
import { useCollection } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { NoteType } from "@lib/data/schemas/note";
import { compatDB as db } from "@lib/firebase-config";

import { useRequestIdGenerator } from "./use-request-id-generator";
import { useSDKApi } from "./use-sdk-api";

type CreateNoteType = (data: Partial<NoteType>) => Promise<string>;

type UseNotesType = ({
  oid,
  contactId,
}: {
  oid?: string;
  contactId?: string;
}) => {
  notes?: NoteType[];
  loading: boolean;
};

const useNotes: UseNotesType = ({ oid, contactId }) => {
  const { data, error } = useCollection<NoteType>(
    oid && contactId ? `users/${oid}/clients/${contactId}/notes` : null,
    {
      orderBy: ["updatedAt", "desc"],
      listen: true,
    }
  );

  const notes = useMemo(
    () => data?.filter(({ status }) => status !== "deleted"),
    [data]
  );

  return { notes, loading: !notes && !error };
};

export const useCreateNotes = (clientId: string) => {
  const { oid, aid } = useAuth();
  const generateRequestId = useRequestIdGenerator("LinkedNotes");
  const activityApi = useSDKApi(ActivityApi);

  const create: CreateNoteType = async (data) => {
    const rightNow = new Date();
    const note = await addDoc(
      collection(db, `/users/${oid}/clients/${clientId}/notes`),
      {
        ...data,
        createdAt: rightNow,
        updatedAt: rightNow,
        status: "private",
        coachUserId: oid,
      }
    );

    await activityApi.publishActivityMessage({
      publishActivityMessageRequest: {
        activityMessage: {
          action: ActivityAction.Create,
          actorType: ActorType.Account,
          actorId: aid || "",
          resourceType: ResourceType.Note,
          resourceId: note.id,
          organizationId: oid,
          clientId,
        },
      },
      xRequestId: generateRequestId(),
    });
    return note.id;
  };

  return create;
};

export default useNotes;
