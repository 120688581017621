import { useState } from "react";
import { PackageInstanceApi } from "@practice/sdk";

import { catchErrorSDK } from "@lib/utils/catch-error-client";

import { useMutatePackages } from "./use-client-organization-data";
import useLogger from "./use-logger";
import { useRequestIdGenerator } from "./use-request-id-generator";
import { useSDKApi } from "./use-sdk-api";

const usePausePackage = (
  organizationId: string,
  clientId: string,
  clientParentId?: string,
  options?: { failMode?: "throw"; logName?: string }
) => {
  const [loading, isLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { logger } = useLogger("usePausePackage");

  const packageInstanceAPI = useSDKApi(PackageInstanceApi);

  const mutatePackages = useMutatePackages({
    clientId,
    parentId: clientParentId,
  });

  const generateRequestId = useRequestIdGenerator(
    options?.logName ?? "usePausePackage"
  );

  const pausePackageInstance = async (packageInstanceId: string) => {
    const request = { organizationId, packageInstanceId, clientId };
    logger.info(request, "Pausing package instance");

    isLoading(true);
    try {
      await packageInstanceAPI.pausePackageInstance({
        ...request,
        xRequestId: generateRequestId(),
      });
      await mutatePackages();
    } catch (e: any) {
      const errorMessage = await catchErrorSDK(
        e,
        "Failed to pause package instance"
      );
      logger.info(
        { error: e, request, errorMessage },
        "Failed to pause package instance"
      );
      setError(errorMessage);
      if (options?.failMode === "throw") {
        throw e;
      }
    } finally {
      isLoading(false);
      logger.info(request, "Package instance paused");
    }
  };

  return { pausePackageInstance, loading, error };
};

export default usePausePackage;
