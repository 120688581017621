import { FC } from "react";
import moment from "moment";

import { getNormalizedDate } from "@lib/appointments";
import { ExtendedInvoiceType } from "@lib/data/schemas/invoice";
import { mapInvoiceStatus } from "@lib/invoices";

import PaymentPlan from "@components/PackageInstance/PaymentPlan";
import { PaymentPlanProps } from "@components/PackageInstance/types";

import PackageTooltipDetails from "../PackageTooltipDetails";

const PackagePaymentDetails: FC<
  PaymentPlanProps & { enableDarkMode?: boolean; isPaused?: boolean }
> = ({ packageInstance, invoices = [], enableDarkMode = false, isPaused }) => {
  const instancePayments = invoices;
  if (instancePayments.length === 0) return null;
  const nextScheduled = instancePayments?.find(
    (item: ExtendedInvoiceType) => mapInvoiceStatus(item) === "scheduled"
  );
  const nextShared = instancePayments?.find(
    (item: ExtendedInvoiceType) => mapInvoiceStatus(item) === "shared"
  );
  const paidItems = instancePayments?.filter(
    (item: ExtendedInvoiceType) => mapInvoiceStatus(item) === "paid"
  );
  const paidOrProcessin = instancePayments?.filter(
    (item: ExtendedInvoiceType) =>
      ["paid", "processing-ach"].includes(mapInvoiceStatus(item))
  );
  const paidInFull = paidItems?.length === instancePayments?.length;

  const getSubtitle = () => {
    if (paidInFull) return "Paid in full";
    if (nextScheduled)
      return `Next: ${moment(getNormalizedDate(nextScheduled?.dueDate)).format(
        "MMMM Do YYYY"
      )}`;
    if (nextShared) return `Shared via email`;
    return "";
  };

  return (
    <PackageTooltipDetails
      title="Payment plan"
      total={instancePayments?.length}
      amount={paidOrProcessin?.length}
      subtitle={getSubtitle()}
      enableDarkMode={enableDarkMode}
      isPaused={isPaused}
    >
      <PaymentPlan
        className="px-4"
        packageInstance={packageInstance}
        invoices={invoices}
      />
    </PackageTooltipDetails>
  );
};

export default PackagePaymentDetails;
