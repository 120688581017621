import React, { FC, useMemo } from "react";
import moment from "moment";
import Link from "next/link";
import Stripe from "stripe";

import { useScheduler } from "@hooks/data/schedulers";
import { useTaxType } from "@hooks/data/taxTypes";
import useOrganizationAccounts from "@hooks/use-organization-accounts";
import { mapInvoiceStatus } from "@lib/invoices";
import { displayItemAmountString } from "@lib/products";
import { IconType } from "@lib/shared-types";

import ArtifactStatusIcon from "@components/ArtifactStatusIcon";
import ClientInfoBoxDetailsPage from "@components/DetailsPage/ClientInfoBoxDetailsPage";
import InfoBoxDateDetailsPage from "@components/DetailsPage/InfoBoxDateDetailsPage";
import InfoBoxDetailsPage from "@components/DetailsPage/InfoBoxDetailsPage";
import InfoBoxLabelDetailsPage from "@components/DetailsPage/InfoBoxLabelDetailsPage";
import InfoBoxStatusDetailsPage from "@components/DetailsPage/InfoBoxStatusDetailsPage";
import InfoSectionDetailsPage from "@components/DetailsPage/InfoSectionDetailsPage";
import SectionDetailsPage from "@components/DetailsPage/SectionDetailsPage";
import SeparatorDetailsPage from "@components/DetailsPage/SeparatorDetailsPage";
import CalendarArrowInIcon from "@components/Icons/CalendarArrowInIcon";
import CalendarArrowOutIcon from "@components/Icons/CalendarArrowOutIcon";
import CalendarIcon from "@components/Icons/CalendarIcon";
import EventsIcon from "@components/Icons/EventsIcon";
import LinkIcon from "@components/Icons/LinkIcon";
import LoopIcon from "@components/Icons/LoopIcon";
import PackageIcon from "@components/Icons/PackageIcon";
import CreditNotesList from "@components/Invoices/CreditNotesList";
import RefundsInfo from "@components/Invoices/RefundsInfo";
import StatusTooltip from "@components/Status/StatusTooltip";

import ItemRowInvoiceDetails from "./ItemRowInvoiceDetails";
import { FormattedStatusType, InvoiceDetailProps } from "./types";

export const formattedStatus: FormattedStatusType = (status) => {
  switch (status) {
    case "open":
    case "draft":
      return "Draft";
    case "scheduled":
      return "Scheduled";
    case "shared":
      return "Shared";
    case "viewed":
      return "Viewed";
    case "paid":
      return "Paid";
    case "void":
      return "Cancelled";
    case "failed":
      return "Failed";
    case "scheduled":
      return "Scheduled";
    case "refunded":
      return "Refunded";
    case "partially_refunded":
      return "Partially refunded";
    case "processing-ach":
      return "Processing via ACH";
    default:
      return "No Status";
  }
};

const InvoiceDetail: FC<InvoiceDetailProps> = ({
  payment,
  appointment,
  client,
  isClientLoading,
  onClickAppt,
  showCustomDetails,
  subscription,
  coachCoupons = [],
}) => {
  const { accounts = [] } = useOrganizationAccounts();
  const {
    createdAt,
    dueDate,
    sendAt,
    amount,
    tax,
    currency,
    total,
    items,
    recurring,
    memo,
    footer,
    customFields,
    couponId,
  } = payment;
  const status = mapInvoiceStatus(payment);
  // @TODO: move this logic to API
  const stripeInvoice = payment.invoice as Stripe.Invoice;
  const appliedBalance = stripeInvoice?.starting_balance;
  const depositBalance = payment?.depositBalance;

  const { taxType } = useTaxType(items?.at(0)?.taxTypeId);
  const taxTypeInfo = taxType && `${taxType.name} ${taxType.percentage}%`;
  const { data: appointmentType } = useScheduler(appointment?.availabilityId);

  const hasCustomFields = customFields && customFields.length > 0;
  const showCustomDetailsSection =
    showCustomDetails && (memo || footer || hasCustomFields);

  const coupon = useMemo(() => {
    return coachCoupons?.find((cp) => cp.id === couponId);
  }, [couponId, coachCoupons]);

  const couponIsPercent = coupon?.unit === "%";
  const isJPY = coupon?.unit === "JPY";
  const couponInfo =
    coupon &&
    `${
      couponIsPercent
        ? `${coupon.value}%`
        : `${displayItemAmountString(coupon.value, currency)}`
    } off - ${coupon.title}`;
  const totalDiscount = payment.invoice?.total_discount_amounts?.reduce(
    (total, curr) => total + (isJPY ? curr.amount * 100 : curr.amount),
    0
  );
  const couponValue = displayItemAmountString(totalDiscount / 100, currency);

  const formatFrequency = () => {
    if (recurring) {
      if (recurring.interval == "week") {
        return "Weekly";
      } else if (subscription) {
        return subscription.frequency.description;
      } else if (recurring.interval == "month") {
        return "Monthly";
      }
    }

    return "One-time";
  };

  const statusIcon = (
    <ArtifactStatusIcon status={status} type="payments" variant="status-icon" />
  );

  const renderStatus = (
    <InfoBoxDetailsPage icon={statusIcon} title="Status">
      <InfoBoxStatusDetailsPage
        formattedStatus={formattedStatus(status)}
        icon={<StatusTooltip type="invoice" />}
      />
    </InfoBoxDetailsPage>
  );

  const renderInfoBoxDate = ({
    icon: Icon = CalendarIcon,
    title,
    date,
    includeTime = false,
  }: {
    icon?: IconType;
    title: string;
    date: Date;
    includeTime?: boolean;
  }) => (
    <InfoBoxDetailsPage
      iconClassName="bg-grey-950"
      icon={<Icon />}
      title={title}
    >
      <InfoBoxDateDetailsPage
        label={moment(date).format(
          `ddd, MMM D${includeTime ? " [at] hh:mm a" : ""}`
        )}
      />
    </InfoBoxDetailsPage>
  );

  const renderAppointment = () => {
    const wrapperStyle =
      "text-black-ink font-medium border-b border-black-ink inline-flex items-center leading-none cursor-pointer";
    const content = appointment && (
      <>
        <LinkIcon className="mr-1" />{" "}
        {moment(appointment?.start.toDate()).format("MMM DD")}:{" "}
        {appointmentType ? appointmentType?.title : appointment?.title}
      </>
    );
    const isEvent = !!appointment?.eventData;
    const Icon = isEvent ? EventsIcon : CalendarIcon;
    const label = isEvent ? "event" : "appointment";

    return (
      appointment && (
        <InfoBoxDetailsPage
          iconClassName="bg-grey-950"
          icon={<Icon className="" />}
          title={`Linked ${label}`}
        >
          {onClickAppt ? (
            <div
              className={wrapperStyle}
              onClick={() => onClickAppt(appointment.id)}
            >
              {content}
            </div>
          ) : (
            <Link
              href={`/appointments/${appointment?.id}`}
              className={wrapperStyle}
            >
              {content}
            </Link>
          )}
        </InfoBoxDetailsPage>
      )
    );
  };

  const renderFrequency = (
    <InfoBoxDetailsPage
      iconClassName="bg-grey-950"
      icon={<LoopIcon />}
      title="Frequency"
    >
      <InfoBoxLabelDetailsPage>{formatFrequency()}</InfoBoxLabelDetailsPage>
    </InfoBoxDetailsPage>
  );

  const description =
    !["draft", "scheduled", "open"].includes(status) &&
    "Shared to your clients and available on PDF invoice.";

  const renderCustomDetails = (
    <>
      <SeparatorDetailsPage />
      <SectionDetailsPage
        title="Additional information"
        description={description}
        columnsLayout
      >
        <div className="grid justify-end">
          {hasCustomFields &&
            customFields.map((field, index) => (
              <InfoBoxDetailsPage
                key={`customField${index}`}
                title={field.title}
                titleClassName="text-right"
              >
                <p className="text-black-ink font-medium text-right mb-4">
                  {field.value}
                </p>
              </InfoBoxDetailsPage>
            ))}
          {memo && (
            <InfoBoxDetailsPage title="Memo" titleClassName="text-right">
              <p className="text-black-ink font-medium text-right mb-4">
                {memo}
              </p>
            </InfoBoxDetailsPage>
          )}
          {footer && (
            <InfoBoxDetailsPage title="Footer" titleClassName="text-right">
              <p className="text-black-ink font-medium text-right">{footer} </p>
            </InfoBoxDetailsPage>
          )}
        </div>
      </SectionDetailsPage>
    </>
  );

  const renderSubscription = (
    <InfoBoxDetailsPage
      iconClassName="bg-grey-950"
      icon={<PackageIcon />}
      title="Link to subscription"
    >
      <InfoBoxLabelDetailsPage className="underline">
        <Link
          href={`/clients/${subscription?.clientId}/subscriptions/${subscription?.id}`}
        >
          {subscription?.title ?? payment.memo}
        </Link>
      </InfoBoxLabelDetailsPage>
    </InfoBoxDetailsPage>
  );

  const renderLineItem = (item) => (
    <div className="contents md:table-row md:pt-2">
      <div className="pt-2 md:table-cell md:w-2/5 md:pr-2">
        {item.description}
      </div>
      <div className="inline-flex md:contents">
        <div className="flex-grow pr-2 md:text-right md:table-cell">
          {`${item.quantity}x`}
        </div>
        <div className="flex-grow pr-2 md:text-right md:table-cell">
          {item.unitAmount}
        </div>
        <div className="flex-grow md:text-right md:table-cell md:pr-2">
          {item.itemAmount}
        </div>
      </div>
    </div>
  );

  const renderContent = (
    <>
      <SectionDetailsPage title="Invoice details">
        <div className="flex flex-col pt-2 md:table md:w-full md:pt-5">
          <div className="hidden md:table-row font-medium">
            <div className="md:table-cell">Items</div>
            <div className="md:table-cell md:pr-2 md:text-right">Quantity</div>
            <div className="md:table-cell md:pr-2 md:text-right">Price</div>
            <div className="md:text-right md:table-cell md:pr-2">Amount</div>
          </div>
          {items.map((item) => renderLineItem(item))}
        </div>
      </SectionDetailsPage>
      <SeparatorDetailsPage />
      {depositBalance !== 0 && (
        <ItemRowInvoiceDetails
          title="Deposit balance"
          value={`-${depositBalance}`}
        />
      )}
      <ItemRowInvoiceDetails title="Subtotal" value={amount} />
      {coupon && (
        <ItemRowInvoiceDetails
          title={couponInfo}
          value={couponValue}
          className="text-blue-300"
          showAsNegative
        />
      )}
      <ItemRowInvoiceDetails title={taxTypeInfo} value={tax} />
      {appliedBalance !== 0 && (
        <ItemRowInvoiceDetails title="Applied balance" value={appliedBalance} />
      )}
      <ItemRowInvoiceDetails
        title="Total"
        value={`${currency} ${total}`}
        isTotal
      />
    </>
  );

  const renderRefundsInfo = !!payment?.refunds?.length && (
    <>
      <SeparatorDetailsPage />
      <RefundsInfo
        currency={payment.currency}
        refunds={payment.refunds}
        members={accounts}
      />
    </>
  );

  const renderCreditNotesList = !!payment?.creditNotes?.length && (
    <SectionDetailsPage title="Credit notes" className="mt-4">
      <CreditNotesList
        currency={payment.currency}
        creditNotes={payment.creditNotes}
      />
    </SectionDetailsPage>
  );

  return (
    <div className="pb-16">
      <InfoSectionDetailsPage>
        {renderStatus}
        <ClientInfoBoxDetailsPage client={client} isLoading={isClientLoading} />
        {sendAt === "scheduled" && (sendAt || dueDate)
          ? renderInfoBoxDate({
              icon: CalendarArrowOutIcon,
              title: "Scheduled for",
              date: sendAt || dueDate,
            })
          : renderInfoBoxDate({ title: "Created on", date: createdAt })}
        {renderFrequency}

        {dueDate &&
          renderInfoBoxDate({
            icon: CalendarArrowInIcon,
            title: "Due on",
            date: dueDate,
          })}

        {renderAppointment()}
        {subscription && renderSubscription}
      </InfoSectionDetailsPage>
      <SeparatorDetailsPage />
      {renderContent}
      {showCustomDetailsSection && renderCustomDetails}
      {renderRefundsInfo}
      {renderCreditNotesList}
    </div>
  );
};

export default InvoiceDetail;
