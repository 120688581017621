import { FC, useMemo } from "react";
import classNames from "classnames";

import { useTaxType } from "@hooks/data/taxTypes";
import { mapInvoiceStatus } from "@lib/invoices";
import { displayPaymentString } from "@lib/products";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";
import { formattedStatus } from "@components/InvoiceDetails/InvoiceDetail";
import {
  backgroundColorStatus,
  iconStatus,
  textColorStatus,
} from "@components/PaymentStatusIcon";
import AccountDetail from "@components/PublicProfile/AccountDetail";

import CommonMoreMenu from "./CommonMoreMenu";
import RenderWithLink from "./RenderWithLink";
import { classes } from "./shared";
import { TimelineArtifactItemProps } from "./types";

export const InvoiceStatus = ({ status, className }) => (
  <div
    className={classNames(
      classes.icon,
      textColorStatus(status),
      backgroundColorStatus(status),
      className
    )}
  >
    {iconStatus(status)}
  </div>
);

// @TODO: add prop to set the value in this component
export const InvoiceStatusText = ({ status, className }) => (
  <p
    className={classNames(
      "capitalize",
      classes.status,
      textColorStatus(status),
      className
    )}
  >
    {formattedStatus(status)}
  </p>
);

interface InvoiceProps extends TimelineArtifactItemProps {
  coachSlug: string;
}

export const Invoice: FC<InvoiceProps> = ({
  item,
  contactId,
  isCoach,
  coachSlug,
  artifactSettings,
  onChangeHidden,
}) => {
  const status = mapInvoiceStatus(item);
  const { taxType } = useTaxType(item.items?.[0]?.taxTypeId);
  const linkUrl = !isCoach
    ? `/me/${coachSlug}/invoices/${item.id}`
    : `/clients/${contactId}/invoices/${item.id}`;

  const hidden = useMemo(() => {
    const { payments } = artifactSettings || {};
    return payments?.hidden?.includes(item.id);
  }, [artifactSettings]);

  const renderMoreMenu = isCoach ? (
    <CommonMoreMenu
      artifact="payments"
      item={item}
      artifactSettings={artifactSettings}
      onChangeHidden={onChangeHidden}
    />
  ) : undefined;

  return (
    <RenderWithLink
      isCoach={isCoach}
      item={item}
      linkUrl={linkUrl}
      moreMenu={renderMoreMenu}
      isHidden={hidden}
    >
      <ArtifactStatusIcon status={status} type="payments" className="mr-4" />
      <div className={`flex w-full ${classes.content}`}>
        <div className="truncate">
          <p className="truncate">{item.title}</p>
          <div className="flex">
            <ArtifactStatusText
              status={status}
              type="payments"
              size="xs"
              className="mr-2"
            />
            {!!item.contacts?.length && (
              <AccountDetail
                accounts={item.contacts}
                showDividerBefore
                accountClassNames="text-xs font-medium"
                avatarSize="xxsmall"
              />
            )}
          </div>
        </div>
        <div className="ml-auto text-right shrink-0 group-hover:opacity-0">
          <div>
            {displayPaymentString({
              total: item.total,
              currency: item.currency,
            })}
          </div>
          {taxType && (
            <div className="text-grey-500 text-sm">
              incl. {taxType.name} ({taxType.percentage}%)
            </div>
          )}
        </div>
      </div>
    </RenderWithLink>
  );
};
