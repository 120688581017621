import { getSchemaDefinition } from "api-services/common";
import { z } from "zod";

import {
  ArtifactSchema,
  ArtifactSettingsSchema,
} from "@lib/data/schemas/artifact-settings";

const tags = ["artifact-settings"];

const ActionSchema = z.enum(["add", "remove"]);
const FieldNameSchema = z.enum(["hidden"]);

export const getArtifactSettings = getSchemaDefinition(
  "/api/v1/users/{userId}/accounts/{accountId}/artifact-settings",
  "get",
  {
    path: z.object({
      userId: z.string(),
      accountId: z.string(),
    }),
  },
  {
    description: "Get an artifact settings",
    tags,
  },
  ArtifactSettingsSchema
);

export const postArtifactSettings = getSchemaDefinition(
  "/api/v1/users/{userId}/accounts/{accountId}/artifact-settings",
  "post",
  {
    path: z.object({
      userId: z.string(),
      accountId: z.string(),
    }),
    body: z.object({
      artifact: ArtifactSchema,
      field: FieldNameSchema,
      value: z.string(),
      action: ActionSchema,
    }),
  },
  {
    description: "Create or update an artifact settings",
    tags,
  },
  ArtifactSettingsSchema
);
