import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ActivityPathIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      fill="none"
      viewBox="0 0 24 25"
    >
      <circle
        cx="18"
        cy="6.5"
        r="2"
        strokeWidth="2"
        transform="rotate(-90 18 6.5)"
      ></circle>
      <path d="M2 12.5v-3a4 4 0 118 0V17a3.5 3.5 0 107 0v-4.5h2v5a5 5 0 01-5 5h-1a5 5 0 01-5-5v-8a2 2 0 10-4 0v3H2z"></path>
      <path d="M17.535 11.55l-3.181 3.182a.5.5 0 000 .707l.707.707a.5.5 0 00.707 0l1.853-1.853a.5.5 0 01.707 0l1.854 1.853a.5.5 0 00.707 0l.707-.707a.5.5 0 000-.707l-3.182-3.182-.068-.073a.5.5 0 00-.713-.02l-.098.093z"></path>
    </svg>
  );
};

export default ActivityPathIcon;
