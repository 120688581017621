import { FC, useMemo } from "react";

import { displayPaymentString } from "@lib/products";

import ArtifactStatusIcon, {
  ArtifactStatusText,
} from "@components/ArtifactStatusIcon";

import CommonMoreMenu from "./CommonMoreMenu";
import RenderWithLink from "./RenderWithLink";
import { classes } from "./shared";
import { TimelineArtifactItemProps } from "./types";

export const Subscription: FC<TimelineArtifactItemProps> = ({
  item,
  contactId,
  isCoach,
  artifactSettings,
  onChangeHidden,
}) => {
  const linkUrl = !isCoach
    ? item.invoice.hosted_invoice_url
    : `/clients/${contactId}/subscriptions/${item.id}`;

  const hidden = useMemo(() => {
    const { subscriptions } = artifactSettings || {};
    return subscriptions?.hidden?.includes(item.id);
  }, [artifactSettings]);

  const renderMoreMenu = isCoach ? (
    <CommonMoreMenu
      artifact="subscriptions"
      item={item}
      artifactSettings={artifactSettings}
      onChangeHidden={onChangeHidden}
    />
  ) : undefined;

  return (
    <RenderWithLink
      isCoach={isCoach}
      item={item}
      linkUrl={linkUrl}
      moreMenu={renderMoreMenu}
      isHidden={hidden}
    >
      <ArtifactStatusIcon
        status={item.status}
        type="subscriptions"
        className="mr-4"
      />
      <div className={`flex w-full ${classes.content}`}>
        <div className="truncate">
          <p className="truncate">{item.title}</p>
          <ArtifactStatusText
            status={item.status}
            type="subscriptions"
            size="xs"
          />
        </div>
        <div className="ml-auto text-right shrink-0 group-hover:opacity-0">
          <div>
            {displayPaymentString({
              total: item.invoicePricing?.total || 0,
              currency: item.currency,
            })}
          </div>
          <div>{item.frequency.description}</div>
        </div>
      </div>
    </RenderWithLink>
  );
};
